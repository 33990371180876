import React, { useEffect, useState, useMemo, useCallback, useRef, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentRequest, PreferredContactMethod } from 'src/interfaces/api/AppointmentRequest';
import {
  AlertHandle,
  Row,
  StyledMasterView,
  TextInput,
  DropDown,
  DatePicker,
  CPrimaryButton,
  BottomButton,
  TwoColorView,
  GeneralView
} from 'src/components';
import { ScrollView } from 'react-native';
import { useQuery, useMutation } from 'react-query';
import { postRequest, getOpenings, QueryKeys } from 'src/api';
import { useUser } from 'src/providers/ClientProvider';
import { useFormik, yupToFormErrors, FormikErrors } from 'formik';
import useAppointmentRequestOptions from './useAppointmentRequestOptions';
import { layout } from 'src/theme/globalStyles';
import {
  findUnavailableDates,
  generateAvailableTimes,
  validationSchema,
  availableColumns,
  datePickerValueFromString,
  FormikData,
  requestPayload,
  initialValues
} from './helpers';
import moment from 'moment-timezone';
import Toast from 'react-native-toast-message';
import { isDefined, queryClient, toLocalTime } from 'src/utils';
import {
  StyledRadioButton,
  StyledBody,
  RadioButtonGroup,
  HeaderBody,
  StickyButtonMasterView
} from './styled';
import { Screens } from 'src/routes/stacks/screens';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Margin, IS_WEB, NEW_PET } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import Alert, { AlertOption } from 'src/components/Alert';
import { usePractice } from 'src/hooks';
import CustomLabel from './CustomLabel';
import UploadFiles, { ImageUpload } from 'src/components/UploadFiles';

interface IOpenings {
  [key: string]: string[];
}

interface IOpeningsQuery {
  data: IOpenings;
}

const RequestAppointment: React.FC<
  NativeStackScreenProps<MainStackParamsList, Screens.APPOINTMENT_REQUEST>
> = ({ route, navigation }) => {
  const { t } = useTranslation('appointmentRequest');
  const { user } = useUser();
  const selectedPatientId = route?.params?.id;
  const { data: practice } = usePractice();
  const { colors } = useAppTheme();

  const { requestSettings, patientOptions, apptTypeOptions, columnOptions, fieldDisplayOptions } =
    useAppointmentRequestOptions();

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        action: () => navigation.goBack(),
        label: t('common:OK')
      }
    ],
    [navigation, t]
  );

  const { mutate: submitRequest } = useMutation(
    async (request: AppointmentRequest) => await postRequest(request),
    {
      onSuccess: () => {
        confirmModalRef.current?.alert();
      },
      onError: () => {
        Toast.show({ text1: t('errorToast') });
      }
    }
  );

  const { values, errors, handleSubmit, setFieldValue, resetForm, dirty, isValid } =
    useFormik<FormikData>({
      initialValues: initialValues(
        fieldDisplayOptions.useAppointmentTypes,
        fieldDisplayOptions.categoryType,
        selectedPatientId
      ),
      validateOnBlur: true,
      validate: async (values): Promise<FormikErrors<AppointmentRequest>> => {
        let errors = {};
        await validationSchema
          .validate(values, {
            context: { ...fieldDisplayOptions }
          })
          .catch((err: any) => {
            errors = yupToFormErrors(err);
          });
        return errors;
      },
      onSubmit: async (values) => {
        const requestImageIds = Object.values(values.images)
          .map((imageUpload: ImageUpload) => imageUpload.id)
          .filter(isDefined);

        submitRequest(
          requestPayload(
            values,
            user,
            fieldDisplayOptions.useAppointmentTypes,
            fieldDisplayOptions.categoryType,
            requestImageIds
          )
        );
        resetForm();
        void queryClient.invalidateQueries([QueryKeys.REQUESTS]);
      }
    });

  const enabled = useMemo(() => {
    return (
      !!user &&
      !!user.practiceId &&
      isDefined(fieldDisplayOptions.useAppointmentTypes) &&
      (fieldDisplayOptions.useAppointmentTypes ? !!values.appointmentTypeId : true) &&
      isDefined(fieldDisplayOptions.allowCategorySelection) &&
      (fieldDisplayOptions.allowCategorySelection
        ? !!values.categoryId || values.column === 'No Preference'
        : true)
    );
  }, [user, fieldDisplayOptions, values]);

  const { data: openings } = useQuery({
    queryFn: async () =>
      await getOpenings({
        practiceId: user.practiceId,
        appointmentTypeId: values.appointmentTypeId,
        categoryId: values.categoryId
      }),
    queryKey: [
      QueryKeys.SCHEDULE_OPENINGS,
      user?.practiceId,
      values.appointmentTypeId,
      values.categoryId
    ],
    enabled
  }) as IOpeningsQuery;

  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
  useEffect(() => {
    setUnavailableDates(
      findUnavailableDates(openings, fieldDisplayOptions.minDays, fieldDisplayOptions.maxDays)
    );
  }, [openings, fieldDisplayOptions]);

  const columns = useMemo(
    () =>
      availableColumns(
        columnOptions,
        fieldDisplayOptions.useAppointmentTypes,
        apptTypeOptions.find((a) => a.value === values.appointmentTypeId)
      ),
    [
      apptTypeOptions,
      columnOptions,
      fieldDisplayOptions.useAppointmentTypes,
      values.appointmentTypeId
    ]
  );

  const [images, setImages] = useState<Record<string, ImageUpload>>({});

  const onImageChange = useCallback(
    (action: SetStateAction<Record<string, ImageUpload>>) => {
      setImages((prev) => {
        const value = typeof action === 'function' ? action(prev) : action;
        void setFieldValue('images', value);
        return value;
      });
    },
    [setFieldValue]
  );

  const disableSubmit = !dirty || !isValid || !!errors.images;

  return (
    <>
      <Alert
        ref={confirmModalRef}
        title={t('appointmentRequest:dialogTitle')}
        body={requestSettings?.appointmentRequestConfirmationTextSanitized ?? t('requestedToast')}
        options={confirmOptions}
      />
      <TwoColorView topColor={colors.background} bottomColor={colors.background}>
        <ScrollView style={layout.flex1} keyboardShouldPersistTaps='always'>
          <StyledMasterView style={{ gap: Margin.Small, padding: Margin.Small }}>
            {requestSettings?.appointmentRequestHeaderTextSanitized && (
              <HeaderBody>{requestSettings.appointmentRequestHeaderTextSanitized}</HeaderBody>
            )}
            <DropDown
              label={t('selectPatient')}
              options={patientOptions}
              value={values.patientId}
              onChange={async (patientId) => {
                await setFieldValue('patientId', patientId);
                await setFieldValue('petName', '');
              }}
              error={!!errors.patientId}
            />
            {values.patientId === NEW_PET && (
              <TextInput
                label={t('petName')}
                value={values.petName ?? ''}
                onChangeText={async (petName) => await setFieldValue('petName', petName)}
              />
            )}
            {fieldDisplayOptions.useAppointmentTypes && (
              <DropDown
                label={t('appointmentType')}
                options={apptTypeOptions}
                value={values.appointmentTypeId}
                onChange={async (appointmentTypeId) => {
                  values.preferredDate = '';
                  values.preferredTime = '';
                  const found = apptTypeOptions.find((a) => a.value === appointmentTypeId);
                  if (found) {
                    // always will be found but linter doesn't make the connection
                    await setFieldValue('appointmentTypeId', appointmentTypeId);
                    await setFieldValue('appointmentType', found.label);
                    await setFieldValue('appointmentLength', found.length);
                  }
                }}
                error={
                  !!errors?.appointmentTypeId ||
                  !!errors?.appointmentType ||
                  !!errors?.appointmentLength
                }
              />
            )}
            {fieldDisplayOptions.allowCategorySelection && (
              <DropDown
                label={t('withWhom')}
                options={columns}
                value={values.column}
                onChange={async (column) => {
                  values.preferredDate = '';
                  values.preferredTime = '';
                  const found = columns.find((a) => a.value === column);
                  if (found) {
                    if (column === 'No Preference') {
                      await setFieldValue('categoryId', undefined);
                      await setFieldValue('column', column);
                    } else if (fieldDisplayOptions.useAppointmentTypes) {
                      await setFieldValue('categoryId', found.categoryId);
                      await setFieldValue('column', column);
                    } else {
                      await setFieldValue('categoryId', found.categoryId);
                      await setFieldValue('appointmentLength', found.appointmentLength);
                      await setFieldValue('column', column);
                    }
                  }
                }}
                error={!!errors?.categoryId || !!errors?.column}
              />
            )}
            {!!openings && (
              <Row justify='flex-start' style={layout.smallGap}>
                <DatePicker
                  style={layout.flex1}
                  label={t('preferredDate')}
                  validRange={{
                    startDate: moment()
                      .startOf('day')
                      .add(fieldDisplayOptions.minDays, 'days')
                      .toDate(),
                    endDate: moment()
                      .startOf('day')
                      .add(fieldDisplayOptions.maxDays, 'days')
                      .toDate(),
                    disabledDates: unavailableDates
                  }}
                  value={datePickerValueFromString(values.preferredDate)}
                  error={!!errors?.preferredDate}
                  startYear={moment().year()}
                  endYear={moment().add(fieldDisplayOptions.maxDays, 'days').year()}
                  onConfirm={(date) => {
                    if (!date) {
                      void setFieldValue('preferredDate', undefined);
                    } else {
                      void setFieldValue('preferredDate', moment(date).format('YYYY-MM-DD'));
                      void setFieldValue('preferredTime', undefined);
                    }
                  }}
                />

                {!!values.preferredDate && (
                  <DropDown
                    style={layout.flex1}
                    label={t('preferredTime')}
                    options={generateAvailableTimes(
                      openings,
                      values.preferredDate,
                      practice?.timeZone ?? ''
                    ).map((value) => ({
                      value: toLocalTime(value, practice?.timeZone),
                      label: toLocalTime(value, practice?.timeZone),
                      custom: <CustomLabel time={value} practiceTimeZone={practice?.timeZone} />
                    }))}
                    value={values.preferredTime}
                    onChange={async (preferredTime) =>
                      await setFieldValue('preferredTime', preferredTime)
                    }
                    error={!!errors?.preferredTime}
                  />
                )}
              </Row>
            )}
            <TextInput
              label={t('comments')}
              value={values.comments ?? ''}
              onChangeText={async (comments) => await setFieldValue('comments', comments)}
              multiline
              clearButtonMode='always'
              error={!!errors?.comments}
            />
            <GeneralView>
              <RadioButtonGroup
                onValueChange={async (v) => await setFieldValue('preferredContactMethod', v)}
                value={values.preferredContactMethod}
              >
                <StyledBody>{t('preferredContactMethod')}</StyledBody>

                <Row justify='flex-start'>
                  <StyledRadioButton
                    value={PreferredContactMethod.Email}
                    label={t('email', { ns: 'common' })}
                    color={colors.primary}
                  />
                  <StyledRadioButton
                    value={PreferredContactMethod.Text}
                    label={t('text', { ns: 'common' })}
                    color={colors.primary}
                  />
                  <StyledRadioButton
                    value={PreferredContactMethod.Phone}
                    label={t('phone', { ns: 'common' })}
                    color={colors.primary}
                  />
                </Row>
              </RadioButtonGroup>
            </GeneralView>
            <GeneralView>
              <StyledBody>{t('common:addFiles')}</StyledBody>
              <UploadFiles onImageChange={onImageChange} images={images} />
            </GeneralView>
          </StyledMasterView>
        </ScrollView>
        {IS_WEB ? (
          <StickyButtonMasterView>
            <CPrimaryButton disabled={disableSubmit} onPress={handleSubmit}>
              {t('submit')}
            </CPrimaryButton>
          </StickyButtonMasterView>
        ) : (
          <BottomButton disabled={disableSubmit} onPress={() => handleSubmit()}>
            {t('submit')}
          </BottomButton>
        )}
      </TwoColorView>
    </>
  );
};

export default RequestAppointment;
