import { PropsWithChildren } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

export enum TOUR {
  ADD_TO_DO = 'tutorials-add-to-dos',
  APPOINTMENTS = 'tutorials-appointments',
  APPOINTMENT_DETAILS = 'tutorials-appointment-details',
  HOME = 'tutorials-home',
  LANDING = 'tutorials-landing',
  LOYALTY = 'tutorials-loyalty',
  PATIENT_PROFILE = 'tutorials-patient-profile',
  PETS = 'tutorials-pets',
  PRACTICE_CLIENT_SEARCH = 'tutorials-practice-client-search',
  PROFILE = 'tutorials-profile',
  TO_DOS = 'tutorials-to-dos',
  USER_INFO = 'tutorials-user-info'
}
export interface TourViewState extends Partial<{ [k in TOUR]: boolean }> {
  tutorialsEnabled: boolean;
}

export enum STATE {
  IDLE,
  PLAYING,
  PAUSED
}

export type TourStep<T extends string | number> = {
  id: T;
  priority?: number;
  onBefore?: () => void;
  onContinue?: () => void;
  handle?: React.RefObject<GuideElementHandle>;
};

export type TourMap<Steps extends string | number> = { [Step in Steps]: TourStep<Step> };

export interface TourGuideContext<T extends string | number> {
  tourId: string;
  focusedId?: T;
  state: STATE;
  exitTour: () => void;
  startTour: () => void;
  enabled: boolean;
  toursEnabled: boolean;
  registerStep: (stepId: T, handle?: React.RefObject<GuideElementHandle>) => void;
  unregisterStep: (stepId: T) => void;
  hasViewedTutorial: boolean;
  shouldUpdate: boolean;
  pendingSteps: boolean;
  updateScrollPositions: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

export interface _GuideElementProp<T extends string | number>
  extends PropsWithChildren<TourGuideContext<T>> {
  visible: boolean;
  enabled: boolean;
  thisRef: React.ForwardedRef<GuideElementHandle>;
}

export interface TourGuideProviderProps extends PropsWithChildren {
  disabled?: boolean;
}

export interface TourGuideHandle {
  startTour: () => void;
  exitTour: () => void;
  updateScrollPositions: () => void;
  reset: () => Promise<void>;
  removeStorageVisited: () => Promise<void>;
}

export interface GuideElementHandle {
  updateScrollPosition: () => void;
}
