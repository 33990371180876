import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Image, View } from 'react-native';
import { useAuth, AuthStatus } from 'src/providers/AuthProvider';
import { fontStyles } from 'src/theme/globalStyles';
import { MainStackScreen } from '../MainStack';
import { Navigators } from '../navigators';
import {
  useFonts,
  Roboto_100Thin,
  Roboto_100Thin_Italic,
  Roboto_300Light,
  Roboto_300Light_Italic,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_500Medium,
  Roboto_500Medium_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
  Roboto_900Black,
  Roboto_900Black_Italic
} from '@expo-google-fonts/roboto';
import { Screens } from '../screens';
import Landing from 'src/scenes/Landing';
import { ActivityIndicator } from 'react-native-paper';
import TermsAndConditions from 'src/scenes/TermsAndConditions';
import ChangePasswordModal from 'src/scenes/ChangePassword';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import RootStackParamList from './ParamsList';
import PetPageDisabled from 'src/scenes/PetPageDisabled';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import PracticeClientSearch from 'src/scenes/PracticeClientSearch';
import { AsyncStorageKeys } from 'src/api';
import { buildRecords } from 'src/utils/expoStorage';
import { imageCache } from 'src/utils/checkImageCache';
import { APP_ENV, TOKEN } from 'src/constants';
import { isDefined, storage } from 'src/utils';
import EmailConfirmation from 'src/scenes/EmailConfirmation';
import useDefaultModalScreenOptions from '../useDefaultModalScreenOptions';
import BiometricLogin from 'src/scenes/BiometricLogin';
import TokenResetPassword from 'src/scenes/TokenResetPassword';
import { useNotificationPrompt } from 'src/providers/NotificationPromptProvider';
import PushNotificationsOptIn from 'src/scenes/PushNotificationsOptIn';
import OnlineBooking from 'src/scenes/OnlineBooking';
import Sandbox from 'src/scenes/Sandbox';

const RootStack = createStackNavigator<RootStackParamList>();

const RootStackScreen: FC = () => {
  const { t } = useTranslation();
  const [fontsLoaded] = useFonts({
    Roboto_100Thin,
    Roboto_100Thin_Italic,
    Roboto_300Light,
    Roboto_300Light_Italic,
    Roboto_400Regular,
    Roboto_400Regular_Italic,
    Roboto_500Medium,
    Roboto_500Medium_Italic,
    Roboto_700Bold,
    Roboto_700Bold_Italic,
    Roboto_900Black,
    Roboto_900Black_Italic
  });
  const { ready, authStatus } = useAuth();
  const {
    colors,
    viewMode: { isMobile }
  } = useAppTheme();

  const [imageRecordsBuilt, setImageRecordsBuilt] = useState(false);

  useEffect(() => {
    if (!imageRecordsBuilt)
      void buildRecords().then(async (records) => {
        Object.assign(imageCache, records);
        try {
          await Promise.all(
            Object.values(imageCache).map(async (uri) => Image.prefetch(await uri))
          );
          setImageRecordsBuilt(true);
        } catch (error) {
          console.error('Error prefetching images', error);
        }
      });
  }, [imageRecordsBuilt, setImageRecordsBuilt]);

  useEffect(() => {
    // Set the token in the storage if the flag is set
    if (TOKEN) {
      void storage.setTypedItem<string>(AsyncStorageKeys.TOKEN, TOKEN);
    }
  }, []);

  const practiceClientSearchScreenOptions: StackNavigationOptions = useMemo(
    () => ({
      headerShown: true,
      headerTitleAlign: 'center',
      headerTintColor: colors.onPrimary,
      headerStyle: { backgroundColor: colors.primary },
      headerShadowVisible: false,
      headerBackTitleVisible: false
    }),
    [colors.onPrimary, colors.primary]
  );

  const modalScreenOptions = useDefaultModalScreenOptions();
  const { hasPrompted } = useNotificationPrompt();

  if (
    !fontsLoaded ||
    !ready ||
    !imageRecordsBuilt ||
    !isDefined(authStatus) ||
    authStatus === AuthStatus.SWITCHING_PRACTICE
  ) {
    return <CenteredIndicator size={'large'} />;
  }
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <RootStack.Navigator
        screenOptions={{ headerTitleStyle: fontStyles.headline }}
        initialRouteName={getInitialRouteName(authStatus)}
      >
        <RootStack.Group
          screenOptions={{
            headerShown: false,
            cardStyle: { backgroundColor: colors.background },
            animationEnabled: true
          }}
        >
          {authStatus === AuthStatus.LOGGED_OUT && (
            <>
              <RootStack.Screen
                name={Screens.LANDING}
                component={Landing}
                options={{ title: t('login:longAppName') }}
              />
              <RootStack.Screen
                name={Screens.PRACTICE_CLIENT_SEARCH}
                component={PracticeClientSearch}
                options={practiceClientSearchScreenOptions}
              />
            </>
          )}
          {authStatus === AuthStatus.FORBIDDEN && (
            <RootStack.Screen
              name={Screens.PET_PAGE_DISABLED}
              component={PetPageDisabled}
              options={{ title: t('login:longAppName') }}
            />
          )}
          {authStatus === AuthStatus.NEEDS_BIOMETRIC && (
            <RootStack.Screen
              name={Screens.BIOMETRIC_LOGIN_AUTH}
              component={BiometricLogin}
              options={{ cardStyle: { backgroundColor: colors.primary } }}
            />
          )}
        </RootStack.Group>
        <RootStack.Group
          screenOptions={{
            headerShown: true,
            headerTitleAlign: 'center',
            headerTintColor: colors.text,
            headerStyle: { backgroundColor: colors.background },
            cardStyle: { backgroundColor: colors.background }
          }}
        >
          {authStatus === AuthStatus.NEEDS_TERMS && (
            <RootStack.Screen
              name={Screens.ACCEPT_TERMS}
              component={TermsAndConditions}
              options={{ title: t('title', { ns: 'eula' }) }}
            />
          )}
          {authStatus === AuthStatus.NEEDS_PASSWORD_RESET && (
            <RootStack.Screen
              name={Screens.RESET_PASSWORD_REQUIRED}
              component={ChangePasswordModal}
              options={{ title: t('title', { ns: 'changePasswordModal' }) }}
            />
          )}
          {authStatus === AuthStatus.AUTHENTICATED && (
            <RootStack.Screen
              name={Navigators.MAIN_STACK}
              component={MainStackScreen}
              options={{
                headerShown: false
              }}
            />
          )}
        </RootStack.Group>
        <RootStack.Group screenOptions={modalScreenOptions}>
          <RootStack.Screen
            name={Screens.EMAIL_CONFIRMATION}
            component={EmailConfirmation}
            options={{
              title: t('title', { ns: 'emailConfirmation' })
            }}
          />
          <RootStack.Screen
            name={Screens.TOKEN_PASSWORD_RESET}
            component={TokenResetPassword}
            options={{
              title: t('title', { ns: 'tokenPasswordReset' })
            }}
          />
          {!hasPrompted && (
            <RootStack.Screen
              name={Screens.PUSH_NOTIFICATIONS_OPT_IN}
              component={PushNotificationsOptIn}
              options={{
                headerLeft: () => null,
                headerShadowVisible: false,
                title: t('pushNotifications', { ns: 'pushNotifications' })
              }}
            />
          )}
          {APP_ENV !== 'PROD' && (
            <RootStack.Screen
              name={Screens.SANDBOX}
              component={Sandbox}
              options={{
                ...modalScreenOptions,
                title: 'Sandbox'
              }}
            />
          )}
          <RootStack.Screen
            name={Screens.ONLINE_BOOKING}
            component={OnlineBooking}
            options={{ ...modalScreenOptions, title: 'Online Booking', headerShown: isMobile }}
          />
        </RootStack.Group>
      </RootStack.Navigator>
    </View>
  );
};

export default RootStackScreen;

const CenteredIndicator = styled(ActivityIndicator).attrs(({ theme }) => ({
  color: theme.colors.onPrimary
}))`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const getInitialRouteName = (
  authStatus: AuthStatus | undefined
): Navigators.MAIN_STACK | Screens.LANDING | undefined => {
  if (authStatus === AuthStatus.AUTHENTICATED) {
    return Navigators.MAIN_STACK;
  }
  if (authStatus === AuthStatus.LOGGED_OUT) {
    return Screens.LANDING;
  }
  return undefined;
};
