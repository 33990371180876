import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getRequestSetting, QueryKeys } from 'src/api';
import { Patient } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';
import RequestSetting from 'src/interfaces/api/RequestSetting';
import { useUser } from 'src/providers/ClientProvider';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import { openWebBrowser } from 'src/utils';

export type RequestSettingHook = UseQueryResult<RequestSetting, unknown> & {
  requestAppointment: (patientId?: Patient['patientId']) => void;
  requestRefill: (callback: () => void) => void;
};

export const requestSettingsOptions = (
  practiceId: Practice['id']
): UseQueryOptions<RequestSetting, unknown, RequestSetting, any> => ({
  queryKey: [QueryKeys.REQUEST_SETTING, practiceId],
  queryFn: async () => await getRequestSetting(practiceId)
});

const useRequestSetting = (): RequestSettingHook => {
  const { user } = useUser();
  const { navigate } = useNavigation();

  const queryResults = useQuery(requestSettingsOptions(user.practiceId));

  const requestAppointment = useCallback(
    (patientId?: Patient['patientId']) => {
      if (!queryResults.data || queryResults.data?.appointmentRequestsDisabled) {
        return;
      }
      if (
        queryResults.data.appointmentRequestOverride &&
        queryResults.data.appointmentRequestOverrideUrl
      ) {
        openWebBrowser(queryResults.data.appointmentRequestOverrideUrl);
      } else {
        navigate(Navigators.MAIN_STACK, {
          screen: Screens.APPOINTMENT_REQUEST,
          initial: false,
          params: {
            id: patientId
          }
        });
      }
    },
    [navigate, queryResults.data]
  );

  const requestRefill = useCallback(
    (callback: () => void) => {
      if (!queryResults.data || queryResults.data?.refillRequestsDisabled) {
        return;
      }
      if (queryResults.data.refillRequestOverride && queryResults.data.refillRequestOverrideUrl) {
        openWebBrowser(queryResults.data.refillRequestOverrideUrl);
      } else {
        callback();
      }
    },
    [queryResults.data]
  );

  return { ...queryResults, requestAppointment, requestRefill };
};

export default useRequestSetting;
