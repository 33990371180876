import React, { useEffect } from 'react';
import { ContentContainer, StyledScrollView } from './shared';
import { Body, Headline } from 'src/components';
import { useTranslation } from 'react-i18next';
import { useBookingState } from '../BookingProvider';
import { CONFIRMATION_SECTIONS } from '../BookingProvider/helpers';
import ConfirmationSection from './ConfirmationSection';

const Confirmation: React.FC = () => {
  const { t } = useTranslation('onlineBooking');
  const { practiceInfo, values, errors, setBookingState, validateForm } = useBookingState();

  useEffect(() => {
    void validateForm();
    setBookingState((prevState) => ({
      ...prevState,
      hasCompleted: true
    }));
  }, [setBookingState, validateForm]);

  return (
    <StyledScrollView>
      <ContentContainer>
        <Headline>{t('confirmation.title')}</Headline>
        <Body>{t('confirmation.description')}</Body>
      </ContentContainer>

      {Object.values(CONFIRMATION_SECTIONS).map(({ section, ...props }) => (
        <ConfirmationSection
          key={section}
          section={section}
          values={values}
          errors={errors}
          practiceInfo={practiceInfo}
          {...props}
        />
      ))}
    </StyledScrollView>
  );
};

export default Confirmation;
