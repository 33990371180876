import { Body, Caption } from 'src/components';
import { IconSize, Margin } from 'src/constants';
import { transparent } from 'src/utils';
import styled from 'styled-components/native';

export const Row = styled.View`
  flex-flow: row wrap;
  flex-shrink: 0;
  row-gap: ${Margin.Medium}px;
`;

export const DetailRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DetailColumn = styled.View`
  flex-direction: column;
  gap: ${Margin.Medium}px;
`;

export const InputContainer = styled.View<{ halfSize?: boolean }>`
  flex-basis: ${({ theme, halfSize }) => (!theme.viewMode.isMobile && halfSize ? '50%' : '100%')};
  padding: 0 ${Margin.Small}px;
`;

export const ContentContainer = styled.View`
  padding-horizontal: ${Margin.Large}px;
  gap: ${Margin.Large}px;
`;

export const NoticeContainer = styled.View`
  padding: ${Margin.Large}px;
  gap: ${Margin.Large}px
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.1)};
  border-radius: ${({ theme }) => theme.roundness}px;
`;

export const NoticeSection = styled.View`
  flex-direction: row;
  gap: ${Margin.Small}px;
`;

export const NoticeText = styled(Body)<{ error?: boolean }>`
  color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.onSurface)};
`;

export const NoticeIcon = styled.View`
  width: ${IconSize.S}px;
  height: ${IconSize.S}px;
  justify-content: center;
  align-items: center;
`;

export const NoticeTextContainer = styled.View`
  flex: 1;
  gap: ${Margin.Medium}px;
`;

export const ErrorText = styled(Caption)`
  color: ${({ theme }) => theme.colors.error};
`;

export const StyledScrollView = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    flexGrow: 1,
    gap: Margin.Large,
    alignItems: 'stretch',
    paddingVertical: Margin.ExtraLarge,
    paddingHorizontal: theme.viewMode.isMobile ? Margin.Medium : Margin.ExtraLarge
  },
  keyboardShouldPersistTaps: 'handled'
}))`
  flex: 1;
`;
