import React from 'react';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { fontStyles } from 'src/theme/globalStyles';
import { IconProps } from 'src/components/Icons';
import { Caption, TextProps } from 'src/components/Text';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const DEFAULT_STROKE_WIDTH = 2;
const STROKE_TO_FONT_WEIGHT_RATIO = 1 / 200;
interface Props extends TextProps {
  icon: React.FC<IconProps | SvgProps>;
  rowStyle?: StyleProp<ViewStyle>;
}
const IconTextLink: React.FC<Props> = ({
  icon: Icon,
  children,
  color,
  style,
  rowStyle,
  ...props
}) => {
  const { colors } = useAppTheme();
  const flatStyle: TextStyle = StyleSheet.flatten([
    { ...fontStyles.caption, color: color ?? colors.text },
    style
  ]);

  return (
    <Caption>
      <Icon
        color={flatStyle?.color ?? color ?? colors.text}
        width={flatStyle.fontSize}
        height={flatStyle.fontSize}
        strokeWidth={
          flatStyle.fontWeight
            ? parseInt(flatStyle.fontWeight) * STROKE_TO_FONT_WEIGHT_RATIO
            : DEFAULT_STROKE_WIDTH
        }
      />{' '}
      <Caption {...props} style={flatStyle}>
        {children}
      </Caption>
    </Caption>
  );
};

export default IconTextLink;
