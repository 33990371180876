import * as React from 'react';

import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { Navigators } from '../navigators';
import { HomeStackNavigator } from '../HomeStackNavigator';
import { AppointmentsStackNavigator } from '../AppointmentStackNavigator';
import { LoyaltyStackNavigator } from '../LoyaltyTabNavigator';
import { useAbility } from 'src/hooks/react-query';
import { transparent } from 'src/utils';
import { MOBILE_WIDTH_EXTRA_SMALL, Size } from 'src/constants';
import { PatientStackNavigator } from '../PatientStackNavigator';
import ToDoStackNavigator from '../ToDoStackNavigator';
import useTodos from 'src/hooks/react-query/todos/useTodos';
import { useState } from 'react';
import moment from 'moment-timezone';
import { AlertBadge } from 'src/components';
import { TFunction, useTranslation } from 'react-i18next';
import BottomTabParamsList from './ParamsList';
import { Calendar, House, Trophy, Paw, AlarmClock } from 'src/components/kit/ABIcons';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useViewMode } from 'src/hooks';
import { Inbox } from 'react-native-feather';
import RequestStackNavigator from '../RequestStackNavigator';

const TabNavigator = createMaterialBottomTabNavigator<BottomTabParamsList>();

export const BottomTabNavigator: React.FC = () => {
  const { userCanViewLoyalty } = useAbility();
  const { colors } = useAppTheme();
  const { t } = useTranslation();

  const { windowWidth } = useViewMode();

  const [todoAlert, setTodoAlert] = useState(false);
  useTodos({
    isComplete: false,
    onFetchSuccess: (todos) => {
      const dueNow = todos?.some((todo) => moment(todo.dueDate).isBefore() || !todo.dueDate);
      setTodoAlert(dueNow);
    }
  });

  return (
    <TabNavigator.Navigator
      backBehavior='history'
      shifting
      labeled
      activeColor={colors.onPrimary}
      inactiveColor={transparent(colors.onPrimary, 0.5)}
      barStyle={{ backgroundColor: colors.primary }}
      screenOptions={({ route }) => ({
        cardStyle: { backgroundColor: colors.background },
        tabBarIcon: ({ color }) => {
          switch (route.name) {
            case Navigators.HOME_STACK:
              return <House width={Size.M} height={Size.M} color={color} />;
            case Navigators.APPOINTMENTS_STACK:
              return <Calendar width={Size.M} height={Size.M} color={color} />;
            case Navigators.LOYALTY_STACK:
              return <Trophy width={Size.M} height={Size.M} color={color} />;
            case Navigators.PET_STACK:
              return <Paw width={Size.M} height={Size.M} color={color} />;
            case Navigators.TODO_STACK:
              return (
                <AlertBadge hasAlert={todoAlert}>
                  <AlarmClock width={Size.M} height={Size.M} color={color} />
                </AlertBadge>
              );
            case Navigators.REQUEST_STACK:
              return <Inbox width={Size.M} height={Size.M} color={color} />;
            default:
              return null;
          }
        },
        tabBarLabel: routeNameGen(route.name, windowWidth, t)
      })}
    >
      <TabNavigator.Screen name={Navigators.HOME_STACK} component={HomeStackNavigator} />
      <TabNavigator.Screen name={Navigators.PET_STACK} component={PatientStackNavigator} />
      <TabNavigator.Screen
        name={Navigators.APPOINTMENTS_STACK}
        component={AppointmentsStackNavigator}
      />
      {userCanViewLoyalty && (
        <TabNavigator.Screen name={Navigators.LOYALTY_STACK} component={LoyaltyStackNavigator} />
      )}
      <TabNavigator.Screen name={Navigators.TODO_STACK} component={ToDoStackNavigator} />
      <TabNavigator.Screen name={Navigators.REQUEST_STACK} component={RequestStackNavigator} />
    </TabNavigator.Navigator>
  );
};

export default BottomTabNavigator;

const routeNameGen = (
  routeName: string,
  windowWidth: number,
  t: TFunction<'translation', undefined>
) => {
  switch (routeName) {
    case Navigators.HOME_STACK:
      return t('title', { ns: 'home' });
    case Navigators.APPOINTMENTS_STACK:
      return windowWidth > MOBILE_WIDTH_EXTRA_SMALL
        ? t('title', { ns: 'appointments' })
        : t('titleShort', { ns: 'appointments' });
    case Navigators.LOYALTY_STACK:
      return t('shortTitle', { ns: 'loyalty' });
    case Navigators.PET_STACK:
      return t('title', { ns: 'petList' });
    case Navigators.TODO_STACK:
      return t('list.title', { ns: 'todos' });
    case Navigators.REQUEST_STACK:
      return t('requestsTitle', { ns: 'requests' });
    default:
      return '';
  }
};
