import React, { useLayoutEffect } from 'react';
import { useBookingState } from '../BookingProvider';
import { ContentContainer, InputContainer, Row, StyledScrollView } from './shared';
import { Body, Headline, HoverButton, TextInput } from 'src/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import SelectPatient from './SelectPatient';
import { ActivityIndicator } from 'react-native-paper';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { LayoutAnimation } from 'react-native';

const PatientInfo: React.FC = () => {
  const { t } = useTranslation('onlineBooking');
  const { colors } = useAppTheme();
  const {
    practiceInfo,
    values: { clientInfo, patientInfo },
    clientPatientQuery: { mutateAsync, variables },
    setFieldValue,
    validateField,
    errors,
    clientPatientQuery
  } = useBookingState();

  useLayoutEffect(() => {
    if (clientInfo.emailAddress !== variables?.email) {
      void mutateAsync({
        email: clientInfo.emailAddress,
        practiceId: practiceInfo.practiceId
      });
    }
  }, [clientInfo.emailAddress, mutateAsync, practiceInfo.practiceId, variables?.email]);

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [patientInfo]);

  if (clientPatientQuery.isLoading) {
    return <ActivityIndicator color={colors.primary} size='large' style={{ flex: 1 }} />;
  }

  if (!clientPatientQuery.data?.patients?.length || !!patientInfo?.isNewPatient) {
    return (
      <StyledScrollView>
        <ContentContainer>
          <Headline>{t('patientInfo.title')}</Headline>
          <Body>{t('patientInfo.description')}</Body>
        </ContentContainer>
        <Row>
          <InputContainer halfSize>
            <TextInput
              label={t('form.name')}
              value={patientInfo?.name}
              onChangeText={async (text) => setFieldValue('patientInfo.name', text, false)}
              onBlur={async () => validateField('patientInfo.name')}
              error={!!errors.patientInfo?.name}
              msg={t(errors.patientInfo?.name ?? '')}
            />
          </InputContainer>
          <InputContainer halfSize>
            <TextInput
              label={t('form.breed')}
              value={patientInfo?.breed}
              onChangeText={async (text) => setFieldValue('patientInfo.breed', text, false)}
              onBlur={async () => validateField('patientInfo.breed')}
              error={!!errors.patientInfo?.breed}
              msg={t(errors.patientInfo?.breed ?? '')}
            />
          </InputContainer>
          <InputContainer halfSize>
            <TextInput
              label={t('form.sex')}
              value={patientInfo?.sex}
              onChangeText={async (text) => setFieldValue('patientInfo.sex', text, false)}
              onBlur={async () => validateField('patientInfo.sex')}
              error={!!errors.patientInfo?.sex}
              msg={t(errors.patientInfo?.sex ?? '')}
            />
          </InputContainer>
          <InputContainer halfSize>
            <TextInput
              label={t('form.age')}
              value={patientInfo?.age}
              onChangeText={async (text) => setFieldValue('patientInfo.age', text, false)}
              onBlur={async () => validateField('patientInfo.age')}
              error={!!errors.patientInfo?.age}
              msg={t(errors.patientInfo?.age ?? '')}
            />
          </InputContainer>
        </Row>
        {!!clientPatientQuery.data?.patients?.length && (
          <StyledButton onPress={async () => setFieldValue('patientInfo.isNewPatient', undefined)}>
            {t('patientInfo.selectExisting')}
          </StyledButton>
        )}
      </StyledScrollView>
    );
  }
  return <SelectPatient />;
};

export default PatientInfo;

const StyledButton = styled(HoverButton).attrs({
  compact: true
})`
  align-self: flex-start;
`;
