import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import HomeHero from './components/HomeHero';

export enum STEPS {
  DRAWER,
  SWITCH_PRACTICE,
  ANNOUNCEMENTS,
  QUICK_ACCESS,
  APPOINTMENTS,
  REMINDERS,
  PRESCRIPTIONS,
  TO_DOS,
  HELP
}

const HomePageTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: { id: STEPS.DRAWER, priority: 0 },
  [STEPS.SWITCH_PRACTICE]: { id: STEPS.SWITCH_PRACTICE, priority: 1 },
  [STEPS.ANNOUNCEMENTS]: { id: STEPS.ANNOUNCEMENTS, priority: 2 },
  [STEPS.QUICK_ACCESS]: { id: STEPS.QUICK_ACCESS, priority: 3 },
  [STEPS.APPOINTMENTS]: { id: STEPS.APPOINTMENTS, priority: 4 },
  [STEPS.REMINDERS]: { id: STEPS.REMINDERS, priority: 5 },
  [STEPS.PRESCRIPTIONS]: { id: STEPS.PRESCRIPTIONS, priority: 6 },
  [STEPS.TO_DOS]: { id: STEPS.TO_DOS, priority: 7 },
  [STEPS.HELP]: { id: STEPS.HELP, priority: 8 }
};

export const SLIDES: SlideItem[] = [
  {
    title: 'home:tour.drawer.title',
    body: 'home:tour.drawer.body',
    image: <HomeHero />
  }
];

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.HOME,
  HomePageTourMap
);
