import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'react-native-feather';
import { Body, Row } from 'src/components';
import { IconSize, Size } from 'src/constants';
import { Weight } from 'src/theme';
import styled from 'styled-components/native';
import { StyledCard } from './shared';
import { ActivityIndicator } from 'react-native-paper';
import useRequestSetting from 'src/hooks/react-query/useRequestSetting';
import { useSelectedPatient } from 'src/providers/SelectedPatientProvider';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const RequestAppointmentCta: React.FC = () => {
  const { t } = useTranslation('appointments');
  const { data: requestSettings, requestAppointment, isFetching } = useRequestSetting();
  const { selectedPatient } = useSelectedPatient();
  const { colors } = useAppTheme();

  if (requestSettings?.appointmentRequestsDisabled) {
    return null;
  }

  if (isFetching) {
    return (
      <StyledCard>
        <ActivityIndicator />
      </StyledCard>
    );
  }

  return (
    <StyledCard onPress={() => requestAppointment(selectedPatient?.patientId)}>
      <Row>
        <FilledIconContainer>
          <Plus width={IconSize.XS} color={colors.onPrimary} />
        </FilledIconContainer>
        <Body color={colors.onSurface} fontWeight={Weight.MEDIUM}>
          {t('requestAnAppointment')}
        </Body>
      </Row>
    </StyledCard>
  );
};

export default RequestAppointmentCta;

const FilledIconContainer = styled.View`
  width: ${Size.M}px;
  height: ${Size.M}px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin-right: ${Size.XS}px;
`;
