import React, { useRef, useState } from 'react';
import { Slide, SlideItem } from './Slide';
import Pagination from './Pagination';
import {
  Animated,
  FlatList,
  FlatListProps,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent
} from 'react-native';
import FixCellOffsetOnWeb from './FixCellOffsetOnWeb';

const viewabilityConfig = {
  itemVisiblePercentThreshold: 50
};

interface Props extends Omit<FlatListProps<SlideItem>, 'renderItem' | 'viewabilityConfig'> {
  data: SlideItem[];
}

const Slider = React.forwardRef<FlatList<SlideItem>, Props>(
  ({ data, onScroll: parentOnScroll, onLayout: parentOnLayout, ...props }, ref) => {
    const [width, setWidth] = useState<number>();
    const scrollX = useRef(new Animated.Value(0)).current;
    const [index, setIndex] = useState(0);

    const onHandleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (!width) return;
      setIndex(Math.floor(event.nativeEvent.contentOffset.x / width));

      Animated.event(
        [
          {
            nativeEvent: {
              contentOffset: { x: scrollX }
            }
          }
        ],
        {
          useNativeDriver: false
        }
      )(event);
      parentOnScroll?.(event);
    };

    const onHandleLayout = useRef((event: LayoutChangeEvent) => {
      setWidth(event.nativeEvent.layout.width);
      parentOnLayout?.(event);
    }).current;

    const flatListRef = useRef<FlatList<SlideItem> | null>(null);

    return (
      <>
        <FlatList
          {...props}
          CellRendererComponent={(props) => <FixCellOffsetOnWeb {...props} {...{ width }} />}
          ref={(r) => {
            flatListRef.current = r;
            if (typeof ref === 'function') {
              ref(r);
            } else if (ref) {
              ref.current = r;
            }
          }}
          data={data}
          renderItem={({ item, index }) => {
            if (!width) return null;
            return (
              <Slide
                item={item}
                width={width}
                scrollX={scrollX}
                sliderWidth={width}
                index={index}
              />
            );
          }}
          onLayout={onHandleLayout}
          horizontal
          pagingEnabled
          snapToAlignment={'center'}
          showsHorizontalScrollIndicator={false}
          onScroll={onHandleScroll}
          scrollEventThrottle={1}
          viewabilityConfig={viewabilityConfig}
        />
        {!!width && (
          <Pagination
            slides={data}
            scrollX={scrollX}
            slideRef={flatListRef}
            index={index}
            sliderWidth={width}
          />
        )}
      </>
    );
  }
);

export default Slider;
