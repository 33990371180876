/**
 * This file is used to mock the firebase messaging module on the web.
 */

import { FirebaseApp, initializeApp, getApps } from 'firebase/app';
import {
  onMessage as _onMessage,
  getMessaging,
  Messaging,
  getToken as _getToken,
  Observer,
  MessagePayload
} from 'firebase/messaging';
import { noop } from 'lodash';
import { isSafari, isIOS } from 'react-device-detect';
import config from 'src/environment';

let firebase: FirebaseApp;
let messaging: Messaging;

const IS_SAFARI_IOS = isSafari && isIOS;

const initialize = () => {
  if (IS_SAFARI_IOS || getApps().length) return;
  firebase = initializeApp(config.FIREBASE);
  messaging = getMessaging(firebase);
};

initialize();

export const getToken = async () => (IS_SAFARI_IOS ? noop : _getToken(messaging));
export const onMessage = (listener: Observer<MessagePayload>) =>
  IS_SAFARI_IOS ? noop : _onMessage(messaging, listener);
