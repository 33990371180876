import { deletePatientPhoto, MutationKeys, postPatientPhoto, QueryKeys } from 'src/api';
import { Action, manipulateAsync } from 'expo-image-manipulator';
import pickImage from 'src/utils/pickImage';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import takeImage from 'src/utils/takeImage';
import { useCallback } from 'react';
import { Patient } from 'src/interfaces';

interface UploadPhotoHook {
  preparePhotoUpload: (camera: boolean) => Promise<string | undefined>;
  uploadPhoto: UseMutateFunction<Patient, Error, { patient: Patient; uri: string }, unknown>;
  deletePhoto: () => void;
  isMutating: boolean;
}
interface Options {
  /**
   * Series of actions to manipulate the photo before uploading.
   */
  manipulate?: Action[];
}

const useUploadPatientPhoto: (patient?: Patient, options?: Options) => UploadPhotoHook = (
  patient,
  { manipulate = [] } = {}
): UploadPhotoHook => {
  const { t } = useTranslation('patientProfile');

  const queryClient = useQueryClient();

  const { mutateAsync: uploadPhoto, isLoading: isUploading } = useMutation({
    mutationKey: [MutationKeys.PATIENT_PHOTO_UPLOAD, patient?.patientId],
    mutationFn: async ({ patient, uri }: { patient: Patient; uri: string }) =>
      await postPatientPhoto(patient.sourceId, patient.patientId, uri),
    onSuccess: async () =>
      queryClient.refetchQueries({ queryKey: [QueryKeys.PATIENT, patient?.patientId] }),

    onError: (error: Error) => {
      Toast.show({
        type: 'error',
        text1: error.name,
        text2: error.message
      });
    }
  });

  const { mutateAsync: deletePhoto, isLoading: isDeleting } = useMutation({
    mutationKey: [MutationKeys.PATIENT_PHOTO_UPLOAD, patient?.patientId],
    mutationFn: async () => {
      if (!patient) return;
      return await deletePatientPhoto(patient.sourceId, patient.patientId);
    },
    onSuccess: (_) => {
      void queryClient.invalidateQueries([QueryKeys.PATIENT, patient?.patientId]);
      Toast.show({ text1: t('deletePhotoSuccess') });
    },
    onError: (error: Error) => {
      Toast.show({
        type: 'error',
        text1: error.name,
        text2: error.message
      });
    }
  });

  const preparePhotoUpload = useCallback(
    async (camera: boolean): Promise<string | undefined> => {
      const imagePickerResult = camera ? await takeImage() : await pickImage();
      if (imagePickerResult && !imagePickerResult?.canceled) {
        const imageResult = await manipulateAsync(imagePickerResult.assets[0].uri, manipulate);
        return imageResult.uri;
      }
      return undefined;
    },
    [manipulate]
  );

  return { preparePhotoUpload, uploadPhoto, deletePhoto, isMutating: isUploading || isDeleting };
};

export default useUploadPatientPhoto;
