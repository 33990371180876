import { IS_DEBUG } from 'src/constants';
import * as Application from 'expo-application';

export const interpolateName = (name: string, node?: React.ReactNode): string => {
  if (typeof node === 'string') {
    return `${name}: ${node}`;
  }
  return name;
};

export const getRelease = () =>
  (IS_DEBUG ? undefined : Application.nativeApplicationVersion) ?? undefined;

export const SAMPLE_RATE = 0.05;

export const ignoreErrors = [
  'Non-Error exception captured',
  'Something went wrong!',
  'AxiosError',
  'CancelledError'
];
