import React, { useLayoutEffect, useRef, useState } from 'react';
import { Checkbox } from 'react-native-paper';
import { IS_WEB, IconSize } from 'src/constants';
import { NoticeSection, NoticeIcon, NoticeText } from './shared';
import { useTranslation } from 'react-i18next';
import { useBookingState } from '../BookingProvider';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Caption } from 'src/components';
import { Animated } from 'react-native';

const TermsCheckbox: React.FC = () => {
  const { t } = useTranslation('onlineBooking');
  const { colors } = useAppTheme();
  const {
    setFieldValue,
    validateField,
    errors,
    values: { clientInfo }
  } = useBookingState();

  const [displayedError, setDisplayedError] = useState(errors.clientInfo?.acceptConditions);
  const shakeValue = useRef(new Animated.Value(0)).current;

  const shake = useRef(() =>
    Animated.sequence([
      Animated.timing(shakeValue, { toValue: 10, duration: 100, useNativeDriver: !IS_WEB }),
      Animated.timing(shakeValue, { toValue: -10, duration: 100, useNativeDriver: !IS_WEB }),
      Animated.timing(shakeValue, { toValue: 10, duration: 100, useNativeDriver: !IS_WEB }),
      Animated.timing(shakeValue, { toValue: 0, duration: 100, useNativeDriver: !IS_WEB })
    ]).start((o) => {
      shakeValue.setValue(0);
    })
  );

  useLayoutEffect(() => {
    if (errors.clientInfo?.acceptConditions) {
      const timeout = setTimeout(() => {
        setDisplayedError(errors.clientInfo?.acceptConditions);
        shake.current();
      }, 100);
      return () => clearTimeout(timeout);
    } else {
      setDisplayedError(errors.clientInfo?.acceptConditions);
      shakeValue.setValue(0);
    }
  }, [errors.clientInfo?.acceptConditions, shakeValue]);

  return (
    <Animated.View style={{ transform: [{ translateX: shakeValue }] }}>
      <NoticeSection>
        <NoticeIcon>
          <Checkbox.Android
            color={colors.primary}
            uncheckedColor={displayedError ? colors.error : undefined}
            style={{ width: IconSize.XS, height: IconSize.XS }}
            status={clientInfo.acceptConditions ? 'checked' : 'unchecked'}
            onPress={async () => {
              await setFieldValue('clientInfo.acceptConditions', !clientInfo.acceptConditions);
              setTimeout(() => {
                void validateField('clientInfo.acceptConditions');
              }, 0);
            }}
          />
        </NoticeIcon>
        <NoticeText as={Caption} error={!!displayedError}>
          {t('clientInfo.disclaimer.acceptConditions')}
        </NoticeText>
      </NoticeSection>
    </Animated.View>
  );
};

export default TermsCheckbox;
