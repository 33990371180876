import { Language } from 'src/constants';

import AddToCalendarEN from './locales/en/addToCalendar.json';
import AddToCalendarES from './locales/es/addToCalendar.json';
import AfterHoursEN from './locales/en/afterHours.json';
import AfterHoursES from './locales/es/afterHours.json';
import AppointmentRequestEN from './locales/en/appointmentRequest.json';
import AppointmentRequestES from './locales/es/appointmentRequest.json';
import AppointmentsEN from './locales/en/appointments.json';
import AppointmentsES from './locales/es/appointments.json';
import BiometricLoginEN from './locales/en/biometricLogin.json';
import BiometricLoginES from './locales/es/biometricLogin.json';
import ChangePasswordEN from './locales/en/changePassword.json';
import ChangePasswordES from './locales/es/changePassword.json';
import ChangePasswordModalEN from './locales/en/changePasswordModal.json';
import ChangePasswordModalES from './locales/es/changePasswordModal.json';
import CommonEN from './locales/en/common.json';
import CommonES from './locales/es/common.json';
import ContactAllyEN from './locales/en/contactAlly.json';
import ContactAllyES from './locales/es/contactAlly.json';
import ContactUsEN from './locales/en/contactUs.json';
import ContactUsES from './locales/es/contactUs.json';
import DiscardChangesEN from './locales/en/discardChanges.json';
import DiscardChangesES from './locales/es/discardChanges.json';
import EulaEN from './locales/en/eula.json';
import EulaES from './locales/es/eula.json';
import GenericErrorEN from './locales/en/genericError.json';
import GenericErrorES from './locales/es/genericError.json';
import HelpCenterEN from './locales/en/helpCenter.json';
import HelpCenterES from './locales/es/helpCenter.json';
import HomeEN from './locales/en/home.json';
import HomeES from './locales/es/home.json';
import InfoUpdateEN from './locales/en/informationUpdate.json';
import InfoUpdateES from './locales/es/informationUpdate.json';
import LoginEN from './locales/en/login.json';
import LoginES from './locales/es/login.json';
import LoyaltyEN from './locales/en/loyalty.json';
import LoyaltyES from './locales/es/loyalty.json';
import OnboardingErrorsEN from './locales/en/onboardingErrors.json';
import OnboardingErrorsES from './locales/es/onboardingErrors.json';
import PatientProfileEN from './locales/en/patientProfile.json';
import PatientProfileES from './locales/es/patientProfile.json';
import PermissionsEN from './locales/en/permissions.json';
import PermissionsES from './locales/es/permissions.json';
import PetIDEN from './locales/en/petID.json';
import PetIDES from './locales/es/petID.json';
import PetListEN from './locales/en/petList.json';
import PetListES from './locales/es/petList.json';
import PracticeClientSearchEN from './locales/en/practiceClientSearch.json';
import PracticeClientSearchES from './locales/es/practiceClientSearch.json';
import ProfileClientPracticeConfigEN from './locales/en/profileClientPracticeConfig.json';
import ProfileClientPracticeConfigES from './locales/es/profileClientPracticeConfig.json';
import ProfileRootEN from './locales/en/profileRoot.json';
import ProfileRootES from './locales/es/profileRoot.json';
import PushNotificationsEN from './locales/en/pushNotifications.json';
import PushNotificationsES from './locales/es/pushNotifications.json';
import RequestsEN from './locales/en/requests.json';
import RequestsES from './locales/es/requests.json';
import SelectPracticeEN from './locales/en/selectPractice.json';
import SelectPracticeES from './locales/es/selectPractice.json';
import SettingsEN from './locales/en/settings.json';
import SettingsES from './locales/es/settings.json';
import TimeRefEN from './locales/en/timeReference.json';
import TimeRefES from './locales/es/timeReference.json';
import TodosEN from './locales/en/todos.json';
import TodosES from './locales/es/todos.json';
import UserInfoEN from './locales/en/userInfo.json';
import UserInfoES from './locales/es/userInfo.json';
import ValidationEN from './locales/en/validation.json';
import ValidationES from './locales/es/validation.json';
import PetPageDisabledEN from './locales/en/petPageDisabled.json';
import PetPageDisabledES from './locales/es/petPageDisabled.json';
import AppErrorEN from './locales/en/appError.json';
import AppErrorES from './locales/es/appError.json';
import EmailConfirmationEn from './locales/en/emailConfirmation.json';
import EmailConfirmationEs from './locales/es/emailConfirmation.json';
import TokenResetPasswordEN from './locales/en/tokenPasswordReset.json';
import TokenResetPasswordES from './locales/es/tokenPasswordReset.json';
import OnlineBookingEN from './locales/en/onlineBooking.json';
import OnlineBookingES from './locales/es/onlineBooking.json';

export const defaultLanguage = Language.en;

export const languagesResources = {
  en: {
    addToCalendar: AddToCalendarEN,
    afterHours: AfterHoursEN,
    appError: AppErrorEN,
    appointmentRequest: AppointmentRequestEN,
    appointments: AppointmentsEN,
    biometricLogin: BiometricLoginEN,
    changePassword: ChangePasswordEN,
    changePasswordModal: ChangePasswordModalEN,
    common: CommonEN,
    contactAlly: ContactAllyEN,
    contactUs: ContactUsEN,
    discardChanges: DiscardChangesEN,
    emailConfirmation: EmailConfirmationEn,
    eula: EulaEN,
    genericError: GenericErrorEN,
    helpCenter: HelpCenterEN,
    home: HomeEN,
    informationUpdate: InfoUpdateEN,
    login: LoginEN,
    loyalty: LoyaltyEN,
    onboardingErrors: OnboardingErrorsEN,
    onlineBooking: OnlineBookingEN,
    patientProfile: PatientProfileEN,
    permissions: PermissionsEN,
    petID: PetIDEN,
    petList: PetListEN,
    petPageDisabled: PetPageDisabledEN,
    practiceClientSearch: PracticeClientSearchEN,
    profileClientPracticeConfig: ProfileClientPracticeConfigEN,
    profileRoot: ProfileRootEN,
    pushNotifications: PushNotificationsEN,
    requests: RequestsEN,
    selectPractice: SelectPracticeEN,
    settings: SettingsEN,
    timeReference: TimeRefEN,
    todos: TodosEN,
    tokenPasswordReset: TokenResetPasswordEN,
    userInfo: UserInfoEN,
    validation: ValidationEN
  },
  es: {
    addToCalendar: AddToCalendarES,
    afterHours: AfterHoursES,
    appError: AppErrorES,
    appointmentRequest: AppointmentRequestES,
    appointments: AppointmentsES,
    biometricLogin: BiometricLoginES,
    changePassword: ChangePasswordES,
    changePasswordModal: ChangePasswordModalES,
    common: CommonES,
    contactAlly: ContactAllyES,
    contactUs: ContactUsES,
    discardChanges: DiscardChangesES,
    emailConfirmation: EmailConfirmationEs,
    eula: EulaES,
    genericError: GenericErrorES,
    helpCenter: HelpCenterES,
    home: HomeES,
    informationUpdate: InfoUpdateES,
    login: LoginES,
    loyalty: LoyaltyES,
    onboardingErrors: OnboardingErrorsES,
    onlineBooking: OnlineBookingES,
    patientProfile: PatientProfileES,
    permissions: PermissionsES,
    petID: PetIDES,
    petList: PetListES,
    petPageDisabled: PetPageDisabledES,
    practiceClientSearch: PracticeClientSearchES,
    profileClientPracticeConfig: ProfileClientPracticeConfigES,
    profileRoot: ProfileRootES,
    pushNotifications: PushNotificationsES,
    requests: RequestsES,
    selectPractice: SelectPracticeES,
    settings: SettingsES,
    timeReference: TimeRefES,
    todos: TodosES,
    tokenPasswordReset: TokenResetPasswordES,
    userInfo: UserInfoES,
    validation: ValidationES
  }
};
