import React, { SetStateAction, useCallback, useState } from 'react';
import { useBookingState } from '../BookingProvider';
import { ContentContainer, InputContainer, Row, StyledScrollView } from './shared';
import { Body, Headline, TextInput } from 'src/components';
import { useTranslation } from 'react-i18next';
import UploadFiles, { ImageUpload } from 'src/components/UploadFiles';
import { Margin } from 'src/constants';
import styled from 'styled-components/native';

const AdditionalInfo: React.FC = () => {
  const { t } = useTranslation('onlineBooking');
  const { values, setFieldValue, validateField, errors } = useBookingState();

  const [images, setImages] = useState<Record<string, ImageUpload>>(
    values.additionalInfo.images ?? {}
  );
  const onImageChange = useCallback(
    async (action: SetStateAction<Record<string, ImageUpload>>) => {
      setImages((prev) => {
        const value = typeof action === 'function' ? action(prev) : action;
        void setFieldValue('additionalInfo.images', value);
        setTimeout(async () => validateField('additionalInfo.images'), 100);
        return value;
      });
    },
    [setFieldValue, validateField]
  );

  return (
    <StyledScrollView>
      <ContentContainer>
        <Headline>{t('additionalInfo.title')}</Headline>
        <Body>{t('additionalInfo.description')}</Body>
      </ContentContainer>
      <Row>
        <InputContainer>
          <TextInput
            multiline
            style={{ minHeight: 100 }}
            label={t('form.comments')}
            value={values.additionalInfo?.comments}
            onChangeText={async (text) => setFieldValue('additionalInfo.comments', text, false)}
            onBlur={async () => validateField('additionalInfo.comments')}
            error={!!errors.additionalInfo?.comments}
            msg={t(errors.additionalInfo?.comments ?? '')}
          />
        </InputContainer>
      </Row>
      <StyledUpload images={images} onImageChange={onImageChange} buttonMode='outlined' />
    </StyledScrollView>
  );
};

export default AdditionalInfo;

const StyledUpload = styled(UploadFiles)`
  flex-direction: column-reverse;
  gap: ${Margin.Large}px;
`;
