import { ClientPatientSearchResponse } from 'src/api';
import { MappedClientPatientSearch, PatientInfo } from '../model';

const mapClientPatientSearch = (data: ClientPatientSearchResponse): MappedClientPatientSearch => {
  return {
    ...data,
    patients: data.patients?.map((patient) => {
      const patientInfo: PatientInfo = {
        breed: patient.species,
        isNewPatient: false,
        ...patient
      };
      return patientInfo;
    })
  };
};

export default mapClientPatientSearch;
