import { NavigationProp, useNavigation } from '@react-navigation/native';
import moment from 'moment-timezone';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, LayoutAnimation } from 'react-native';
import { Swipeable } from 'react-native-gesture-handler';
import { Checkbox } from 'react-native-paper';
import { QueryKeys } from 'src/api';
import { Body, Caption, Column, HoverCard, Row } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import { IconSize, Size } from 'src/constants';
import { useBoolean, usePatient, useMutateTodos } from 'src/hooks';
import { Todo } from 'src/interfaces';
import { Screens } from 'src/routes/stacks/screens';
import TodoStackParamList from 'src/routes/stacks/ToDoStackNavigator/ParamsList';
import { Weight } from 'src/theme';
import { fontStyles } from 'src/theme/globalStyles';
import { queryClient, toLocalDateTime } from 'src/utils';
import styled from 'styled-components/native';
import { ReasonMap } from './model';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import DeleteButton from './DeleteButton';
import { debounce } from 'lodash';

interface Props {
  todo: Todo;
  tourEnabled: boolean;
}
const TodoItem: React.FC<Props> = ({ todo, tourEnabled }) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('todos');
  const { navigate } = useNavigation<NavigationProp<TodoStackParamList>>();

  const { data: patient } = usePatient(todo.patientId);

  const { toggleCompleteTodo, deleteTodo } = useMutateTodos();
  const isPastDue = moment(todo.dueDate).isBefore() && !todo.isComplete;
  const { value: isDeleted, toTrue: doDelete } = useBoolean();
  const { value: disabled, toTrue: disablePress, toFalse: enablePress } = useBoolean();
  const { value: willToggle, toTrue: doToggle } = useBoolean();

  const debounceEnablePress = useMemo(
    () =>
      debounce(enablePress, 500, {
        leading: false,
        trailing: true
      }),
    [enablePress]
  );

  const renderRightActions = (
    progress: Animated.AnimatedInterpolation<number>,
    dragAnimatedValue: Animated.AnimatedInterpolation<number>
  ) => {
    return (
      <DeleteButton
        dragAnimatedValue={dragAnimatedValue}
        remove={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          doDelete();
          void deleteTodo(todo);
        }}
      />
    );
  };
  const scaleY = useRef(new Animated.Value(1)).current;
  const nameAndReason = `${String(patient?.name)} - ${String(
    todo.otherReason?.length ? todo.otherReason : t(ReasonMap[todo.reason].label)
  )}`;

  if ((!patient && !tourEnabled) || isDeleted) {
    return null;
  }
  return (
    <Animated.View style={[{ transform: [{ scaleY }] }]}>
      <Swipeable
        renderRightActions={renderRightActions}
        onSwipeableWillOpen={disablePress}
        onSwipeableWillClose={debounceEnablePress}
      >
        <StyledItem
          disabled={disabled}
          onPress={() => navigate(Screens.ADD_TO_DO, { todoId: todo.id })}
        >
          <Row>
            <AvatarContainer>
              <PatientAvatar size={IconSize.L} patient={patient} />
            </AvatarContainer>
            <Column>
              <Body numberOfLines={1}>{nameAndReason}</Body>
              {!!todo.dueDate && (
                <Caption
                  color={isPastDue ? colors.error : colors.text}
                  fontWeight={isPastDue ? Weight.MEDIUM : fontStyles.caption.fontWeight}
                >
                  {toLocalDateTime(moment(todo.dueDate).utc())}
                </Caption>
              )}
              {!!todo.notes && <Caption>{todo.notes}</Caption>}
            </Column>
            <Checkbox.Android
              disabled={willToggle || disabled}
              uncheckedColor={colors.primary}
              color={colors.primary}
              status={(willToggle ? !todo.isComplete : todo.isComplete) ? 'checked' : 'unchecked'}
              onPress={async () => {
                disablePress();
                doToggle();
                await toggleCompleteTodo(todo);
                void queryClient.invalidateQueries([QueryKeys.PAGED_TODOS]);
              }}
            />
          </Row>
        </StyledItem>
      </Swipeable>
    </Animated.View>
  );
};

export default TodoItem;

const AvatarContainer = styled.View`
  align-self: center;
  margin-right: ${Size.XS}px;
`;

const StyledItem = styled(HoverCard).attrs({ hideShadow: true })`
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${Size.X2_S}px;
`;
