import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PatientAvatar from 'src/components/PatientAvatar';
import TempImageAvatar from 'src/scenes/PatientProfile/components/TempImageAvatar';
import { IS_WEB, PATIENT_STATUSES, Size } from 'src/constants';
import { useViewMode } from 'src/hooks';
import { Patient } from 'src/interfaces';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';
import { FormState, ReducerState } from '../model';
import useUploadPatientPhoto from 'src/hooks/react-query/useUploadPatientPhoto';
import _ from 'lodash';
import { Camera, Trash2, Image as FeatherImage } from 'react-native-feather';
import { palette } from 'src/theme';
import AvatarMaskedView from './AvatarMaskedView';
import { Caption } from 'src/components';
import { View } from 'react-native';
import { GuideElement, STEPS } from 'src/scenes/PatientList/tour';
import { StyledFAB } from '../style';
import { FormStateHook } from '../hooks/useFormState';

interface Props {
  state: ReducerState;
  patient: Patient;
  setFieldValue: FormStateHook['setFieldValue'];
  newPatientImageUri: string | null;
  patientIsMutating: boolean;
}

const AVATAR_ASPECT_RATIO_MOBILE = 5 / 4;
const AVATAR_ASPECT_RATIO_WEB = 2;

const PatientProfileAvatar: React.FC<Props> = ({
  state,
  patient,
  setFieldValue,
  newPatientImageUri,
  patientIsMutating
}) => {
  const { isMobile, maxContentWidth } = useViewMode();
  const { colors } = useAppTheme();
  const { t } = useTranslation();

  const { preparePhotoUpload, isMutating } = useUploadPatientPhoto(patient);

  const onPressCameraOrUpload = useMemo(
    () =>
      _.debounce(async (isCamera: boolean) => {
        if (!patient) return;
        const uri = await preparePhotoUpload(isCamera);
        if (uri) void setFieldValue('newPatientImageUri', uri);
      }),
    [patient, preparePhotoUpload, setFieldValue]
  );

  const onPressDeletePhoto = useMemo(
    () =>
      _.debounce(() => {
        void setFieldValue('newPatientImageUri', '');
      }),
    [setFieldValue]
  );

  const patientStatus = useMemo(() => {
    if (patient?.deceasedDate) {
      return PATIENT_STATUSES.DECEASED;
    }
    return patient?.status as PATIENT_STATUSES;
  }, [patient]);

  const avatarAspectRatio = useMemo(() => {
    return isMobile ? AVATAR_ASPECT_RATIO_MOBILE : AVATAR_ASPECT_RATIO_WEB;
  }, [isMobile]);

  return (
    <Container>
      <AvatarMaskedView width={maxContentWidth} aspectRatio={avatarAspectRatio}>
        <GuideElement body={t('patientProfile:tour.photos')} id={STEPS.PHOTOS}>
          <View style={{ width: maxContentWidth }}>
            {newPatientImageUri === null && (
              <PatientAvatar
                patient={patient}
                size={maxContentWidth}
                aspectRatio={avatarAspectRatio}
              />
            )}
            {newPatientImageUri !== null && (
              <TempImageAvatar
                uri={newPatientImageUri}
                aspectRatio={avatarAspectRatio}
                width={maxContentWidth}
                species={patient.species}
                patientIsMutating={patientIsMutating}
              />
            )}
            {state.state === FormState.EDIT_INFO && !isMutating && (
              <EditFabContainer>
                {!IS_WEB && (
                  <StyledFAB
                    size='small'
                    icon={() => <Camera color={colors.onPrimary} />}
                    onPress={() => onPressCameraOrUpload(true)}
                  />
                )}
                <StyledFAB
                  size='small'
                  icon={() => <FeatherImage color={colors.onPrimary} />}
                  onPress={() => onPressCameraOrUpload(false)}
                />
                <StyledFAB
                  size='small'
                  icon={() => <Trash2 color={colors.onPrimary} />}
                  onPress={() => onPressDeletePhoto()}
                />
              </EditFabContainer>
            )}
            {patientStatus !== PATIENT_STATUSES.ACTIVE && (
              <StatusContainer>
                <Caption color={palette.AB_BRIGHT_WHITE}>{t(`petList:${patientStatus}`)}</Caption>
              </StatusContainer>
            )}
          </View>
        </GuideElement>
      </AvatarMaskedView>
    </Container>
  );
};

export default PatientProfileAvatar;

const Container = styled.View`
  align-self: center;
  margin-top: ${({ theme }) => (!theme.viewMode.isMobile ? Size.S : 0)}px;
  background-color: ${({ theme }) => theme.colors.background};
`;

const StatusContainer = styled.View`
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: ${Size.X3_S}px;
  background-color: ${({ theme }) => theme.colors.backdrop};
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
`;

const EditFabContainer = styled.View`
  position: absolute;
  gap: ${Size.S}px;
  right: ${({ theme }) => (theme.viewMode.isMobile ? Size.XS : Size.S)}px;
  bottom: ${!IS_WEB ? Size.L : Size.S}px;
`;
