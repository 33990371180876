import { ActivityIndicator } from 'react-native-paper';
import { Body, HoverButton, HoverCard, Subheading } from 'src/components';
import { IconSize, Margin, Size } from 'src/constants';
import { palette, Weight } from 'src/theme';
import { transparent } from 'src/utils';
import styled from 'styled-components/native';

const MINIMUM_HEIGHT = 260;

export const CallToAction = styled(HoverButton).attrs({ mode: 'contained', uppercase: false })`
  margin: ${Margin.Large}px;
`;
export const FullActivityIndicator = styled(ActivityIndicator)`
  height: ${MINIMUM_HEIGHT}px;
`;

export const ListContainer = styled.View`
  margin-horizontal: ${Margin.Large}px;
`;

export const StyledHeading = styled(Subheading)`
  color: ${({ theme }) => theme.colors.onSurface};
  flex-grow: 1;
`;

export const HeadingIconContainer = styled.View`
  margin-right: ${Margin.Medium}px;
`;

export const StyledHeader = styled(HoverCard).attrs({ hideShadow: true })`
  flex-direction: row;
  align-items: center;
  padding: ${Size.S}px;
`;

export const Badge = styled.View`
  background-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_10 : palette.BLACK_OPACITY_10};
  border-radius: ${Size.S}px;
  padding-horizontal: ${Margin.Large}px;
  padding-vertical: ${Margin.Small}px;
`;

export const CaughtUpText = styled(Subheading)`
  background-color: ${({ theme }) => transparent(theme.colors.surface, 0.75)}
  color: ${({ theme }) => theme.colors.onSurface};
  margin: ${Size.X2_L}px ${Size.M}px;
  padding: ${Margin.Medium}px;
  border-radius: ${({ theme }) => theme.roundness}px;
  align-self: center;
  text-align: center;
`;

export const CardContent = styled.View`
  gap: ${Margin.ExtraLarge}px;
  padding-top: ${Margin.Large}px;
  padding-horizontal: ${Margin.ExtraLarge}px;
`;

export const Link = styled(Body)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration-line: underline;
  font-weight: ${Weight.MEDIUM};
  letter-spacing: ${Size.X5_S}px;
`;

export const FilledIconContainer = styled.View`
  margin-vertical: ${Margin.Large}px;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.15)};
  border-radius: ${IconSize.L}px;
  padding: ${Size.X2_S}px;
`;
