import { Size, Margin } from 'src/constants';
import styled from 'styled-components/native';
import { fontStyles } from 'src/theme/globalStyles';
import { RadioButton } from 'react-native-paper';
import { StyledMasterView, Body } from 'src/components';
import { palette } from 'src/theme';

export const Content = styled.View`
  align-items: flex-start;
  padding: ${Size.M}px;
`;

export const StickyButtonMasterView = styled(StyledMasterView)`
  flex-grow: 0;
  justify-content: flex-end;
  padding: ${Margin.Small}px;
`;

export const StyledRadioButton = styled(RadioButton.Item).attrs({
  mode: 'android',
  labelStyle: fontStyles.caption
})``;

export const StyledBody = styled(Body)`
  opacity: 1;
  color: ${({ theme }) => (theme.dark ? palette.WHITE_OPACITY_54 : palette.BLACK_OPACITY_54)};
`;

export const HeaderBody = styled(Body)`
  padding: ${Size.M}px ${Size.X2_S}px;
`;

export const RadioButtonGroup = styled(RadioButton.Group)``;
