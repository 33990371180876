import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { getLoyaltyTransactions } from 'src/api/loyaltyTransactions';
import LoyaltyTransaction from 'src/interfaces/api/LoyaltyTransaction';
import { t } from 'i18next';
import { ActivityLineItem, RewardSectionItem } from './types';
import useAbility from 'src/hooks/react-query/useAbility';
import _ from 'lodash';
import AwardQueue from './AwardQueue';
import { toLocalMonthYear } from 'src/utils';
import { LOYALTY_TRANSACTION_TYPES } from 'src/constants/loyaltyTransactionTypes';

const _useLoyaltyTransactions = () => {
  const { userCanViewLoyalty } = useAbility();
  const {
    data: transactions = [],
    isLoading,
    isFetched,
    isRefetching,
    refetch
  } = useQuery({
    queryKey: [QueryKeys.LOYALTY_TRANSACTIONS],
    queryFn: getLoyaltyTransactions,
    enabled: userCanViewLoyalty
  });

  const { aggregatedExpirations, ...userCoinData } = useMemo(() => {
    const { activityEntries, coinBalance, expiringAwards } = createActivityItems(transactions);
    const sections = createActivitySections(activityEntries);
    const aggregatedExpirations = groupExpiringRewards(transactions);
    return { sections, coinBalance, expiringAwards, aggregatedExpirations };
  }, [transactions]);

  return {
    transactions,
    isLoading,
    isFetched,
    isRefetching,
    refetch,
    userCoinData,
    aggregatedExpirations
  };
};

const groupExpiringRewards = (transactions: LoyaltyTransaction[]): RewardSectionItem[] => {
  return _(transactions)
    .filter((t) => moment(t.expiresAt).isAfter(new Date(), 'day'))
    .sortBy('expiresAt')
    .groupBy((t) => moment(t.expiresAt).startOf('day'))
    .map((value) => ({
      type: 'expiry' as RewardSectionItem['type'],
      expireAmount: value.reduce((acc, txn) => acc + txn.amount, 0),
      expireDate: moment(value[0].expiresAt).startOf('day').toDate(),
      reward: undefined
    }))
    .filter((item) => item.expireAmount > 0)
    .value();
};

const createActivitySections = (activityEntries: ActivityLineItem[]) => {
  const sections: Array<{
    title: string | undefined;
    data: ActivityLineItem[];
  }> = _(activityEntries)
    .groupBy(({ isPending, date, fulfilledDate }) => {
      if (!isPending && date) {
        return toLocalMonthYear(moment(date));
      } else if (fulfilledDate) {
        return toLocalMonthYear(moment(fulfilledDate));
      }
      return undefined;
    })
    .map((value, key) => ({
      title: key === 'undefined' ? undefined : key,
      data: value.sort((a, b) => moment(b.date).diff(moment(a.date)))
    }))
    .sortBy((section) => (section.title ? moment(section.data[0].date) : undefined))
    .reverse()
    .value();

  return sections;
};

export const createActivityItems = (transactions: LoyaltyTransaction[]) => {
  const rewards = new AwardQueue<LoyaltyTransaction>();
  const activityEntries: ActivityLineItem[] = [];
  const pending: LoyaltyTransaction[] = [];

  transactions.forEach((txn) => {
    if (txn.type !== LOYALTY_TRANSACTION_TYPES.REWARD) {
      if (txn.amount > 0) {
        rewards.addAward(txn);
      } else {
        rewards.adjustBalance(txn.amount);
      }
      activityEntries.push(createLineItem(txn));
    } else if (!txn.fulfilledAt) {
      if (!txn.expiresAt || moment().isBefore(txn.expiresAt, 'day')) pending.push(txn);
    } else {
      const expiring = rewards.removeExpiring(txn.fulfilledAt);
      activityEntries.push(...expiring.map((txn) => createLineItem(txn, true)));
      rewards.adjustBalance(txn.amount);
      activityEntries.push(createLineItem(txn));
    }
  });

  const expiringAwards = rewards.removeExpiring(new Date());
  activityEntries.push(...expiringAwards.map((txn) => createLineItem(txn, true)));
  activityEntries.push(...pending.map((txn) => createLineItem(txn)));
  const pendingBalance = pending.reduce((acc, txn) => acc + txn.amount, 0);
  return {
    activityEntries,
    coinBalance: rewards.getBalance() + pendingBalance,
    expiringAwards
  };
};

export const createLineItem = (
  { id, description, amount, createdAt, expiresAt, fulfilledAt, type }: LoyaltyTransaction,
  expired = false
): ActivityLineItem => {
  const activityLineItem = {
    transactionId: id,
    description: expired ? t('loyalty:expired') : description,
    date: expired ? expiresAt : createdAt,
    fulfilledDate: fulfilledAt,
    isPending: amount < 0 && !fulfilledAt && type === LOYALTY_TRANSACTION_TYPES.REWARD,
    amount: expired ? -amount : amount
  };

  return activityLineItem;
};

export default _useLoyaltyTransactions;
