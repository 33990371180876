import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Subheading } from 'src/components';
import {
  CoinBalanceAmountText,
  CoinBalanceContainer,
  CoinIcon,
  ExtendedHeader,
  Header,
  TabViewContainer,
  TabViewInnerContainer,
  TopTab
} from './style';
import TabBarIndicator from './TabIndicator';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import GuideWrapper from './GuideWrapper';
import { GuideElement, STEPS } from '../../tour';
import { useLoyaltyTransactions } from 'src/hooks';
import { View } from 'react-native';

const LoyaltyTabBar: React.FC<MaterialTopTabBarProps> = ({ state, navigation }) => {
  const { t } = useTranslation('loyalty');
  const { userCoinData, refetch } = useLoyaltyTransactions();
  const indexTabWidths = useRef(
    Array(state.routes.length).map(() => ({ x: 0, y: 0, width: 0, height: 0 }))
  );

  const firstTimeRef = React.useRef(true);

  useEffect(() => {
    indexTabWidths.current = Array(state.routes.length).map(() => ({
      x: 0,
      y: 0,
      width: 0,
      height: 0
    }));
  }, [state.routes.length]);

  useFocusEffect(
    useCallback(() => {
      if (firstTimeRef.current) {
        firstTimeRef.current = false;
        return;
      }

      void refetch();
    }, [refetch])
  );

  const { top } = useSafeAreaInsets();

  const focusNext = useCallback(() => {
    if (state.index === 0) {
      navigation.navigate(state.routeNames[1]);
    } else if (state.index === 1) {
      navigation.navigate(state.routeNames[0]);
    }
  }, [state.index, navigation, state.routeNames]);

  return (
    <>
      <ExtendedHeader topMargin={top}>
        <Header>{t('rewardsScreenTitle')}</Header>
      </ExtendedHeader>
      <TabViewContainer>
        <TabViewInnerContainer>
          {state.routes.map((route, index) => {
            const isFocused = state.index === index;

            const onPress = (): void => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true
              });

              if (!isFocused && !event.defaultPrevented) {
                navigation.navigate(route.name);
              }
            };

            return (
              <View
                key={route.name}
                onLayout={(event) => {
                  indexTabWidths.current[index] = event.nativeEvent.layout;
                }}
              >
                <GuideWrapper initialIndex={state.index} thisIndex={index} focusNext={focusNext}>
                  <TopTab
                    accessibilityRole='button'
                    accessibilityState={{ selected: isFocused }}
                    onPress={onPress}
                  >
                    <Subheading numberOfLines={1}>{t(`title.${route.name}`)}</Subheading>
                  </TopTab>
                </GuideWrapper>
              </View>
            );
          })}
          <TabBarIndicator
            leftOffset={indexTabWidths.current[state.index]?.x ?? 0}
            width={indexTabWidths.current[state.index]?.width ?? 0}
          />
        </TabViewInnerContainer>
        <GuideElement id={STEPS.BALANCE} body={t('tour.balance')} semiTransparentBg>
          <CoinBalanceContainer>
            <CoinBalanceAmountText value={userCoinData.coinBalance} />
            <CoinIcon />
          </CoinBalanceContainer>
        </GuideElement>
      </TabViewContainer>
    </>
  );
};

export default LoyaltyTabBar;
