import React, { useEffect, useRef } from 'react';
import Banner from './components/Banner';
import AnnouncementsCarousel from './components/AnnouncementsCarousel';
import { FocusAwareStatusBar, MasterView } from 'src/components';
import HomeHeader from './components/HomeHeader';
import QuickAccess from './components/QuickAccess';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isDarkColor, isDefined, queryClient } from 'src/utils';
import RemindersCard from './components/RemindersCard';
import { IS_WEB, Margin } from 'src/constants';
import PrescriptionsCard from './components/PrescriptionsCard';
import ToDosCard from './components/ToDosCard';
import AppointmentsCard from './components/AppointmentsCard';
import { RefreshControl, ScrollView } from 'react-native';
import { QueryKey, useIsFetching, useQueries } from 'react-query';
import { QueryKeys } from 'src/api';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, SliderElement, SLIDES, STEPS, TourGuide } from './tour';
import { useTranslation } from 'react-i18next';
import { TourGuideHandle } from 'src/module/TourGuide/model';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import HelpCenterCard from './components/HelpCenterCard';
import WhatsNew from './components/WhatsNew';
import { useBiometricsPrompt } from 'src/providers/BiometricsPromptProvider';

const queries: QueryKey[] = [
  QueryKeys.CHECK_EMAIL,
  QueryKeys.ANNOUNCEMENTS,
  QueryKeys.APPOINTMENTS,
  QueryKeys.PRESCRIPTIONS,
  QueryKeys.REMINDERS
];

const refresh = () => {
  queries.forEach(async (query) => queryClient.refetchQueries([query]));
};

const Home: React.FC<NativeStackScreenProps<HomeStackParamsList, Screens.HOME>> = () => {
  const { colors } = useAppTheme();

  const { t } = useTranslation('home');
  const results = useQueries(queries.map((key) => ({ queryKey: [key] })));
  const isFetched = results.every((query) => query.isFetched);
  const isRefreshing = results.some((query) => query.isRefetching && !query.isStale);
  const someQueryLoading = useIsFetching();

  const scrollRef = useRef<ScrollView>(null);
  const guideRef = useRef<TourGuideHandle>(null);

  useEffect(() => {
    guideRef.current?.updateScrollPositions();
  }, [someQueryLoading]);

  const { needsPrompt } = useBiometricsPrompt();

  return (
    <TourGuide.Provider
      ref={guideRef}
      disabled={!IS_WEB && (!isDefined(needsPrompt) || needsPrompt)}
    >
      <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
      <TourGuide.Consumer>
        {({ exitTour, enabled }) => (
          <>
            <FocusAwareStatusBar
              barStyle={isDarkColor(colors.primary) ? 'light-content' : 'dark-content'}
            />
            <HomeHeader isFetched={isFetched} />
            <ScrollView
              ref={scrollRef}
              contentInset={{ bottom: Margin.ExtraLarge }}
              refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={refresh} />}
              onMomentumScrollEnd={guideRef.current?.updateScrollPositions}
              scrollsToTop={!enabled}
            >
              <ScrollRefProvider scrollRef={scrollRef}>
                <Banner />
                <MasterView>
                  <AnnouncementsCarousel />
                  <GuideElement
                    id={STEPS.QUICK_ACCESS}
                    body={t('tour.quickAccess')}
                    semiTransparentBg
                  >
                    <QuickAccess />
                  </GuideElement>
                  <GuideElement id={STEPS.APPOINTMENTS} body={t('tour.appointments')}>
                    <AppointmentsCard />
                  </GuideElement>
                  <GuideElement id={STEPS.REMINDERS} body={t('tour.reminders')}>
                    <RemindersCard />
                  </GuideElement>
                  <GuideElement id={STEPS.PRESCRIPTIONS} body={t('tour.prescriptions')}>
                    <PrescriptionsCard />
                  </GuideElement>
                  <GuideElement id={STEPS.TO_DOS} body={t('tour.toDos')}>
                    <ToDosCard />
                  </GuideElement>
                  <GuideElement id={STEPS.HELP} body={t('tour.help')} onContinue={exitTour}>
                    <HelpCenterCard />
                  </GuideElement>
                  <WhatsNew />
                </MasterView>
              </ScrollRefProvider>
            </ScrollView>
          </>
        )}
      </TourGuide.Consumer>
    </TourGuide.Provider>
  );
};

export default Home;
