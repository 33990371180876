import config from 'src/environment';
import axios, { AxiosError } from 'axios';
import { t } from 'i18next';
import { AllyError, ALLY_ERROR_CODE } from './model';
import { AuthStatus } from 'src/providers/AuthProvider/model';
import { Platform } from 'react-native';
import packageConfig from '../../../package.json';
const GENERIC_ERROR_FALLBACK = 'Oh no!';

export const apiClient = axios.create({
  baseURL: config.API_URL
});

apiClient.defaults.headers.common = {};
apiClient.defaults.headers.common.Accept = 'application/json';
apiClient.defaults.headers.common['Content-Type'] = 'application/json';
apiClient.defaults.headers.Authorization = null;
apiClient.defaults.headers['X-App-Version'] = packageConfig.version;
apiClient.defaults.headers['X-App-Platform'] = Platform.OS;

export const responseFailureInterceptor = (
  error: AxiosError<AllyError>
): AuthStatus | undefined => {
  const allyErrorCode = error?.response?.data?.allyErrorCode;

  if (allyErrorCode === ALLY_ERROR_CODE.NEED_TO_ACCEPT_TERMS) {
    error.message = t('1001', { ns: 'onboardingErrors' });
    error.name = t('userActionRequired', { ns: 'onboardingErrors' });
    return AuthStatus.NEEDS_TERMS;
  } else if (allyErrorCode === ALLY_ERROR_CODE.PASSWORD_RESET_REQUIRED) {
    error.message = t('1002', { ns: 'onboardingErrors' });
    error.name = t('userActionRequired', { ns: 'onboardingErrors' });
    return AuthStatus.NEEDS_PASSWORD_RESET;
  } else if (allyErrorCode === ALLY_ERROR_CODE.CLIENT_NEEDS_UPGRADE) {
    error.message = t('1003', { ns: 'onboardingErrors' });
    error.name = t('userActionRequired', { ns: 'onboardingErrors' });
    return AuthStatus.NEEDS_UPGRADE;
  } else if (error?.response?.status === 401) {
    error.message = t('401', { ns: 'onboardingErrors' });
    error.name = t('userActionRequired', { ns: 'onboardingErrors' });
    return AuthStatus.LOGGED_OUT;
  } else if (error?.response?.status === 403) {
    error.message = t('403', { ns: 'onboardingErrors' });
    error.name = t('accessDenied', { ns: 'onboardingErrors' });
    return AuthStatus.FORBIDDEN;
  } else {
    error.message =
      error?.response?.data?.error ??
      t('errorMessage', { ns: 'genericError' }) ??
      GENERIC_ERROR_FALLBACK;
    error.name = t('errorName', { ns: 'genericError' });
    return undefined;
  }
};

export * from './model';
