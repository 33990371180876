import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Avatar from './Avatar';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useUser } from 'src/providers/ClientProvider';
import { encodeKeyFromUser } from 'src/utils/extractImageName';
import { useAuth } from 'src/providers/AuthProvider';
import { useIsFetching, useIsMutating } from 'react-query';
import { MutationKeys, QueryKeys } from 'src/api';
interface Props {
  size: number;
  style?: StyleProp<ViewStyle>;
  aspectRatio?: number;
}
const ClientAvatar: React.FC<Props> = (props) => {
  const { user } = useUser();
  const { token } = useAuth();

  const isFetching = !!useIsFetching([QueryKeys.CLIENT, token]);
  const isDeleting = !!useIsMutating([MutationKeys.PROFILE_PHOTO_DELETE]);
  const isUploading = !!useIsMutating([MutationKeys.PROFILE_PHOTO_UPLOAD]);
  const isMutating = isDeleting || isUploading;
  return (
    <Avatar
      uri={user.hasPhoto ? user.photo.urls.large : undefined}
      id={encodeKeyFromUser(user)}
      isFetching={isFetching}
      isMutating={isMutating}
      {...props}
      EmptyComponent={(props) => <Icon name='user' {...props} />}
    />
  );
};

export default ClientAvatar;
