import { Headline, HoverCard } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import { Margin, Size } from 'src/constants';
import styled from 'styled-components/native';

export const StyledCard = styled(HoverCard)`
  color: ${({ theme: { colors } }) => colors.onSurface}
  padding: ${Size.XS}px;
  justify-content: center;
  align-items: stretch;
`;

export const StyledImage = styled.Image<{ size: Size }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ theme }) => theme.colors.disabled};
  margin-right: ${Margin.Medium}px;
  border-radius: ${Size.X2_L}px;
`;

export const StyledHeadline = styled(Headline).attrs({
  shadow: true
})<{ inset?: boolean }>`
  margin-top: ${Margin.Large}px;
  margin-bottom: ${Margin.Medium}px;
`;

export const StyledSectionHeader = styled(Headline).attrs({
  shadow: false
})`
  padding-top: ${Margin.Large}px;
  padding-bottom: ${Margin.Medium}px;
  padding-horizontal: ${({ theme }) => Size.X3_S + theme.viewMode.horizontalInset}px;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const PaperCard = styled(HoverCard)`
  margin-bottom: ${Margin.Medium}px;
  color: ${({ theme: { colors } }) => colors.onSurface};
  margin-horizontal: ${({ theme }) => Size.X3_S + theme.viewMode.horizontalInset}px;
`;

export const Container = styled.View`
  flex-direction: row;
  padding-vertical: ${Margin.Medium}px;
  padding-right: ${Margin.Small}px;
`;

export const AvatarContainer = styled.View`
  margin-horizontal: ${Margin.Medium}px;
  justify-content: center;
`;

export const Avatar = styled(PatientAvatar)`
  margin-horizontal: ${Margin.Medium}px;
  align-self: center;
`;

export const DataContainer = styled.View`
  justify-content: center;
  margin-left: ${Margin.Small}px;
  flex: 1;
`;

export const RightIconContainer = styled.View`
  padding-horizontal: ${Margin.Medium}px;
  justify-content: center;
`;

export const CaptionContainer = styled.View`
  margin-vertical: ${Margin.ExtraSmall}px;
`;
