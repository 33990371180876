import { AppointmentRequest, AppointmentRequestContent } from './AppointmentRequest';
import Client from './Client';
import { InfoUpdateRequest, InfoUpdateRequestContent } from './InfoUpdateRequest';
import Patient from './Patient';
import RefillRequest, { RefillRequestContent } from './RefillRequest';

export interface NewRequest {
  appointmentId?: string;
  archivedAt?: Date;
  clientId?: string;
  content?: AppointmentRequestContent | RefillRequestContent | InfoUpdateRequestContent;
  createdAt?: Date;
  newClient?: boolean;
  notes?: string;
  patient?: PartialPatient;
  patientId?: string;
  practiceId?: number;
  requestImageIds?: number[];
  sourceId?: number;
  type: RequestType;
  updatedAt?: Date;
  updatedBy?: string;
  visitId?: number;
}

export enum RequestType {
  Appointment = 'appointment',
  Refill = 'refill',
  InfoUpdate = 'information'
}

export enum RequestStatus {
  New = 'new',
  InProgress = 'in_progress',
  Archived = 'archived'
}

export type Request = MixRequest & {
  id: number;
  images: ImageAttachment[];
  status: RequestStatus;
  patientId?: Patient['patientId'];
  clientId?: Client['clientId'];
};

export type MixRequest = AppointmentRequest | RefillRequest | InfoUpdateRequest;

export interface ImageAttachment {
  id: number;
  urls: {
    fullScreen: string;
    original: string;
    thumbnail: string;
  };
}

type PartialPatient = Pick<
  Patient,
  'deceasedDate' | 'displayId' | 'name' | 'patientId' | 'practiceId' | 'sourceId' | 'status'
>;
