import React, { Suspense, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useAppointments from '../hooks/useAppointments';
import { useSelectedPatient } from 'src/providers/SelectedPatientProvider';
import { RefreshControl, SectionList } from 'react-native';
import AppointmentListItem from './AppointmentListItem';
import { StyledSectionHeader } from './shared';
import { ActivityIndicator } from 'react-native-paper';
import { QueryKeys } from 'src/api';
import { queryClient } from 'src/utils';
import HeroSection from './HeroSection';
import { Size } from 'src/constants';
import { Body, TwoColorView } from 'src/components';
import styled from 'styled-components/native';
import AppointmentListItemLoading from './AppointmentListItemLoading';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS, useTourGuide } from '../../tour';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import { Appointment } from 'src/interfaces';

const UPCOMING_RIGHT_ICONS = 2;
const PAST_RIGHT_ICONS = 1;

const refresh = () => {
  const clearQueries = [QueryKeys.APPOINTMENTS, QueryKeys.EMPLOYEES, QueryKeys.PATIENT];
  clearQueries.forEach((query) => {
    void queryClient.invalidateQueries({ queryKey: [query] });
  });
};

interface PlaceHolderAppointment {
  isPlaceholder: true;
}

interface Section {
  title: string;
  stepId: STEPS;
  data: Array<Appointment | PlaceHolderAppointment>;
  index: number;
}

const isPlaceholderAppointment = (
  item: Appointment | PlaceHolderAppointment
): item is PlaceHolderAppointment => {
  return (item as PlaceHolderAppointment).isPlaceholder;
};

const PLACEHOLDER: PlaceHolderAppointment[] = [{ isPlaceholder: true }];

const AppointmentsList: React.FC = () => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('appointments');
  const { exitTour, updateScrollPositions, enabled: touring } = useTourGuide();

  const { selectedPatient } = useSelectedPatient();
  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    hasNextPage,
    isFetched
  } = useAppointments({
    selectedPatient: selectedPatient?.patientId,
    suspense: false,
    enabled: !touring
  });

  const appointments: Section[] = useMemo(
    () => [
      {
        title: 'upcomingAppointments',
        stepId: STEPS.UPCOMING,
        data: data.future.length ? data.future : touring ? PLACEHOLDER : [],
        index: 0
      },
      {
        title: 'pastAppointments',
        stepId: STEPS.PAST,
        data: data.past.length ? data.past : touring ? PLACEHOLDER : [],
        index: 1
      }
    ],
    [data, touring]
  );
  const ref = useRef<SectionList<Appointment | PlaceHolderAppointment, Section>>(null);

  return (
    <ScrollRefProvider scrollRef={ref}>
      <TwoColorView topColor={colors.primary} bottomColor={colors.background}>
        <SectionList
          ref={ref}
          onMomentumScrollEnd={updateScrollPositions}
          contentContainerStyle={{
            marginBottom: 50,
            backgroundColor: colors.background,
            flexGrow: 1
          }}
          ListHeaderComponent={HeroSection}
          sections={appointments}
          onEndReached={() => {
            if (hasNextPage && !isFetchingNextPage) {
              void fetchNextPage();
            }
          }}
          refreshing={isLoading && !touring}
          refreshControl={
            <RefreshControl
              refreshing={isRefetching}
              onRefresh={refresh}
              tintColor={colors.onPrimary}
            />
          }
          renderSectionHeader={({ section }) =>
            section.data?.length ? (
              <GuideElement
                id={section.stepId}
                body={t(`list-tour.${section.title}`)}
                semiTransparentBg
                onContinue={section.stepId === STEPS.PAST ? exitTour : undefined}
                itemIndex={1}
                sectionIndex={section.index}
                viewOffset={Size.S}
                autoStart={isFetched}
              >
                <StyledSectionHeader>{t(section.title)}</StyledSectionHeader>
              </GuideElement>
            ) : null
          }
          renderItem={({ item, section }) => {
            if (isPlaceholderAppointment(item))
              return (
                <AppointmentListItemLoading
                  numRightIcons={
                    section.title === 'upcomingAppointments'
                      ? UPCOMING_RIGHT_ICONS
                      : PAST_RIGHT_ICONS
                  }
                />
              );
            return (
              <Suspense
                fallback={
                  <AppointmentListItemLoading
                    numRightIcons={
                      section.title === 'upcomingAppointments'
                        ? UPCOMING_RIGHT_ICONS
                        : PAST_RIGHT_ICONS
                    }
                  />
                }
              >
                <AppointmentListItem appointment={item} key={item.appointmentId} />
              </Suspense>
            );
          }}
          ListFooterComponent={
            <>
              {isFetchingNextPage && <LoadingNextIndicator />}
              {!hasNextPage && !isLoading && <NoMoreText>{t('common:noMore')}</NoMoreText>}
            </>
          }
        />
      </TwoColorView>
    </ScrollRefProvider>
  );
};

export default AppointmentsList;

const NoMoreText = styled(Body)`
  margin: ${Size.M}px;
  text-align: center;
`;

const LoadingNextIndicator = styled(ActivityIndicator)`
  margin: ${Size.M}px;
`;
