import moment from 'moment';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { onDemandSync, onDemandStatus, OnDemandStatus } from 'src/api/onDemandSync';
import { ApiPracticeInfo } from 'src/interfaces';
import { queryClient } from 'src/utils';

const VALID_DURATION = moment.duration(5, 'm').asMilliseconds();
const POLL_INTERVAL = moment.duration(5, 's').asMilliseconds();

const useOnDemandPoll = (practiceInfo: ApiPracticeInfo) => {
  const { data } = useQuery({
    queryKey: [QueryKeys.ON_DEMAND_SYNC, practiceInfo.practiceId],
    queryFn: async () => onDemandSync({ practiceId: practiceInfo.practiceId }),
    enabled: practiceInfo.onlineBooking,
    staleTime: VALID_DURATION,
    cacheTime: VALID_DURATION
  });

  const { data: { status } = {} } = useQuery({
    queryKey: [QueryKeys.ON_DEMAND_STATUS, data?.commandIds[0]],
    queryFn: async () => onDemandStatus({ commandId: data?.commandIds[0] }),
    enabled: !!data?.commandIds[0],
    refetchInterval: (data) => (data?.status === OnDemandStatus.RUNNING ? POLL_INTERVAL : false),
    staleTime: Infinity,
    cacheTime: Infinity
  });

  useEffect(() => {
    if (status === OnDemandStatus.SUCCESS) {
      void queryClient.resetQueries([QueryKeys.SCHEDULE]);
    }
  }, [status]);

  return {
    lastSyncId: data?.commandIds[0],
    onDemandReady: !practiceInfo.onlineBooking || status === OnDemandStatus.SUCCESS
  };
};

export default useOnDemandPoll;
