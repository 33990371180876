import moment from 'moment-timezone';
import React, { useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ActivityIndicator, RefreshControl, SectionList } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { getRewards } from 'src/api/rewards';
import { Body } from 'src/components';
import { Margin } from 'src/constants';
import { useLoyaltyTransactions, usePractice, useViewMode } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS, useTourGuide } from 'src/routes/stacks/LoyaltyTabNavigator/tour';
import { Weight } from 'src/theme';
import { isWithin30days, queryClient, toLocalShortDate } from 'src/utils';
import { ExpiringCoinsHeader, StyledSectionHeader } from './style';
import ExpiringCareCoinItem from './components/ExpiringCareCoinItem';
import _ from 'lodash';
import RewardRow from './components/RewardRow';
import { RewardSectionItem } from 'src/providers/LoyaltyTransactionProvider/types';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';

interface Section {
  index: number;
  title: string;
  data: RewardSectionItem[];
}

const LoyaltyRewards: React.FC = () => {
  const { t } = useTranslation('loyalty');
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();
  const { data: practice } = usePractice();
  const { enabled: isTouring, updateScrollPositions, pendingSteps, exitTour } = useTourGuide();

  const { data: rewards, isFetched } = useQuery([QueryKeys.REWARDS], {
    queryFn: getRewards
  });

  const { userCoinData, isRefetching, aggregatedExpirations } = useLoyaltyTransactions();

  const sectionListData = useMemo(() => {
    const sections = [
      {
        index: 0,
        title: 'rewardsYouCanGet',
        data: rewards?.length
          ? _.sortBy(rewards, 'price').map(
              (reward): RewardSectionItem => ({
                type: 'reward',
                reward,
                expireAmount: 0,
                expireDate: undefined
              })
            )
          : []
      },
      {
        index: 1,
        title: 'expiring',
        data: aggregatedExpirations
      }
    ];

    const showListHeaderComponent =
      !!aggregatedExpirations.length && isWithin30days(moment(aggregatedExpirations[0].expireDate));

    return { sections, showListHeaderComponent };
  }, [aggregatedExpirations, rewards]);

  const ref = useRef<SectionList<RewardSectionItem, Section>>(null);

  if (!isFetched) {
    return <ActivityIndicator color={colors.primary} size='large' style={{ flex: 1 }} />;
  }

  return (
    <ScrollRefProvider scrollRef={ref}>
      <SectionList
        ref={ref}
        contentContainerStyle={{
          marginHorizontal: horizontalInset,
          padding: Margin.Large
        }}
        onMomentumScrollEnd={updateScrollPositions}
        ListHeaderComponent={
          sectionListData.showListHeaderComponent ? (
            <ExpiringCoinsHeader>
              <Trans
                i18nKey={t('expiringCoins')}
                values={{
                  amount:
                    aggregatedExpirations[0].expireAmount < userCoinData.coinBalance
                      ? aggregatedExpirations[0].expireAmount
                      : userCoinData.coinBalance,
                  expireDate: toLocalShortDate(
                    moment(aggregatedExpirations[0].expireDate),
                    practice?.timeZone
                  )
                }}
                components={{ strong: <Body fontWeight={Weight.BOLD} /> }}
              />
            </ExpiringCoinsHeader>
          ) : (
            <></>
          )
        }
        refreshControl={
          <RefreshControl
            refreshing={isRefetching}
            tintColor={colors.primary}
            onRefresh={async () =>
              await Promise.all([
                queryClient.invalidateQueries([QueryKeys.LOYALTY_TRANSACTIONS]),
                queryClient.invalidateQueries([QueryKeys.REWARDS])
              ])
            }
          />
        }
        sections={sectionListData.sections}
        renderSectionHeader={({ section }) =>
          section.data.length || isTouring ? (
            <GuideElement
              id={!section.index ? STEPS.REWARDS : STEPS.EXPIRING}
              itemIndex={0}
              sectionIndex={section.index}
              body={!section.index ? t('tour.rewards') : t('tour.expiring')}
              semiTransparentBg
              onContinue={() => {
                if (!pendingSteps) exitTour();
              }}
            >
              <StyledSectionHeader>{t(section.title)}</StyledSectionHeader>
            </GuideElement>
          ) : (
            <></>
          )
        }
        renderItem={({ section, item, index }) =>
          item.reward ? (
            <RewardRow
              reward={item.reward}
              coinBalance={userCoinData.coinBalance}
              isStart={index === 0}
              isEnd={index === section.data.length - 1}
            />
          ) : (
            <ExpiringCareCoinItem expringAmount={item.expireAmount} expireDate={item.expireDate} />
          )
        }
        stickySectionHeadersEnabled={false}
      />
    </ScrollRefProvider>
  );
};

export default LoyaltyRewards;
