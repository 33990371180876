import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Animated,
  Easing,
  View,
  useWindowDimensions,
  ScrollView,
  LayoutRectangle,
  Keyboard,
  ColorValue,
  TouchableWithoutFeedback
} from 'react-native';
import { Portal } from 'react-native-paper';
import { Body, HoverButton, HoverCard, Subheading } from 'src/components';
import { IS_WEB, MOBILE_WIDTH_SMALL, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import {
  calculatePosition,
  Dimensions,
  focusView,
  measureRefWithoutScrollView,
  normalizeX,
  SCROLL_FOCUS_DELAY
} from './helper';
import { GuideElementHandle, _GuideElementProp } from '../model';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { useIsFocused } from '@react-navigation/native';
import { useScrollRef } from 'src/providers/ScrollableRefProvider';
import { pick } from 'lodash';
import useUpdateScrollPosition from './useUpdateScrollPosition';
import { useInteractionFocusEffect } from 'src/hooks';
import { useTourGuideMaster } from '../TourGuideMaster';
import Color from 'color';

export interface GuideElementProps<T extends string | number> extends PropsWithChildren {
  id: T;
  body: React.ReactNode;
  title?: React.ReactNode;
  onContinue?: () => Promise<void> | void;
  onPress?: () => Promise<void> | void;
  updateAnimationConfig?: Omit<Animated.TimingAnimationConfig, 'toValue'>;
  semiTransparentBg?: boolean;
  disabled?: boolean;
  autoStart?: boolean;
  backgroundColor?: ColorValue;
  itemIndex?: number;
  sectionIndex?: number;
  viewOffset?: number;
  alignHorizontally?: boolean;
}

function _GuideElement<T extends string | number>({
  id,
  children,
  visible,
  body,
  exitTour,
  updateAnimationConfig = {
    duration: 300,
    easing: Easing.inOut(Easing.ease),
    useNativeDriver: !IS_WEB
  },
  onContinue,
  title,
  unregisterStep,
  onPress,
  semiTransparentBg,
  thisRef,
  autoStart,
  startTour,
  backgroundColor: parentBackgroundColor,
  itemIndex,
  sectionIndex,
  viewOffset,
  alignHorizontally = false
}: GuideElementProps<T> & _GuideElementProp<T>): React.ReactElement {
  const { t } = useTranslation('common');
  const { stopTours } = useTourGuideMaster();

  const { roundness, colors, dark } = useAppTheme();
  const viewRef = useRef<View>(null);
  const [layout, setLayout] = useState<Dimensions>({ width: 0, height: 0 });
  const [helperBox, setHelperBox] = useState({ width: 0, height: 0 });
  const window = useWindowDimensions();

  const scrollRef = useScrollRef();

  const position = useRef(new Animated.ValueXY({ x: 0, y: 0 }));
  const guidePosition = useRef(new Animated.ValueXY({ x: window.width / 2, y: window.height / 2 }));
  const guideOpacity = useRef(new Animated.Value(0));
  const cloneOpacity = useRef(new Animated.Value(0));
  const opacity = cloneOpacity.current.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0]
  });

  const caretPosition = useRef(new Animated.ValueXY({ x: 0, y: 0 }));

  const [guideDimensions, setGuideDimensions] = useState<Partial<Dimensions>>({});

  const focused = useIsFocused();

  const setPosition = useRef((l: LayoutRectangle) => {
    setLayout(pick(l, 'width', 'height'));
    position.current.setValue({
      x: normalizeX(l.x, window.width) - Size.XS,
      y: l.y - Size.XS
    });
  });

  const applyCoords = useRef((l: LayoutRectangle, h: Dimensions) => {
    const { computedGuidePosition, computedCaretPosition } = calculatePosition(
      l,
      h,
      window,
      alignHorizontally
    );
    setPosition.current(l);
    const opacityAnimation = Animated.sequence([
      Animated.timing(cloneOpacity.current, {
        toValue: 1,
        ...updateAnimationConfig
      }),
      Animated.timing(guideOpacity.current, {
        toValue: 1,
        ...updateAnimationConfig
      })
    ]);
    const animation = Animated.parallel([
      Animated.timing(guidePosition.current, {
        toValue: computedGuidePosition,
        ...updateAnimationConfig
      }),
      Animated.timing(caretPosition.current, {
        toValue: computedCaretPosition,
        ...updateAnimationConfig
      })
    ]);
    animation.start();
    opacityAnimation.start();

    return () => {
      animation.stop();
      opacityAnimation.stop();
    };
  });

  const backgroundColor: ColorValue | undefined = useMemo(() => {
    if (semiTransparentBg) {
      return dark
        ? Color(colors.surface).lighten(0.5).string()
        : Color(colors.surface).darken(0.2).string();
    }
    return parentBackgroundColor;
  }, [semiTransparentBg, parentBackgroundColor, dark, colors.surface]);

  const applyScrollCoords = useCallback(
    (l: LayoutRectangle, h: Dimensions) => {
      setPosition.current(l);
      const { computedGuidePosition, computedCaretPosition } = calculatePosition(
        { ...l, x: normalizeX(l.x, window.width) },
        h,
        window,
        alignHorizontally
      );
      setGuideDimensions({
        width: computedGuidePosition.width,
        height: computedGuidePosition.height
      });
      const animation = Animated.parallel([
        Animated.timing(guidePosition.current, {
          toValue: computedGuidePosition,
          ...updateAnimationConfig
        }),
        Animated.timing(caretPosition.current, {
          toValue: computedCaretPosition,
          ...updateAnimationConfig
        }),
        Animated.timing(guideOpacity.current, {
          toValue: 1,
          ...updateAnimationConfig
        }),
        Animated.timing(cloneOpacity.current, {
          toValue: 1,
          ...updateAnimationConfig
        })
      ]);
      animation.start();
      return animation.stop;
    },
    [alignHorizontally, updateAnimationConfig, window]
  );

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const clearScrollTimeout = useRef(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  });

  const updateScrollPosition = useUpdateScrollPosition({
    visible,
    focused,
    clearScrollTimeout,
    applyScrollCoords,
    helperBox,
    scrollRef,
    viewRef
  });

  const handle: GuideElementHandle = useMemo(
    () => ({ updateScrollPosition }),
    [updateScrollPosition]
  );

  useImperativeHandle<GuideElementHandle, GuideElementHandle>(thisRef, () => handle, [handle]);
  const internalRef = useRef<GuideElementHandle>(handle);

  useEffect(() => {
    internalRef.current = handle;
  }, [handle]);

  useInteractionFocusEffect(() => {
    if (visible && focused) {
      if (!scrollRef?.current) {
        const helperDims = { width: helperBox.width, height: helperBox.height };
        measureRefWithoutScrollView(viewRef, helperDims, applyCoords.current);
      } else if ((scrollRef.current as ScrollView).getInnerViewNode) {
        viewRef.current?.measureLayout(
          (scrollRef.current as ScrollView).getInnerViewNode(),
          (x, y) => focusView(x, y, scrollRef, itemIndex, sectionIndex, viewOffset)
        );
      } else {
        const node = scrollRef?.current?.getScrollableNode();
        const refNode = IS_WEB ? node.children[0] : node;
        viewRef.current?.measureLayout(refNode, (x, y) => {
          focusView(x, y, scrollRef, itemIndex, sectionIndex, viewOffset);
        });
      }
      timeout.current = setTimeout(internalRef.current.updateScrollPosition, SCROLL_FOCUS_DELAY);
      return clearScrollTimeout.current;
    } else {
      cloneOpacity.current.setValue(0);
    }
  }, [
    visible,
    scrollRef,
    clearScrollTimeout,
    helperBox.width,
    helperBox.height,
    focused,
    itemIndex,
    sectionIndex,
    viewOffset
  ]);

  useImperativeHandle<GuideElementHandle, GuideElementHandle>(thisRef, () => handle, [handle]);

  useEffect(() => {
    if (visible) {
      Keyboard.dismiss();
    }
  }, [visible]);

  useEffect(() => {
    if (autoStart) {
      startTour();
    }
  }, [autoStart, startTour, id]);

  return (
    <>
      {visible && (
        <Portal>
          <ErrorBoundary>
            <Animated.View
              style={{
                position: 'absolute',
                transform: position.current.getTranslateTransform(),
                padding: Size.XS,
                width: layout.width + Size.XS * 2,
                height: layout.height + Size.XS * 2,
                borderRadius: roundness,
                opacity: cloneOpacity.current,
                backgroundColor
              }}
            >
              <TouchableWithoutFeedback
                onPress={async () => {
                  await onPress?.();
                  await onContinue?.();
                  unregisterStep(id);
                }}
              >
                <View pointerEvents='none'>
                  <ErrorBoundary>
                    {React.cloneElement(
                      children as React.DetailedReactHTMLElement<any, HTMLElement>
                    )}
                  </ErrorBoundary>
                </View>
              </TouchableWithoutFeedback>
            </Animated.View>
            <Animated.View
              style={{
                minWidth: MOBILE_WIDTH_SMALL / 2,
                transform: guidePosition.current.getTranslateTransform(),
                maxWidth: MOBILE_WIDTH_SMALL - Size.X2_L,
                opacity: guideOpacity.current
              }}
            >
              <HoverCard
                onLayout={(event) => {
                  const { width, height } = event.nativeEvent.layout;
                  setHelperBox({ width, height });
                }}
                style={{
                  gap: Size.S,
                  padding: Size.S,
                  position: 'absolute',
                  minWidth: MOBILE_WIDTH_SMALL / 2,
                  width: guideDimensions.width,
                  height: guideDimensions.height
                }}
              >
                <Animated.View
                  style={{
                    width: Size.S,
                    height: Size.S,
                    backgroundColor: colors.surface,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transform: [
                      ...caretPosition.current.getTranslateTransform(),
                      { rotate: '45deg' }
                    ]
                  }}
                />
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  {!!title && <Subheading color={colors.onSurface}>{title}</Subheading>}
                </View>
                <View>
                  {typeof body === 'string' ? <Body color={colors.onSurface}>{body}</Body> : body}
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <HoverButton
                    buttonColor={colors.disabled}
                    onPress={() => {
                      exitTour();
                      void stopTours();
                    }}
                  >
                    {t('stopTours')}
                  </HoverButton>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <HoverButton onPress={exitTour}>{t('skip')}</HoverButton>
                    <HoverButton
                      onPress={async () => {
                        await onContinue?.();
                        unregisterStep(id);
                      }}
                      mode='contained'
                    >
                      {t('continue')}
                    </HoverButton>
                  </View>
                </View>
              </HoverCard>
            </Animated.View>
          </ErrorBoundary>
        </Portal>
      )}
      <Animated.View ref={viewRef} collapsable={false} style={{ opacity }}>
        {children}
      </Animated.View>
    </>
  );
}

export default _GuideElement;
