import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys, getPatients, getSchedule } from 'src/api';
import { Schedule } from 'src/interfaces';
import { useUser } from 'src/providers/ClientProvider';
import { useTranslation } from 'react-i18next';
import { useRequestSetting } from 'src/hooks';
import { ColumnOption } from './model';
import { isPatientActive } from 'src/utils';

interface ScheduleQuery {
  data: Schedule;
}

const useAppointmentRequestOptions = () => {
  const { data: patients = [] } = useQuery([QueryKeys.PATIENTS], {
    queryFn: getPatients,
    select: (ps) => ps.filter(isPatientActive)
  });
  const { t } = useTranslation('appointmentRequest');

  const { user } = useUser();
  // get schedule
  const { data: schedule } = useQuery({
    queryFn: async () => await getSchedule(user?.practiceId),
    queryKey: [QueryKeys.SCHEDULE, user?.practiceId]
  }) as ScheduleQuery;

  const patientOptions = useMemo(
    () =>
      patients
        .map((patient) => ({
          label: patient.name,
          value: patient.patientId
        }))
        .concat({
          label: t('newPet'),
          value: 'New Pet'
        }),
    [patients, t]
  );

  const apptTypeOptions = useMemo(
    () =>
      (schedule?.appointmentTypes ?? []).map((at) => ({
        label: at.description,
        value: at.id,
        length: at.length,
        categories: at.categories
      })),
    [schedule]
  );

  const fieldDisplayOptions = useMemo(() => {
    return {
      minDays: schedule?.daysInAdvanceMin,
      maxDays: schedule?.daysInAdvanceMax,
      useAppointmentTypes: schedule?.useAppointmentTypes,
      allowCategorySelection: schedule?.allowCategorySelection,
      categoryType: schedule?.categoryType,
      requireComments: schedule?.requireComments
    };
  }, [schedule]);

  const columnOptions: ColumnOption[] = useMemo(() => {
    const categories = [];
    if (schedule?.allowNoPreference) {
      categories.push({
        label: t('noPreference'),
        value: 'No Preference'
      });
    }
    for (const [colKey, value] of Object.entries(schedule?.categories ?? {})) {
      let appointmentLength;
      // when useAppointmentTypes is false, appointmentLength must come from the category itself
      if (!fieldDisplayOptions.useAppointmentTypes && schedule.categories) {
        appointmentLength = value.duplicateOfAppointmentCategoryId
          ? schedule.categories[value.duplicateOfAppointmentCategoryId].appointmentLength
          : value.appointmentLength;
      }
      categories.push({
        label: value.descriptionOverride ? value.descriptionOverride : value.description,
        value: value.descriptionOverride ? value.descriptionOverride : value.description,
        categoryId: value.id,
        appointmentLength,
        colKey,
        duplicateOfAppointmentCategoryId: value.duplicateOfAppointmentCategoryId
      });
    }
    return categories;
  }, [schedule, fieldDisplayOptions, t]);

  const { data: requestSettings } = useRequestSetting();

  return {
    requestSettings,
    patientOptions,
    apptTypeOptions,
    columnOptions,
    fieldDisplayOptions
  };
};

export default useAppointmentRequestOptions;
