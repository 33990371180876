import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Body, Headline, HoverButton, HoverCard, MiniHeroIcon } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Margin, endpoint } from 'src/constants';
import styled from 'styled-components/native';
import { useBookingState } from '../BookingProvider';
import ConfirmationSection from './ConfirmationSection';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { isDefinedOrThrowServerError, queryClient } from 'src/utils';
import { BOOKING_STEPS, SUBMISSION_STATE } from '../BookingProvider/helpers';
import { ProgressBar } from 'react-native-paper';
import { AppColors } from 'src/types/paper';
import { LayoutAnimation } from 'react-native';
import { ApiPracticeInfo } from 'src/interfaces';
import Confetti from 'src/scenes/ToDos/ToDoList/components/Confetti';
import { AppointmentRequestSettings, BookingFormData } from '../BookingProvider/model';
import RichTextRenderer from 'src/components/PracticeTextRenderer';
import BookingStateIcon from './BookingStateIcon';
import moment from 'moment-timezone';
import { SvgProps } from 'react-native-svg';
import { unauthenticatedApiClient } from 'src/utils/axios/unauthenticated';
import { StyledScrollView } from './shared';

const REFETCH_INTERVAL = moment.duration(5, 'seconds').asMilliseconds();
const MIN_STATE_DURATION = moment.duration(1, 'seconds').asMilliseconds();

enum WRITEBACK_STATUS {
  SUCCESS = 'success',
  MANUAL = 'manual_entry_required',
  PENDING = 'pending'
}

interface RequestStatus {
  id: number;
  writeback_status: WRITEBACK_STATUS;
}

const BookingStatus: React.FC = () => {
  const { colors } = useAppTheme();
  const { values, errors, practiceInfo, goToStep, isSubmitting, requestSetting } =
    useBookingState();
  const { t } = useTranslation('onlineBooking');

  const { data, isError } = useQuery({
    queryKey: [QueryKeys.WRITEBACK_STATUS, values.requestId, 1],
    queryFn: async () => {
      const response = await unauthenticatedApiClient.get<RequestStatus>(
        endpoint('REQUEST_STATUS'),
        {
          params: {
            request_id: values.requestId
          }
        }
      );

      return isDefinedOrThrowServerError(response?.data);
    },
    enabled: !isSubmitting && !!values.requestId,
    refetchInterval: (data) => {
      if (data?.writeback_status === WRITEBACK_STATUS.MANUAL) return false;
      if (data?.writeback_status === WRITEBACK_STATUS.SUCCESS) return false;
      return REFETCH_INTERVAL;
    }
  });

  const [state, setStringsKey] = React.useState(SUBMISSION_STATE.SUBMITTING);

  const setNextState = useMemo(
    () =>
      debounce(
        (state: SUBMISSION_STATE) =>
          setStringsKey((prev) => {
            if (prev === state) return prev;
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            return state;
          }),
        MIN_STATE_DURATION,
        {
          leading: false,
          trailing: true
        }
      ),
    []
  );
  useEffect(() => {
    const nextState = getNextState({ isSubmitting, isError, data, values });
    if (state !== nextState) {
      setNextState(nextState);
    }
  }, [data, isError, isSubmitting, setNextState, values, values.clientInfo.clientId, state]);

  const Icon = useCallback(
    (props: SvgProps) => <BookingStateIcon state={state} {...props} />,
    [state]
  );

  return (
    <StyledScrollView>
      <StyledCard>
        <MiniHeroIcon icon={Icon} color={switchColor(state, colors)} />
        <CenteredText as={Headline}>{t(`status.${state}.title`)}</CenteredText>

        <ProgressBar
          indeterminate={switchProgress(state, practiceInfo) === undefined}
          color={switchColor(state, colors)}
          progress={switchProgress(state, practiceInfo)}
        />

        <RichTextRenderer
          fallback={
            <CenteredText>
              {t(`status.${state}.description`, { email: values.clientInfo.emailAddress })}
            </CenteredText>
          }
        >
          {getPracticeText(state, requestSetting)}
        </RichTextRenderer>

        {isError && (
          <HoverButton
            mode='contained'
            onPress={async () => {
              void queryClient.resetQueries([QueryKeys.SCHEDULE_OPENINGS]);
              goToStep(BOOKING_STEPS.SELECT_APPOINTMENT);
            }}
          >
            {t('status.unavailable.retry')}
          </HoverButton>
        )}
      </StyledCard>
      <ConfirmationSection
        section={'appointmentInfo'}
        values={values}
        errors={errors}
        practiceInfo={practiceInfo}
        disableEditing
      />
      {state === SUBMISSION_STATE.SCHEDULED && <StyledConfetti />}
    </StyledScrollView>
  );
};

export default BookingStatus;

const StyledCard = styled(HoverCard)`
  padding: ${Margin.ExtraLarge}px;
  gap: ${Margin.Large}px;
`;

const CenteredText = styled(Body)`
  text-align: center;
`;

const getNextState = ({
  isSubmitting,
  isError,
  data,
  values
}: {
  isSubmitting: boolean;
  isError: boolean;
  data: RequestStatus | undefined;
  values: Partial<BookingFormData>;
}): SUBMISSION_STATE => {
  if (isSubmitting || !data) return SUBMISSION_STATE.SUBMITTING;
  if (isError) return SUBMISSION_STATE.UNAVAILABLE;
  if (!values.clientInfo?.clientId || !values.patientInfo?.patientId)
    return SUBMISSION_STATE.NEW_CLIENT;
  if (data?.writeback_status === WRITEBACK_STATUS.SUCCESS) return SUBMISSION_STATE.SCHEDULED;
  return SUBMISSION_STATE.SUBMITTED;
};

const switchColor = (state: SUBMISSION_STATE, colors: AppColors) => {
  switch (state) {
    case SUBMISSION_STATE.SUBMITTING:
      return colors.warn;
    case SUBMISSION_STATE.UNAVAILABLE:
      return colors.error;
    default:
      return colors.primary;
  }
};

const switchProgress = (state: SUBMISSION_STATE, practiceInfo: ApiPracticeInfo) => {
  switch (state) {
    case SUBMISSION_STATE.SUBMITTING:
      return undefined;
    case SUBMISSION_STATE.SUBMITTED:
      if (practiceInfo.onlineBooking) return undefined;
      return 1;
    case SUBMISSION_STATE.UNAVAILABLE:
      return 0;
    case SUBMISSION_STATE.NEW_CLIENT:
    case SUBMISSION_STATE.SCHEDULED:
      return 1;
  }
};

const getPracticeText = (state: SUBMISSION_STATE, requestSetting: AppointmentRequestSettings) => {
  switch (state) {
    case SUBMISSION_STATE.SCHEDULED:
      return requestSetting.onlineBookingConfirmationText;
    case SUBMISSION_STATE.SUBMITTED:
    case SUBMISSION_STATE.NEW_CLIENT:
      return requestSetting.confirmationText;
    default:
      return undefined;
  }
};

const StyledConfetti = styled(Confetti)`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;
