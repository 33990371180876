export enum Species {
  AVIAN = 'avian',
  BOVINE = 'bovine',
  BIRD = 'bird',
  CAT = 'cat',
  CANINE = 'canine',
  COW = 'cow',
  EQUINE = 'equine',
  FELINE = 'feline',
  DOG = 'dog',
  HORSE = 'horse',
  LAGOMORPH = 'lagomorph',
  OTHER = 'other',
  RODENT = 'rodent'
}
