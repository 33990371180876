import { isPeriodUnit, Patient, PeriodUnit, Todo, TodoAlert } from 'src/interfaces';
import notifee, {
  AndroidCategory,
  AndroidImportance,
  Event,
  EventDetail,
  EventType,
  Notification,
  Trigger,
  TriggerNotification,
  TriggerType
} from '@notifee/react-native';
import moment, { MomentInput } from 'moment-timezone';
import { NotificationCategory } from './NotificationCategory';
import { CHANNELS } from './ChannelsAndCategories';
import { navigateToTodos } from 'src/utils/linking';
import { todoAlertId, todoBody, todoTitle } from './notificationIdGenerators';
import { isNumber } from 'lodash';
import { DEBUG_NOTIFICATIONS } from 'src/constants';
import { postStatus } from 'src/api/push';
import { urlEmitter } from 'src/hooks/useDeeplink/helper';
import { Linking } from 'react-native';
import { NotificationStatus } from 'src/utils/remoteNotifications/types';

interface TodoNotification extends Notification {
  id: string;
  data: {
    category: 'Todo';
    todoAlertId: string;
    period?: number;
    periodUnit?: PeriodUnit;
    alertByMinutesPrior?: number;
    dueDate: Date;
    notes: string;
  };
}

interface TodoTriggerNotification extends TriggerNotification {
  notification: TodoNotification;
}

/**
 * Extracts alerts from a todo list into dictionary.
 * @param todos
 * @returns
 */
const extractAlerts = (todos: Todo[]) => {
  const alerts: Record<string, TodoAlert> = {};
  todos.forEach(({ todoAlertsAttributes }) => {
    todoAlertsAttributes.forEach((alert) => {
      alerts[todoAlertId(alert)] = alert;
    });
  });
  return alerts;
};

/**
 * Fetches pending notifications on the system categorized as TODO.
 * @returns
 */
const getTodoNotifications = async () =>
  (await notifee.getTriggerNotifications()).filter(
    ({ notification }) => notification.data?.category === NotificationCategory.TODO
  ) as TodoTriggerNotification[];

/**
 * Cancels any pending TODO system notifications that have been deleted from the todos api.
 * @param todos
 */
const cancelDeletedTodos = async (todos: Todo[]) => {
  const alerts = extractAlerts(todos);
  const notifications = await getTodoNotifications();
  notifications.forEach(({ notification: { data, id } }) => {
    if (!alerts[data.todoAlertId]) {
      void notifee.cancelTriggerNotification(id);
    }
  });
};

/**
 * Generates a trigger configuration based on a todo repeat type
 * @param todo
 * @param alert
 * @returns Trigger
 */

const generateTrigger = (todo: Todo, alert: TodoAlert): Trigger | undefined => {
  if (todo.isComplete || !todo.dueDate || !todo.allowNotify) {
    return;
  }

  if (todo.periodUnit && todo.period) {
    const diff = calculateNextAlert(
      todo.dueDate,
      todo.periodUnit,
      todo.period,
      alert.alertByMinutesPrior
    );
    return {
      type: TriggerType.TIMESTAMP,
      timestamp: diff.valueOf()
    };
  }

  const timestamp = moment(todo.dueDate).subtract(alert.alertByMinutesPrior ?? 0, 'minutes');
  if (timestamp.isAfter(moment())) {
    return {
      type: TriggerType.TIMESTAMP,
      timestamp: timestamp.valueOf()
    };
  }
};

/**
 * Schedules an alert notification for a todo.
 * @param todo
 * @param alert
 * @returns
 */
const scheduleTodoAlert = async (todo: Todo, alert: TodoAlert, patients: Patient[]) => {
  const trigger = generateTrigger(todo, alert);
  const patientName = patients.find((p) => p.patientId === todo.patientId)?.name;
  if (!trigger) return;
  const nextNotification = {
    id: todoAlertId(alert),
    title: todoTitle(todo, patientName),
    body: todoBody(todo, alert, trigger),
    data: generateTodoData(todo, alert, trigger),
    ...addDeviceSettings(NotificationCategory.TODO)
  };
  await notifee.createTriggerNotification(nextNotification, trigger);
};

/**
 * Adds device specific settings to notification to fire properly.
 * @param t
 * @returns
 */
export const addDeviceSettings = (type: NotificationCategory) => ({
  android: {
    channelId: CHANNELS[type].id,
    pressAction: {
      id: 'default'
    },
    category: AndroidCategory.REMINDER,
    importance: AndroidImportance.HIGH
  },
  ios: {
    categoryId: type,
    sound: 'default'
  }
});

export const generateTodoData = (todo: Todo, alert: TodoAlert, trigger: Trigger) => {
  const todoDetails = {
    category: NotificationCategory.TODO,
    todoAlertId: alert.id,
    todoId: todo.id,
    alertByMinutesPrior: alert.alertByMinutesPrior ?? 0
  };

  if (isNumber(todo.period) && isPeriodUnit(todo.periodUnit) && moment(todo.dueDate).isValid()) {
    Object.assign(todoDetails, {
      period: todo.period,
      periodUnit: todo.periodUnit,
      dueDate: todo.dueDate
    });
  }

  if (trigger.type === TriggerType.TIMESTAMP && trigger.timestamp) {
    Object.assign(todoDetails, {
      alertTimestamp: moment(trigger.timestamp).toISOString()
    });
  }

  return todoDetails;
};

/**
 * Cancels notifications if the corresponding todo does not exist on the todos list.
 * Updates or creates notifications for each alert in the todos list.
 * @param todos
 */
export const scheduleTodos = async (todos: Todo[], patients: Patient[]) => {
  await cancelDeletedTodos(todos);
  todos.forEach((todo) =>
    todo.todoAlertsAttributes.map(async (alert) => {
      await scheduleTodoAlert(todo, alert, patients);
    })
  );
};

/**
 * Handles a notification action by category
 */
export const handlePressAction = async (detail: EventDetail) => {
  reportStatus(NotificationStatus.OPENED, detail.notification);
  const url = detail.notification?.data?.url;
  if (typeof url === 'string') {
    const match = url.match(/^((https:\/\/practices(.dev)?.allydvm.com)|(asteroid:\/\/))/);
    if (match) {
      const $meta = detail.notification?.data?.$metadata;
      const metadata = typeof $meta === 'string' ? JSON.parse($meta) : $meta;
      urlEmitter.emit('url', { url, userId: metadata.userId });
    } else if (url.match(/^https:\/\//) && (await Linking.canOpenURL(url))) {
      void Linking.openURL(url);
    }
  } else {
    const { category } = detail.notification?.data ?? {};
    switch (category) {
      case NotificationCategory.TODO:
        navigateToTodos();
    }
  }
};

/**
 * Handles a notification delivery by category
 */
const handleDelivered = (detail: EventDetail) => {
  const { category } = detail.notification?.data ?? {};
  reportStatus(NotificationStatus.DELIVERED, detail.notification);
  switch (category) {
    case NotificationCategory.TODO:
      rescheduleRepeatingTodoAlert(detail.notification);
      break;
  }
};

/**
 * Schedules repeating alerts for a todo after delivery.
 * @param notification
 * @returns
 */
const rescheduleRepeatingTodoAlert = (notification?: Notification) => {
  const { data } = notification ?? {};
  if (
    !notification?.data ||
    !data ||
    !isNumber(data.period) ||
    !isPeriodUnit(data.periodUnit) ||
    !moment(data?.dueDate).isValid() ||
    !isNumber(data.alertByMinutesPrior)
  )
    return;

  const nextAlert = calculateNextAlert(
    data.dueDate,
    data.periodUnit,
    data.period,
    data.alertByMinutesPrior
  );

  const trigger: Trigger = {
    type: TriggerType.TIMESTAMP,
    timestamp: nextAlert.valueOf()
  };
  notification.data.alertTimestamp = moment(trigger.timestamp).toISOString();

  const nextAlertBody = todoBody(
    data,
    {
      alertByMinutesPrior: data.alertByMinutesPrior
    },
    trigger
  );

  void notifee.createTriggerNotification({ ...notification, body: nextAlertBody }, trigger);
};

/**
 * Listener for notification events on notifee onBackgroundEvent and onForegroundEvent methods
 */
export const notificationEventListener = async ({ type, detail }: Event) => {
  if (DEBUG_NOTIFICATIONS) {
    console.debug(
      '[DEBUG_NOTIFICATIONS]',
      EventType[type],
      JSON.stringify(detail.notification?.id)
    );
  }
  switch (type) {
    case EventType.DELIVERED:
      handleDelivered(detail);
      break;
    case EventType.ACTION_PRESS:
    case EventType.PRESS:
      void handlePressAction(detail);
      break;
    case EventType.DISMISSED:
      handleDismissed(detail);
      break;
    default:
  }
};

/**
 * Calculates the next alert for a repeating todo.
 * Note, this has issues repeating alerts at an interval of once every minute.
 *
 * @param dueDate
 * @param periodUnit
 * @param period
 * @param alertByMinutesPrior
 * @returns
 */
const calculateNextAlert = (
  dueDate: MomentInput,
  periodUnit: PeriodUnit,
  period: number,
  alertByMinutesPrior = 0
) => {
  const timestamp = moment(dueDate).subtract(alertByMinutesPrior ?? 0, 'minutes');
  const intervalIncrease = Math.ceil(moment().diff(timestamp, periodUnit, true) / period);
  const nextAlert = timestamp.clone().add(intervalIncrease * period, periodUnit);
  return nextAlert;
};

const handleDismissed = (detail: EventDetail) => {
  reportStatus(NotificationStatus.DISMISSED, detail.notification);
};

const reportStatus = (status: NotificationStatus, notification?: Notification) => {
  if (typeof notification?.data?.$metadata === 'string') {
    const $metadata = JSON.parse(notification.data.$metadata);
    void postStatus({
      id: $metadata.id,
      status,
      sourceId: $metadata.sourceId,
      practiceId: $metadata.practiceId
    });
  }
};
