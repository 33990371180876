// use yup to generate validation schema
import * as yup from 'yup';
import { t } from 'i18next';
import { ImageUpload } from 'src/components/UploadFiles';
import { AppointmentOption } from 'src/hooks/useAppointmentOptions/types';
import { ColumnOption, NO_PREFERENCE } from 'src/hooks/useAppointmentOptions/extractColumnOptions';
import { PreferredContactMethod } from 'src/interfaces';

export const clientSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('errors.nameRequired')
    .matches(/^.+ .+/, 'errors.firstAndLastRequired'),
  emailAddress: yup.string().required('errors.emailRequired').email('errors.emailInvalid'),
  phoneNumber: yup
    .string()
    .required('errors.phoneRequired')
    .matches(/^\d{10}$/, 'errors.phoneInvalid'),
  acceptConditions: yup.boolean().test({
    test: (value) => !!value,
    message: 'errors.acceptConditionsRequired',
    name: 'acceptConditions'
  })
});

export const patientSchema = yup.object().shape({
  name: yup.string().required('errors.patientNameRequired'),
  breed: yup.string().when('isNewPatient', {
    is: (isNewPatient: boolean) => !!isNewPatient,
    then: yup.string().required('errors.patientBreedRequired')
  }),
  age: yup.string().when('isNewPatient', {
    is: (isNewPatient: boolean) => !!isNewPatient,
    then: yup.string().required('errors.patientAgeRequired')
  }),
  sex: yup.string().when('isNewPatient', {
    is: (isNewPatient: boolean) => !!isNewPatient,
    then: yup.string().required('errors.patientSexRequired')
  }),
  isNewPatient: yup.boolean()
});

export const notificationSchema = yup.object().shape({
  smsOptIn: yup.boolean().required('errors.smsOptInRequired'),
  preferredContactMethod: yup
    .mixed<PreferredContactMethod>()
    .required('errors.preferredContactMethodRequired')
    .oneOf(Object.values(PreferredContactMethod), 'errors.preferredContactMethodInvalid')
});

export const imageUploadSchema = yup
  .object()
  .test({
    test: (imagesDict: Record<string, ImageUpload>): boolean =>
      Object.values(imagesDict).every((image: ImageUpload) => !image?.error),
    message: t('patientProfile:deleteBeforeProceeding'),
    name: 'deleteBeforeProceeding'
  })
  .test({
    test: (imagesDict: Record<string, ImageUpload>): boolean =>
      Object.values(imagesDict).every((image: ImageUpload) => !!image?.id),
    message: t('common:imagesNotReady'),
    name: 'imagesNotReady'
  })
  .default({});

export const additionalInfoSchema = yup.object().shape({
  requireComments: yup.boolean(),
  comments: yup.string().when('requireComments', {
    is: (requireComments: boolean) => !!requireComments,
    then: yup.string().required('errors.commentsRequired')
  }),
  images: imageUploadSchema
});

const columnSchema = yup.object().shape({
  label: yup.string().required(),
  value: yup.string().required(),
  categoryId: yup.number(),
  appointmentLength: yup.number(),
  colKey: yup.string(),
  duplicateOfAppointmentCategoryId: yup.string().nullable()
});

export const appointmentSchema = yup.object().shape({
  useAppointmentTypes: yup.boolean(),
  appointmentOption: yup.object().when('useAppointmentTypes', {
    is: (useAppointmentTypes: boolean) => !!useAppointmentTypes,
    then: yup.object().shape({
      value: yup.string().required('errors.selectionRequired')
    })
  }),
  allowCategorySelection: yup.boolean(),
  columnOption: yup.object().when('allowCategorySelection', {
    is: (allowCategorySelection: boolean) => !!allowCategorySelection,
    then: columnSchema.required('errors.selectionRequired').test({
      name: 'isValidCategory',
      message: 'errors.notValidCategory',
      test: function (value: ColumnOption) {
        const { appointmentOption } = this.parent;
        if (appointmentOption as AppointmentOption) {
          return (
            value.value === NO_PREFERENCE || appointmentOption.categories?.includes(value.colKey)
          );
        }
        return true;
      }
    })
  }),
  preferredTime: yup
    .string()
    .required('errors.timeRequired')
    .when(['preferredDate', 'availableDates'], {
      is: (preferredDate: string | undefined, availableDates: string[] | undefined) => {
        return preferredDate && availableDates?.includes(preferredDate);
      },
      then: (schema) =>
        schema.test({
          name: 'isValidTime',
          message: 'errors.timeNotAvailable',
          test: function (value) {
            const { availableTimes = [] } = this.parent;
            return !!availableTimes?.includes(value);
          }
        })
    }),
  preferredDate: yup
    .string()
    .required('errors.dateRequired')
    .when(['allowCategorySelection', 'columnOption', 'appointmentOption', 'useAppointmentTypes'], {
      is: (
        allowCategorySelection: boolean,
        columnOption: ColumnOption | undefined,
        appointmentOption: AppointmentOption | undefined,
        useAppointmentTypes: boolean | undefined
      ) => {
        if (!allowCategorySelection) {
          return false;
        } else if (useAppointmentTypes) {
          return !!appointmentOption?.categories?.find((cat) => cat === columnOption?.colKey);
        }
        return true;
      },
      then: yup.string().test({
        name: 'isValidDate',
        message: 'errors.dateNotAvailable',
        test: function (value) {
          const { availableDates = [] } = this.parent;
          return !!availableDates?.includes(value);
        }
      })
    }),
  availableDates: yup.array().of(yup.string()),
  availableTimes: yup.array().of(yup.string()),
  availableColumns: yup.array().of(columnSchema)
});

export const validationSchema = yup.object().shape({
  clientInfo: clientSchema,
  patientInfo: patientSchema,
  appointmentInfo: appointmentSchema,
  additionalInfo: additionalInfoSchema,
  notificationSettings: notificationSchema
});
