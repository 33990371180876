import { Platform } from 'react-native';
import Constants from 'expo-constants';
import moment from 'moment-timezone';
import { nativeBuildVersion } from 'expo-application';
import appPackage from '../../package.json';

export const APP_ENV = Constants?.expoConfig?.extra?.APP_ENV;

export const IS_IOS = Platform.OS === 'ios';
export const IS_ANDROID = Platform.OS === 'android';
export const IS_WEB = Platform.OS === 'web';
export const IS_EXPO_GO = Constants.appOwnership === 'expo';

export const NOTIFICATIONS_SUPPORTED = !IS_WEB && !IS_EXPO_GO;

export const SCHEME = IS_EXPO_GO ? 'exp' : IS_WEB ? 'http' : 'asteroid';

export const IS_PROD = APP_ENV === 'PROD';
export const IS_DEV = APP_ENV === 'DEV';
export const IS_DEBUG = !IS_PROD && !IS_DEV;

export const MOBILE_WIDTH_EXTRA_SMALL = 375;
export const MOBILE_WIDTH_SMALL = 400;
export const MOBILE_WIDTH_MAX = 640;
export const NOT_MOBILE_WIDTH = 850;
export const TABLET_WIDTH_PORTRAIT_MIN = 768;
export const TABLET_WIDTH_PORTRAIT_MAX = 1024;
export const TABLET_WIDTH_LANDSCAPE_MAX = 1368;

export const MODAL_EXTRA_SCROLL_HEIGHT = 100;

export const VERSION_INFO = `v${appPackage.version} ${
  nativeBuildVersion ? `(${nativeBuildVersion})` : ''
} `;

export enum Size {
  X5_S = 1,
  X4_S = 2,
  X3_S = 4,
  X2_S = 8,
  XS = 12,
  S = 16,
  M = 24,
  L = 28,
  XL = 32,
  X2_L = 48,
  X3_L = 64,
  X4_L = 72,
  X5_L = 128
}

export enum Margin {
  ExtraSmall = Size.X4_S,
  Small = Size.X3_S,
  Medium = Size.X2_S,
  Large = Size.S,
  ExtraLarge = Size.M
}

export const PHONE_MASK = '(999) 999-9999';
export const INTL_PHONE_MASK = '+9 (999) 999-9999';
export const SMS_MASK = '999999';
const ZIP_MASK_CANADA = 'SSS SSS';
const ZIP_MASK_USA = '99999-9999';
export const ZIP_MASK = [ZIP_MASK_USA, ZIP_MASK_CANADA];

export const ALLY_SUPPORT_EMAIL = 'support@allydvm.com';

export enum Language {
  en = 'en',
  es = 'es'
}

export enum IconSize {
  XS = 20,
  S = 28,
  M = 32,
  L = 48,
  XL = 96,
  XXL = 128,
  XXXL = 192
}
export const TOP_TAB_WIDTH = 100;

export const STROKE_WIDTH_DEFAULT = 1.5;

export const PAGE_LENGTH = 10;

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const TWO_WAY_TEXTING = 'two_way_texting';

export const FIXED_INPUT_HEIGHT = 68;

export const alertOptions: number[] = [
  moment.duration(0, 'seconds').asMinutes(),
  moment.duration(5, 'minutes').asMinutes(),
  moment.duration(15, 'minutes').asMinutes(),
  moment.duration(30, 'minutes').asMinutes(),
  moment.duration(1, 'hour').asMinutes(),
  moment.duration(2, 'hours').asMinutes(),
  moment.duration(1, 'day').asMinutes(),
  moment.duration(2, 'days').asMinutes()
];

export const IMAGES_DIRECTORY = 'images';
export const OLD_IMAGES_DIRECTORY = 'patient_images';
export const IMAGE_CACHE_VERSION = 'v1.0.1';
export const DEBUG_CACHE = Constants?.expoConfig?.extra?.DEBUG_CACHE;
export const TOKEN = Constants?.expoConfig?.extra?.TOKEN;
export const DEBUG_NOTIFICATIONS = Constants?.expoConfig?.extra?.DEBUG_NOTIFICATIONS;

export const PETPAGE_APP_STORE = 'https://apps.apple.com/us/app/petpage/id6443568264';
export const PETPAGE_PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.allydvm.ast';

export const HOSTNAME = Constants?.expoConfig?.extra?.REACT_NATIVE_PACKAGER_HOSTNAME as string;

export const NEW_PET = 'New Pet';
