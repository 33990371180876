import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'react-native-feather';
import { FAB } from 'react-native-paper';
import { IconSize } from 'src/constants';
import { useViewMode } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import { palette } from 'src/theme';
import { styles } from './styles';
import { layout } from 'src/theme/globalStyles';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestWizardFAB: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation('requests');
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();
  const { navigate } = useNavigation<NavigationProp<MainStackParamsList>>();

  const style = useMemo(() => {
    return styles(colors, horizontalInset);
  }, [colors, horizontalInset]);

  return (
    <FAB.Group
      color={colors.onPrimary}
      backdropColor={palette.BLACK_OPACITY_54}
      fabStyle={style.fabStyle}
      open={open}
      visible={!open}
      icon={() => (
        <Plus
          style={layout.justifyCenter}
          color={colors.onPrimary}
          height={IconSize.M}
          width={IconSize.M}
        />
      )}
      actions={[
        {
          icon: 'human',
          label: t('wizard.updateMyInfo'),
          onPress: () => navigate(Screens.PROFILE_INFORMATION_UPDATE),
          style: style.actionStyle,
          labelStyle: style.labelStyle,
          containerStyle: style.containerStyle,
          color: colors.onPrimary
        },
        {
          icon: 'paw',
          label: t('wizard.updatePetInfo'),
          onPress: () => navigate(Navigators.PATIENT_UPDATE_STACK_NAVIGATOR),
          style: style.actionStyle,
          labelStyle: style.labelStyle,
          containerStyle: style.containerStyle,
          color: colors.onPrimary
        },
        {
          icon: 'calendar',
          label: t('wizard.newAppointment'),
          onPress: () => navigate(Screens.APPOINTMENT_REQUEST),
          style: style.actionStyle,
          labelStyle: style.labelStyle,
          containerStyle: style.containerStyle,
          color: colors.onPrimary
        },
        {
          icon: 'package',
          label: t('wizard.productRequest'),
          onPress: () => navigate(Screens.REQUESTS_PRODUCT),
          style: style.actionStyle,
          labelStyle: style.labelStyle,
          containerStyle: style.containerStyle,
          color: colors.onPrimary
        }
      ]}
      onStateChange={() => setOpen(!open)}
    />
  );
};

export default RequestWizardFAB;
