import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native-paper';
import { Body, HoverButton, Title } from 'src/components';
import { IconSize, Size } from 'src/constants';
import styled from 'styled-components/native';
import { colorShade, transparent } from 'src/utils';
import { Patient } from 'src/interfaces';
import { Calendar } from 'src/components/kit/ABIcons';
import { useRequestSetting } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  patientId?: Patient['patientId'];
}
const AppointmentCardNone: React.FC<Props> = ({ patientId }) => {
  const { t } = useTranslation('appointments');
  const { colors, dark } = useAppTheme();
  const { data: requestSettings, isLoading, requestAppointment } = useRequestSetting();
  return (
    <ContentContainer>
      <FilledIconContainer>
        <Calendar
          width={IconSize.S}
          height={IconSize.S}
          color={dark ? colors.primary : colorShade(colors.primary, 0.8)}
        />
      </FilledIconContainer>
      <StyledTitle color={colors.onSurface}>{t('noAppointments')}</StyledTitle>
      <StyledBody color={colors.onSurface}>
        {requestSettings?.appointmentRequestsDisabled ? t('allCaughtUp') : t('scheduleYourNext')}
      </StyledBody>
      {!requestSettings?.appointmentRequestsDisabled && (
        <StyledCta onPress={() => requestAppointment(patientId)}>
          {isLoading ? <ActivityIndicator color={colors.onPrimary} /> : t('requestAnAppointment')}
        </StyledCta>
      )}
    </ContentContainer>
  );
};

export default AppointmentCardNone;

const FilledIconContainer = styled.View`
  aspect-ratio: 1;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.15)};
  border-radius: ${IconSize.L}px;
  padding: ${Size.XS}px;
`;

const StyledBody = styled(Body).attrs({ numberOfLines: 2 })`
  text-align: center;
  margin-top: ${Size.XS}px;
`;

const StyledTitle = styled(Title)`
  text-align: center;
  margin-top: ${Size.XS}px;
`;

const ContentContainer = styled.View`
  aling-items: center;
  margin-vertical: ${Size.S}px;
  margin-horizontal: ${Size.S}px;
`;

const StyledCta = styled(HoverButton).attrs({ mode: 'contained', uppercase: false })`
  margin-top: ${Size.M}px;
`;
