import {
  AppointmentRequest,
  AppointmentRequestContent,
  Client,
  Openings,
  PreferredContactMethod,
  RequestType
} from 'src/interfaces';
import { AnySchema, InferType, boolean, mixed, number, object, string } from 'yup';
import moment from 'moment-timezone';
import { ColumnOption } from './model';
import { ImageUpload } from 'src/components/UploadFiles';
import { t } from 'i18next';
import { Platform } from 'react-native';
import _ from 'lodash';

export type FormikData = InferType<typeof validationSchema>;

export const isFieldDirty = (
  field: keyof AppointmentRequestContent,
  values: AppointmentRequestContent,
  initialValues: AppointmentRequestContent
): boolean => !_.isEqual(values[field], initialValues[field]);

export const validationSchema = object({
  appointmentLength: number().optional(),
  appointmentType: string().optional(),
  appointmentTypeId: number().when('$useAppointmentTypes', (useAppointmentTypes, schema) =>
    useAppointmentTypes ? schema.required() : schema
  ),
  categoryId: number().when('$allowCategorySelection', (allowCategorySelection, schema) =>
    allowCategorySelection
      ? schema.when('column', {
          is: (val?: string) => val === 'No Preference',
          then: (s: AnySchema) =>
            s.transform((value) => (isNaN(value) ? undefined : value)).nullable()
        })
      : schema
  ),
  categoryType: string().optional(),
  column: string().optional(),
  comments: string().when('$requireComments', (requireComments, schema) =>
    requireComments ? schema.required().min(1) : schema
  ),
  images: object()
    .test({
      test: (imagesDict: Record<string, ImageUpload>): boolean =>
        Object.values(imagesDict).every((image: ImageUpload) => !image?.error),
      message: t('patientProfile:deleteBeforeProceeding'),
      name: 'deleteBeforeProceeding'
    })
    .test({
      test: (imagesDict: Record<string, ImageUpload>): boolean =>
        Object.values(imagesDict).every((image: ImageUpload) => !!image?.id),
      message: t('common:imagesNotReady'),
      name: 'imagesNotReady'
    })
    .default({}),
  patientId: string().when('petName', {
    is: (val?: string) => !val,
    then: (schema) => schema.required().matches(/^(?!New Pet).*/im)
  }),
  petName: string().optional(),
  preferredTime: string().required(),
  preferredDate: string().required(),
  preferredContactMethod: mixed<PreferredContactMethod>()
    .oneOf(Object.values(PreferredContactMethod))
    .required(),
  useAppointmentTypes: boolean().optional()
});

const enumerateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().format('YYYY-MM-DD'));
    currDate.add(1, 'days');
  }

  return dates;
};

export const findUnavailableDates = (
  openings: Openings | undefined,
  minDays: number | undefined,
  maxDays: number | undefined
) => {
  const allDates = enumerateDaysBetweenDates(
    moment().startOf('day').add(minDays, 'days').toDate(),
    moment().startOf('day').add(maxDays, 'days').toDate()
  );

  if (!openings) {
    return allDates.map((x) => moment(x).startOf('day').toDate());
  }

  const available = Object.keys(openings).map((d: any) =>
    moment(d).startOf('day').format('YYYY-MM-DD')
  );

  const result = allDates
    .filter((x) => !available.includes(x))
    .map((x) => moment(x).startOf('day').toDate());

  return result;
};

export const generateAvailableTimes = (
  openings: Openings | undefined,
  date: string | undefined,
  timeZone: string
) => {
  if (!date || !openings) {
    return [];
  }

  const dateString = moment(date).format('YYYY-MM-DD');
  return openings[dateString].map((time: string) => {
    const t = moment.tz(`${dateString} ${time}`, timeZone);
    return t;
  });
};

interface AppointmentTypeOption {
  label: string;
  value: number;
  length: number;
  categories?: string[];
}

export const availableColumns = (
  categories: ColumnOption[],
  useAppointmentTypes?: boolean,
  appointmentType?: AppointmentTypeOption
) => {
  if (!useAppointmentTypes) {
    return categories;
  }
  let available = categories.filter(
    (c) => !c.colKey || appointmentType?.categories?.includes(c.colKey)
  );
  available = available.concat(
    categories.filter((c) => {
      return (
        c.duplicateOfAppointmentCategoryId &&
        appointmentType?.categories?.includes(c.duplicateOfAppointmentCategoryId)
      );
    })
  );
  return available;
};

export const datePickerValueFromString = (date?: string): Date | undefined => {
  return date
    ? new Date(new Date(date).getTime() + Math.abs(new Date(date).getTimezoneOffset() * 60000))
    : undefined;
};

export const initialValues = (
  useAppointmentTypes: boolean | undefined,
  categoryType: string | undefined,
  selectedPatientId: string | undefined
) => {
  return {
    appointmentLength: undefined,
    appointmentType: undefined,
    appointmentTypeId: undefined,
    categoryId: undefined,
    categoryType,
    column: undefined,
    comments: undefined,
    images: {},
    patientId: selectedPatientId,
    petName: undefined,
    preferredContactMethod: PreferredContactMethod.Email,
    preferredDate: '',
    preferredTime: '',
    useAppointmentTypes
  };
};

export const requestPayload = (
  values: FormikData,
  user: Client,
  useAppointmentTypes: boolean | undefined,
  categoryType: string | undefined,
  requestImageIds: number[]
): AppointmentRequest => {
  return {
    clientId: user.clientId,
    content: {
      appointmentLength: values.appointmentLength,
      appointmentType: values.appointmentType,
      appointmentTypeId: values.appointmentTypeId,
      categoryId: values.categoryId,
      categoryType,
      column: values.column,
      comments: values.comments,
      origin: Platform.OS,
      petName: values.petName,
      preferredDate: values.preferredDate,
      preferredTime: values.preferredTime,
      preferredContactMethod: values.preferredContactMethod,
      useAppointmentTypes
    },
    patientId: values.patientId,
    practiceId: user.practiceId,
    requestImageIds,
    sourceId: user.sourceId,
    type: RequestType.Appointment
  };
};
