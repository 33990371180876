import { endpoint } from 'src/constants';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

interface OnDemandSyncRequest {
  practiceId: number;
}
interface OnDemandSyncResponse {
  status: OnDemandStatus;
  commandIds: string[];
}

interface RawResponse {
  status: OnDemandStatus;
  command_ids: string;
}
export enum OnDemandStatus {
  SUCCESS = 'SUCCESS',
  RUNNING = 'RUNNING'
}

export const onDemandSync = async (request: OnDemandSyncRequest): Promise<OnDemandSyncResponse> => {
  const url = endpoint('ON_DEMAND_SYNC', {
    practiceId: request.practiceId
  });
  const response = await apiClient.post<RawResponse>(url);
  const rawData = isDefinedOrThrowServerError(response.data);
  return {
    status: rawData.status,
    commandIds: rawData.command_ids.split(',').map((id) => id.trim())
  };
};

interface OnDemandStatusRequest {
  commandId?: string;
}

export const onDemandStatus = async (
  request: OnDemandStatusRequest
): Promise<OnDemandSyncResponse> => {
  if (!request.commandId) {
    throw new Error('commandId is required');
  }
  const url = endpoint('ON_DEMAND_STATUS', {
    commandId: request.commandId
  });
  const response = await apiClient.get<RawResponse>(url);
  const rawData = isDefinedOrThrowServerError(response.data);
  return {
    status: rawData.status,
    commandIds: rawData.command_ids.split(',').map((id) => id.trim())
  };
};

interface GetWritebackStatusRequest {
  requestId: number;
}

interface RawWritebackStatusResponse {
  id: number;
  writeback_status: WritebackStatus;
}

interface GetWritebackStatusResponse {
  id: number;
  writeback_status: WritebackStatus;
}

enum WritebackStatus {
  MANUAL_ENTRY_REQUIRED = 'manual_entry_required',
  SUCCESS = 'success'
}

export const getWritebackStatus = async (
  request: GetWritebackStatusRequest
): Promise<GetWritebackStatusResponse> => {
  const url = endpoint('REQUEST_STATUS', {
    request_id: request.requestId
  });
  const response = await apiClient.get<RawWritebackStatusResponse>(url);
  const rawData = isDefinedOrThrowServerError(response.data);
  return {
    id: rawData.id,
    writeback_status: rawData.writeback_status
  };
};
