import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { useQuery } from 'react-query';
import { practiceOptions } from 'src/hooks/react-query/usePractice';
import { useAuth } from 'src/providers/AuthProvider';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { BookingProvider } from './BookingProvider';
import { LayoutController } from './components';
import Content from './components/Content';
import { ActivityIndicator } from 'react-native-paper';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { QueryKeys } from 'src/api';
import PracticeNotFound from './components/PracticeNotFound';
import { unauthenticatedApiClient } from 'src/utils/axios/unauthenticated';
import { endpoint } from 'src/constants';
import { ApiPracticeInfo, Client, Patient } from 'src/interfaces';
import { isDefinedOrThrowServerError } from 'src/utils';
import { getAppointmentRequestSettings } from './BookingProvider/helper';
import Practice from 'src/interfaces/api/Practice';

const PLACEHOLDER_TOKEN_DATA = {
  practice: undefined,
  client: undefined,
  patient: undefined
};

const OnlineBooking: React.FC<
  NativeStackScreenProps<RootStackParamList, Screens.ONLINE_BOOKING>
> = ({ route, navigation }) => {
  const { _user } = useAuth();
  const { colors } = useAppTheme();
  const practiceName = route.params?.practice;
  const token = route.params?.token;

  useQuery({
    ...practiceOptions(_user?.practiceId),
    onSuccess: (data) => {
      navigation.setParams({ practice: data.shortName });
    },

    enabled: !practiceName && !!_user && !token
  });

  const {
    data: { client, patient } = PLACEHOLDER_TOKEN_DATA,
    isError: autoFillError,
    isFetched: isTokenDataFetched
  } = useQuery({
    queryFn: async () => {
      const response = await unauthenticatedApiClient.get<{
        practice: Practice;
        client: Client;
        patient: Patient;
      }>(endpoint('AUTO_FILL_TOKEN', { token }));
      const data = isDefinedOrThrowServerError(response.data);
      navigation.setParams({ practice: data.practice.shortName });
      return data;
    },
    cacheTime: Infinity,
    staleTime: 0,
    enabled: !!token
  });

  const {
    data: practiceInfo,
    isFetched: practiceInfoIsFetched,
    isError
  } = useQuery({
    queryKey: [QueryKeys.CUSTOM_THEME, practiceName],
    queryFn: async () => {
      const response = await unauthenticatedApiClient.get<ApiPracticeInfo>(
        endpoint('PRACTICE_INFO', {
          shortName: practiceName
        })
      );
      const data = isDefinedOrThrowServerError(response.data);
      return {
        ...data,
        phoneNumber: data.phoneNumber.replace(/\D/g, '')
      };
    },
    retry: false,
    enabled: !!practiceName && (!token || isTokenDataFetched)
  });

  const { data: requestSettings, isFetched: requestSettingsIsFetched } = useQuery({
    queryKey: [QueryKeys.REQUEST_SETTING, practiceInfo?.practiceId, 'online-booking'],
    queryFn: async () => {
      try {
        const response = await unauthenticatedApiClient.get(
          endpoint('GET_REQUEST_SETTING', { practiceId: practiceInfo?.practiceId })
        );
        const data = isDefinedOrThrowServerError(response.data);
        return getAppointmentRequestSettings(data);
      } catch {}
    },
    enabled: !!practiceInfo,
    suspense: true
  });

  const practiceId = practiceInfo?.practiceId ?? _user?.practiceId;

  if (
    (token && !isTokenDataFetched) ||
    (practiceName && (!practiceInfoIsFetched || !requestSettingsIsFetched))
  ) {
    return <ActivityIndicator color={colors.primary} size={'large'} style={{ flex: 1 }} />;
  }

  if (
    !practiceId ||
    !practiceInfo ||
    isError ||
    !requestSettings ||
    autoFillError ||
    requestSettings.appointmentRequestsDisabled
  ) {
    return <PracticeNotFound />;
  }

  return (
    <BookingProvider
      practiceInfo={practiceInfo}
      user={client ?? _user}
      requestSetting={requestSettings}
      patient={patient}
    >
      <LayoutController>
        <Content />
      </LayoutController>
    </BookingProvider>
  );
};

export default OnlineBooking;
