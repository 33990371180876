import { Schedule } from 'src/interfaces';

export const NO_PREFERENCE = 'No Preference';
export interface ColumnOption {
  label: string;
  value: string;
  categoryId?: number;
  appointmentLength?: number;
  colKey?: string;
  duplicateOfAppointmentCategoryId?: string | undefined;
}

const extractColumnOptions = (schedule: Schedule): ColumnOption[] => {
  const categories = [];
  if (schedule?.allowNoPreference) {
    categories.push({
      label: 'common:noPreference',
      value: NO_PREFERENCE
    });
  }
  for (const [colKey, value] of Object.entries(schedule?.categories ?? {})) {
    let appointmentLength;
    // when useAppointmentTypes is false, appointmentLength must come from the category itself
    if (!schedule.useAppointmentTypes && schedule.categories) {
      appointmentLength = value.duplicateOfAppointmentCategoryId
        ? schedule.categories[value.duplicateOfAppointmentCategoryId].appointmentLength
        : value.appointmentLength;
    }
    categories.push({
      label: value.descriptionOverride ? value.descriptionOverride : value.description,
      value: value.descriptionOverride ? value.descriptionOverride : value.description,
      categoryId: value.id,
      appointmentLength,
      colKey,
      duplicateOfAppointmentCategoryId: value.duplicateOfAppointmentCategoryId
    });
  }
  return categories;
};

export default extractColumnOptions;
