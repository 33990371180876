import React from 'react';
import RenderHTML from 'react-native-render-html';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { fonts } from 'src/theme';
import { fontStyles } from 'src/theme/globalStyles';

interface Props {
  fallback: React.ReactNode;
  children?: string | null;
}

/**
 * Component to formatted practice provided text
 * @param children - text to render
 * @param fallback - fallback component to render if no text is provided, must be a react node
 *
 * @remark This should never be a child of Text component, as it will not render correctly
 */
const RichTextRenderer: React.FC<Props> = ({ children, fallback }) => {
  const { colors } = useAppTheme();

  if (!children?.length) {
    if (fallback) {
      return <>{fallback}</>;
    } else return null;
  }
  return (
    <RenderHTML
      enableExperimentalMarginCollapsing
      enableCSSInlineProcessing
      baseStyle={{ color: colors.onSurface, fontFamily: fonts.roboto.regular }}
      source={{ html: children }}
      tagsStyles={fontStyles}
      ignoredStyles={['fontSize']}
    />
  );
};

export default RichTextRenderer;
