import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useMemo, useState } from 'react';
import { ImageBackground, View, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useViewMode } from 'src/hooks';
import {
  usePractice,
  usePracticeConfiguration,
  usePracticeCustomizations
} from 'src/hooks/react-query';
import { palette } from 'src/theme';
import { cleanUrl, transparent } from 'src/utils';
import styled from 'styled-components/native';
import DEFAULT_BANNER from 'assets/banner-default.jpg';

const DEFAULT_BANNER_RATIO = 390 / 204;

const Banner = () => {
  const { maxContentWidth } = useViewMode();

  const { data: practiceConfiguration, isFetching: practiceFetching } = usePracticeConfiguration();

  const [dim, setDim] = useState({
    width: maxContentWidth,
    height: maxContentWidth / DEFAULT_BANNER_RATIO
  });

  const bannerUri = useMemo(
    () => cleanUrl(practiceConfiguration?.mobileBannerImage?.urls.original ?? ''),
    [practiceConfiguration]
  );

  const [imageFetching, setImageFetching] = useState(false);
  useEffect(() => {
    if (bannerUri) {
      setImageFetching(true);
      void Image.prefetch(bannerUri).finally(() => {
        Image.getSize(bannerUri, (width, height) =>
          setDim({ width: maxContentWidth, height: (maxContentWidth * height) / width })
        );
        setImageFetching(false);
      });
    }
  }, [bannerUri, maxContentWidth]);

  const { data: practice } = usePractice();
  const {
    data: practiceCustomization,
    isFetching: practiceCustomizationIsFetching,
    isFetched
  } = usePracticeCustomizations(practice?.shortName);

  const isFetching = practiceFetching || imageFetching || practiceCustomizationIsFetching;

  if (isFetched && practiceCustomization?.hideBannerImage) return null;

  return (
    <View style={[{ height: dim.height, alignItems: 'center' }]}>
      {isFetching ? (
        <ActivityIndicator size='large' style={{ flex: 1 }} />
      ) : (
        <>
          <StyledImageBackground
            blurRadius={8}
            style={{
              width: '100%',
              height: dim.height,
              position: 'absolute'
            }}
            source={bannerUri ? { uri: bannerUri } : DEFAULT_BANNER}
          />
          <StyledImageBackground
            resizeMode='cover'
            style={dim}
            source={bannerUri ? { uri: bannerUri } : DEFAULT_BANNER}
          />
        </>
      )}
      <LinearGradient
        colors={[
          transparent(palette.ALLY_SURFACE_BLACK, 0.0),
          transparent(palette.ALLY_SURFACE_BLACK, 0.2),
          transparent(palette.ALLY_SURFACE_BLACK, 0.75)
        ]}
        locations={[0.5, 0.7, 1]}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          bottom: 0
        }}
      />
    </View>
  );
};

export default Banner;

const StyledImageBackground = styled(ImageBackground)`
  justify-content: center;
`;
