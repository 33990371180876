import { endpoint } from 'src/constants';
import LoyaltyTransaction from 'src/interfaces/api/LoyaltyTransaction';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getLoyaltyTransactions = async (): Promise<LoyaltyTransaction[]> => {
  const response = await apiClient.get<LoyaltyTransaction[]>(endpoint('LOYALTY_TRANSACTIONS'));
  return isDefinedOrThrowServerError(response.data, Array.isArray);
};

export const postLoyaltyTransaction = async (rewardId: number): Promise<LoyaltyTransaction> => {
  const response = await apiClient.post<LoyaltyTransaction>(endpoint('LOYALTY_TRANSACTIONS'), {
    type: 'reward',
    rewardId
  });
  return isDefinedOrThrowServerError(response.data);
};

export const deleteLoyaltyTransaction = async (id: number): Promise<void> =>
  await apiClient.delete(endpoint('DELETE_LOYALTY_TRANSACTION', { id }));
