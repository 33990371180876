import { TOUR } from 'src/module/TourGuide/model';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';

export const FAQS = [
  {
    question: 'faqQ1',
    answer: 'faqA1'
  },
  {
    question: 'faqQ2',
    answer: 'faqA2'
  },
  {
    question: 'faqQ3',
    answer: 'faqA3'
  },
  {
    question: 'faqQ4',
    answer: 'faqA4'
  },
  {
    question: 'faqQ5',
    answer: 'faqA5'
  },
  {
    question: 'faqQ6',
    answer: 'faqA6'
  }
];

export const TOUR_GUIDES = [
  {
    label: 'home:title',
    stack: Navigators.HOME_STACK,
    screen: Screens.HOME,
    tours: [TOUR.HOME]
  },
  {
    label: 'home:profile',
    stack: Navigators.HOME_STACK,
    screen: Screens.PROFILE_ROOT,
    tours: [TOUR.PROFILE, TOUR.USER_INFO]
  },
  {
    label: 'petList:title',
    stack: Navigators.PET_STACK,
    screen: Screens.PATIENT_LIST,
    tours: [TOUR.PETS, TOUR.PATIENT_PROFILE]
  },
  {
    label: 'loyalty:rewardsScreenTitle',
    stack: Navigators.LOYALTY_STACK,
    screen: Screens.LOYALTY_ACTIVITY,
    tours: [TOUR.LOYALTY]
  },
  {
    label: 'home:appointments',
    stack: Navigators.APPOINTMENTS_STACK,
    screen: Screens.APPOINTMENTS,
    tours: [TOUR.APPOINTMENTS, TOUR.APPOINTMENT_DETAILS]
  },
  {
    label: 'home:toDos',
    stack: Navigators.TODO_STACK,
    screen: Screens.TO_DOS,
    tours: [TOUR.TO_DOS, TOUR.ADD_TO_DO]
  }
];
