import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView } from 'react-native';
import { Trash2 } from 'react-native-feather';
import { ActivityIndicator, Checkbox } from 'react-native-paper';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import { useQuery } from 'react-query';
import { QueryKeys, getTodo } from 'src/api';
import {
  Body,
  Column,
  Row,
  Title,
  Caption,
  StyledMasterView,
  DiscardChangesModal,
  Alert,
  AlertHandle
} from 'src/components';
import CPrimaryButton from 'src/components/CPrimaryButton';
import { DatePicker, HoverButton, TextInput, TimePicker } from 'src/components/kit';
import DropDown from 'src/components/kit/DropDown';
import { Margin, NOTIFICATIONS_SUPPORTED, STROKE_WIDTH_DEFAULT, Size } from 'src/constants';
import useMutateTodos from 'src/hooks/react-query/todos/useMutateTodos';
import { EditTodo, PeriodUnit, TodoReason } from 'src/interfaces';
import { useNotification } from 'src/providers/NotificationProvider';
import { Screens } from 'src/routes/stacks/screens';
import TodoStackParamList from 'src/routes/stacks/ToDoStackNavigator/ParamsList';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import { validationSchema, mapTodoToEdit, defaultData, FieldNames, FormTodo } from './helpers';
import useAddTodoOptions from './useAddTodoOptions';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS, TourGuide } from './tour';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';

type Props = NativeStackScreenProps<TodoStackParamList, Screens.ADD_TO_DO>;

const AddTodoScreen: React.FC<Props> = ({ route, navigation }) => {
  const todoId = route.params?.todoId;
  const { t } = useTranslation('todos');
  const { colors } = useAppTheme();
  const { openNotificationSettings, notify } = useNotification();

  const [formSubmitted, setFormSubmited] = useState(false);

  const { createTodo, editTodo, deleteTodo } = useMutateTodos();
  const alertHandle = useRef<AlertHandle>(null);
  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    initialValues,
    resetForm,
    dirty,
    validateForm
  } = useFormik<FormTodo>({
    initialValues: defaultData,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (todo) {
        const union = _.unionBy(
          todo.todoAlertsAttributes,
          values.todoAlertsAttributes,
          'alertByMinutesPrior'
        );
        const cleaned = union.map((alert) => ({
          ...alert,
          _destroy: !values.todoAlertsAttributes.find(
            (newAlert) => newAlert.alertByMinutesPrior === alert.alertByMinutesPrior
          )
        }));

        const newTodo: EditTodo = {
          ...todo,
          ...values,
          todoAlertsAttributes: cleaned
        };

        await editTodo(newTodo);
      } else {
        await createTodo(values);
      }
      resetForm({ values });
      await validateForm();
      setFormSubmited(true);
    }
  });

  useEffect(() => {
    if (formSubmitted && !dirty) {
      setFormSubmited(false);
      navigation.goBack();
    }
  }, [formSubmitted, dirty, navigation]);

  const { data: todo, isFetched } = useQuery({
    staleTime: Infinity,
    cacheTime: Infinity,
    queryKey: [QueryKeys.TODO, todoId],
    queryFn: async () => !!todoId && (await getTodo(todoId)),
    enabled: !!todoId,
    retry: false,
    onError: () => {
      Toast.show({
        text1: t('createTodo.todoNotFoundText1'),
        text2: t('createTodo.todoNotFoundText2'),
        type: 'error'
      });
      navigation.setParams({ todoId: undefined });
    }
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        todo ? (
          <HoverButton onPress={alertHandle.current?.alert}>
            <Trash2 color={colors.onPrimary} strokeWidth={STROKE_WIDTH_DEFAULT} />
          </HoverButton>
        ) : null
    });
  }, [deleteTodo, navigation, todo, colors]);

  useEffect(() => {
    if (todo) {
      const values = mapTodoToEdit(todo);
      resetForm({ values });
    }
  }, [todo, resetForm]);

  const { alertOptions, customRepeatOptions, repeatOptions, reasonOptions, patientOptions } =
    useAddTodoOptions(values);

  const rightProps = useCallback(
    (fieldName: FieldNames) =>
      !!values[fieldName] && {
        name: 'undo',
        onPress: async () =>
          await setFieldValue(
            fieldName,
            values[fieldName] !== initialValues[fieldName] ? initialValues[fieldName] : undefined
          )
      },
    [values, initialValues, setFieldValue]
  );

  const debouncedSubmit = useMemo(() => _.debounce(() => handleSubmit()), [handleSubmit]);

  const ref = useRef<ScrollView>(null);

  if (todoId && !isFetched) {
    return <StyledActivityIndicator size={'large'} />;
  }

  return (
    <TourGuide.Provider>
      <TourGuide.Consumer>
        {({ exitTour }) => (
          <>
            <StyledScrollView ref={ref}>
              <ScrollRefProvider scrollRef={ref}>
                <GuideElement id={STEPS.BASIC} body={t('createTodo.tour.basic')} autoStart>
                  <>
                    <DropDown
                      label={t('createTodo.pet')}
                      options={patientOptions}
                      value={values.patientId}
                      onChange={async (patientId) => await setFieldValue('patientId', patientId)}
                      error={!!errors.patientId}
                    />
                    <DropDown
                      label={t('createTodo.reason')}
                      options={reasonOptions}
                      value={values.reason}
                      onChange={async (reason) => await setFieldValue('reason', reason)}
                      error={!!errors.reason}
                    />
                    {values.reason === TodoReason.OTHER && (
                      <TextInput
                        label={t('createTodo.otherReason')}
                        value={values.otherReason ?? ''}
                        onChangeText={async (otherReason) =>
                          await setFieldValue('otherReason', otherReason)
                        }
                        error={!!errors.otherReason}
                      />
                    )}
                    <TextInput
                      label={t('createTodo.notes')}
                      value={values.notes ?? ''}
                      onChangeText={async (notes) => await setFieldValue('notes', notes)}
                      multiline
                      clearButtonMode='always'
                      error={!!errors.notes}
                    />
                  </>
                </GuideElement>
                <GuideElement id={STEPS.DUE_DATE} body={t('createTodo.tour.dueDate')}>
                  <StyledRow>
                    <View style={layout.flex3}>
                      <DatePicker
                        label={t('createTodo.dueDate')}
                        error={!!errors.dueDate}
                        right={rightProps('dueDate')}
                        validRange={{
                          startDate: moment().startOf('day').toDate()
                        }}
                        value={values.dueDate ? new Date(values.dueDate) : undefined}
                        startYear={moment().year()}
                        endYear={moment().add(11, 'years').year()}
                        onConfirm={(date) => {
                          if (!date) {
                            void setFieldValue('dueDate', undefined);
                          } else if (values.dueDate) {
                            void setFieldValue(
                              'dueDate',
                              moment(values.dueDate).set(date).toISOString()
                            );
                          } else {
                            const now = moment();
                            void setFieldValue(
                              'dueDate',
                              moment(date)
                                .set({
                                  h: now.hours(),
                                  m: now.minutes()
                                })
                                .toISOString()
                            );
                          }
                        }}
                      />
                    </View>
                    {!!values.dueDate && (
                      <View style={layout.flex2}>
                        <TimePicker
                          label={t('createTodo.dueTime')}
                          value={values.dueDate ? new Date(values.dueDate) : undefined}
                          onConfirm={(time) => {
                            if (values.dueDate) {
                              const date = moment(values.dueDate).startOf('day');
                              void setFieldValue('dueDate', date.add(time).toISOString());
                            }
                          }}
                          error={!!errors.dueDate}
                        />
                      </View>
                    )}
                  </StyledRow>
                </GuideElement>

                {!!values.dueDate && (
                  <DropDown
                    label={t('createTodo.repeat')}
                    options={repeatOptions}
                    value={values.repeatType}
                    onChange={async (value) => {
                      await setFieldValue('repeatType', value);
                      if (value === 'none') {
                        await setFieldValue('periodUnit', undefined);
                        await setFieldValue('period', undefined);
                      } else if (value === 'custom') {
                        if (!values.periodUnit) {
                          await setFieldValue('periodUnit', PeriodUnit.DAY);
                          await setFieldValue('period', 1);
                        }
                      } else {
                        await setFieldValue('periodUnit', value);
                        await setFieldValue('period', 1);
                      }
                    }}
                  />
                )}
                {!!values.dueDate && values.repeatType === 'custom' && (
                  <StyledRow>
                    <Column>
                      <TextInput
                        label={t('createTodo.repeatEvery')}
                        value={String(values.period)}
                        onChange={({ nativeEvent: { text } }) => {
                          if (!isNaN(parseInt(text))) {
                            void setFieldValue('period', parseInt(text));
                          } else {
                            void setFieldValue('period', 0);
                          }
                        }}
                        keyboardType={'decimal-pad'}
                        error={!!errors.period}
                      />
                    </Column>
                    <Column>
                      <DropDown
                        label={t('createTodo.unit')}
                        options={customRepeatOptions}
                        value={values.periodUnit}
                        onChange={(value) => {
                          void setFieldValue('periodUnit', value);
                        }}
                      />
                    </Column>
                  </StyledRow>
                )}
                {!!values.dueDate && values.repeatType !== 'none' && (
                  <StyledRow>
                    <View style={layout.flex3}>
                      <DatePicker
                        label={t('createTodo.repeatUntilDate')}
                        error={!!errors.repeatUntilDate}
                        right={rightProps('repeatUntilDate')}
                        validRange={{
                          startDate: new Date(values.dueDate)
                        }}
                        value={
                          values.repeatUntilDate ? new Date(values.repeatUntilDate) : undefined
                        }
                        startYear={moment().year()}
                        endYear={moment().add(11, 'years').year()}
                        onConfirm={(date) => {
                          if (!date) {
                            void setFieldValue('repeatUntilDate', undefined);
                          } else if (values.repeatUntilDate) {
                            void setFieldValue(
                              'repeatUntilDate',
                              moment(values.repeatUntilDate).set(date).toISOString()
                            );
                          } else {
                            const now = moment();
                            void setFieldValue(
                              'repeatUntilDate',
                              moment(date)
                                .set({
                                  h: now.hours(),
                                  m: now.minutes()
                                })
                                .toISOString()
                            );
                          }
                        }}
                      />
                    </View>
                    {!!values.repeatUntilDate && (
                      <View style={layout.flex2}>
                        <TimePicker
                          label={t('createTodo.repeatUntilTime')}
                          value={
                            values.repeatUntilDate ? new Date(values.repeatUntilDate) : undefined
                          }
                          onConfirm={(time) => {
                            if (values.repeatUntilDate) {
                              const date = moment(values.repeatUntilDate).startOf('day');
                              void setFieldValue('repeatUntilDate', date.add(time).toISOString());
                            }
                          }}
                          error={!!errors.repeatUntilDate}
                        />
                      </View>
                    )}
                  </StyledRow>
                )}

                <StyledRow justify='space-between' style={{ marginTop: Size.XL }}>
                  <Column>
                    <Title>{t('createTodo.alerts')}</Title>
                  </Column>
                  <StyledRow>
                    <GuideElement
                      id={STEPS.NOTIFY}
                      body={notify ? t('createTodo.tour.notify') : t('createTodo.tour.notifyOff')}
                      semiTransparentBg
                    >
                      <>
                        <Body>{t('createTodo.notifyMe')}</Body>
                        <Checkbox.Android
                          disabled={!values.dueDate}
                          onPress={() => {
                            const next = !values.allowNotify;
                            void setFieldValue('allowNotify', next);
                            void setFieldValue(
                              'todoAlertsAttributes',
                              next ? [{ alertByMinutesPrior: 0 }] : []
                            );
                          }}
                          status={values.allowNotify && !!values.dueDate ? 'checked' : 'unchecked'}
                          color={colors.primary}
                        />
                      </>
                    </GuideElement>
                  </StyledRow>
                </StyledRow>
                {NOTIFICATIONS_SUPPORTED && notify !== 1 && (
                  <StyledWarningContainer onPress={() => openNotificationSettings()}>
                    <Caption color={colors.warn}>{t('createTodo.alertsWontDisplay')}</Caption>
                  </StyledWarningContainer>
                )}
                {!NOTIFICATIONS_SUPPORTED && (
                  <StyledWarningContainer disabled>
                    <Caption color={colors.warn}>
                      {t('createTodo.notificationsNotSupported')}
                    </Caption>
                  </StyledWarningContainer>
                )}
                <GuideElement
                  id={STEPS.ALERT}
                  body={notify ? t('createTodo.tour.alert') : t('createTodo.tour.notify')}
                  onContinue={exitTour}
                >
                  <DropDown
                    multiSelect
                    disabled={!values.dueDate || !values.allowNotify}
                    style={!values.dueDate && { opacity: 0.5 }}
                    label={t('createTodo.alertTimes')}
                    options={alertOptions}
                    value={values.todoAlertsAttributes}
                    onChange={async (values) => setFieldValue('todoAlertsAttributes', values)}
                  />
                </GuideElement>
              </ScrollRefProvider>
            </StyledScrollView>
            <StickyButtonMasterView>
              <CPrimaryButton onPress={debouncedSubmit} disabled={!_.isEmpty(errors) || !dirty}>
                {t('submit', { ns: 'common' })}
              </CPrimaryButton>
            </StickyButtonMasterView>
            <DiscardChangesModal isDirty={dirty} />
            {!!todo && (
              <Alert
                options={[
                  {
                    label: t('cancel', { ns: 'common' }),
                    type: 'neutral'
                  },
                  {
                    label: t('delete', { ns: 'common' }),
                    action: async () => {
                      await deleteTodo(todo);
                      navigation.goBack();
                    },
                    type: 'destructive'
                  }
                ]}
                title={t('createTodo.deletePrompt')}
                ref={alertHandle}
              />
            )}
          </>
        )}
      </TourGuide.Consumer>
    </TourGuide.Provider>
  );
};

export default AddTodoScreen;

const StyledWarningContainer = styled.Pressable`
  border-radius: ${Size.X3_S}px;
  border: 1px solid ${({ theme }) => theme.colors.warn};
  padding: ${Margin.Medium}px;
  justify-content: center;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  flex: 1;
`;

const StickyButtonMasterView = styled(StyledMasterView)`
  ${({ theme: { viewMode: isWeb } }) =>
    isWeb
      ? `
    flex-grow: 1;
    justify-content: flex-end;
    padding: ${Margin.Small}px;
  `
      : ''}
`;

const StyledScrollView = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    flexGrow: 1,
    marginHorizontal: theme.viewMode.horizontalInset,
    gap: Margin.Small,
    padding: Margin.Small
  }
}))`
  flex-grow: 1;
`;

const StyledRow = styled(Row)`
  gap: ${Margin.Small}px;
`;
