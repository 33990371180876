import { Platform } from 'react-native';
import { Config } from './Config';

const firebaseAppId = Platform.select({
  android: '1:859221759384:android:c4df232b642bd32fc247ed',
  ios: '1:859221759384:ios:71c2117ecb445ebcc247ed',
  web: '1:859221759384:web:408c10d0e76c6660c247ed',
  default: ''
});

export const prodConfig: Config = {
  API_URL: 'https://connect.allydvm.com',
  ENVIRONMENT: 'Production',
  PUSH_URL: 'https://push.allydvm.com',
  FIREBASE: {
    apiKey: 'AIzaSyDVNuDc9tFS4qYMFZAoPVV1WVma0tPQAHo',
    authDomain: 'allydvm-1.firebaseapp.com',
    projectId: 'allydvm-1',
    storageBucket: 'allydvm-1.appspot.com',
    databaseURL: 'https://allydvm-1.firebaseio.com',
    messagingSenderId: '859221759384',
    appId: firebaseAppId
  }
};
