import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import { Body, Headline, HoverButton, HoverCard, Row } from 'src/components';
import { Size } from 'src/constants';
import { useAnnouncements } from 'src/hooks/react-query';
import Announcement from 'src/interfaces/api/Announcement';
import styled from 'styled-components/native';
import useStateRef from 'react-usestateref';
import { useBoolean } from 'src/hooks';
import { isDefined } from 'src/utils';
import Icon from 'react-native-vector-icons/Fontisto';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS } from '../tour';

const AnnouncementsCarousel: React.FC = () => {
  const { t } = useTranslation('home');
  const { data: announcements } = useAnnouncements();
  const { colors } = useAppTheme();
  const flatListRef = React.useRef<FlatList>(null);
  const [currentIndex, setCurrentIndex, currentIndexRef] = useStateRef(0);
  const interval = useRef<NodeJS.Timer>();

  const [carouselLayout, setCarouselLayout] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const { value: isPlaying, toTrue: play, toFalse: pause } = useBoolean(true);
  const { value: resetIntervalTrigger, toggle: resetInterval } = useBoolean();
  const { value: isAutoPlay, toggle: toggleAuto } = useBoolean(true);

  const activeAnnouncements = useMemo(() => {
    if (announcements) {
      return announcements.filter((a) => !a.archivedAt && !a.disabled);
    }
  }, [announcements]);

  useFocusEffect(
    useCallback(() => {
      isDefined(resetIntervalTrigger);
      if (isPlaying && isAutoPlay) {
        if (activeAnnouncements && activeAnnouncements.length > 1) {
          const intervalVal: NodeJS.Timeout = setInterval(() => {
            const nextIndex =
              currentIndexRef.current + 1 >= activeAnnouncements.length
                ? 0
                : currentIndexRef.current + 1;
            flatListRef.current?.scrollToOffset({
              animated: true,
              offset: nextIndex * carouselLayout.width
            });
          }, 10000);
          interval.current = intervalVal;
          return () => clearInterval(intervalVal);
        }
      }
    }, [
      activeAnnouncements,
      carouselLayout.width,
      currentIndexRef,
      isAutoPlay,
      isPlaying,
      resetIntervalTrigger
    ])
  );

  const hasAnnouncements = !!activeAnnouncements?.length;

  if (!hasAnnouncements) {
    return null;
  }

  return (
    <GuideElement id={STEPS.ANNOUNCEMENTS} body={t('tour.announcements')}>
      <HoverCard>
        <Row style={{ justifyContent: 'space-between' }}>
          <StyledTitle>{t('announcements')}</StyledTitle>
          <HoverButton onPress={toggleAuto} style={{ marginHorizontal: Size.XS }}>
            <Icon name={isAutoPlay ? 'pause' : 'play'} color={colors.onSurface} />
          </HoverButton>
        </Row>
        <FlatList<Announcement>
          data={activeAnnouncements}
          ref={flatListRef}
          showsHorizontalScrollIndicator={false}
          snapToAlignment='center'
          horizontal
          pagingEnabled
          onScrollBeginDrag={pause}
          onScrollEndDrag={play}
          onScroll={({ nativeEvent }) =>
            setCurrentIndex((prev) => {
              const next = Math.round(nativeEvent.contentOffset.x / carouselLayout.width);
              if (prev !== next) {
                resetInterval();
              }
              return next;
            })
          }
          onLayout={({ nativeEvent }) => setCarouselLayout(nativeEvent.layout)}
          renderItem={({ item }) => (
            <CarouselItemView width={carouselLayout.width}>
              {!!carouselLayout.width && <AnnouncementText>{item?.text}</AnnouncementText>}
            </CarouselItemView>
          )}
        />
        <DotContainer>
          {activeAnnouncements?.map((item, index) => (
            <StyledIcon
              focused={index === currentIndex}
              key={item.text}
              onPress={() => {
                flatListRef.current?.scrollToOffset({
                  animated: true,
                  offset: index * carouselLayout.width
                });
              }}
            />
          ))}
        </DotContainer>
      </HoverCard>
    </GuideElement>
  );
};

export default AnnouncementsCarousel;

const DotContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${Size.S}px;
`;

const StyledIcon = styled.Pressable.attrs({ hitSlop: Size.XS })<{ focused: boolean }>`
  width: ${({ focused }) => (focused ? Size.X2_S : 6)}px;
  height: ${({ focused }) => (focused ? Size.X2_S : 6)}px;
  border-radius: ${Size.X2_S}px;
  margin: ${Size.X2_S}px;
  background-color: ${({ focused, theme: { colors } }) =>
    focused ? colors.onSurface : colors.disabled};
`;
const StyledTitle = styled(Headline)`
  color: ${({ theme }) => theme.colors.onSurface};
  padding: ${Size.S}px;
`;

const CarouselItemView = styled.View<{ width: number }>`
  width: ${({ width }) => width}px;
  padding-horizontal: ${Size.S}px;
`;

const AnnouncementText = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;
