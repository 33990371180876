import { useQuery } from 'react-query';
import { QueryKeys, getPatients, getRequests } from 'src/api';
import { Margin } from 'src/constants';
import { useViewMode } from 'src/hooks';
import { ActivityIndicator, RefreshControl, SectionList, View } from 'react-native';
import RequestItem from './components/RequestItem';
import { useTranslation } from 'react-i18next';
import {
  EmptyListCard,
  NoRequestText,
  SectionHeaderContainer,
  SectionTitle,
  StyledHeroIcon
} from './styles';
import { isDefined, queryClient } from 'src/utils';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { RequestFilterCriteria, filterRequests } from './helper/requestFilter';
import RequestsFilterDrawer from './components/RequestsFilterDrawer';
import { ModalDrawerHandle } from 'src/components/ModalDrawer';
import { useNavigation } from '@react-navigation/native';
import { Headline, HoverButton, Row } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import partitionRequests from './helper/partitionRequests';
import type { RequestSectionList, RequestWithPatient } from './helper/model';
import { Inbox } from 'react-native-feather';
import SuspenseRequestItem from './components/SuspenseRequestItem';
import RequestWizardFAB from '../RequestWizardFAB';
import { Patient } from 'src/interfaces';

const FALLBACK_DATA: RequestSectionList[] = [];
const Requests: React.FC = () => {
  const { t } = useTranslation('requests');
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();
  const [filters, setFilters] = useState<RequestFilterCriteria>({});
  const [open, setOpen] = useState<boolean>(false);

  const {
    data = FALLBACK_DATA,
    isRefetching,
    refetch,
    isFetched
  } = useQuery({
    queryKey: [QueryKeys.REQUESTS, filters],
    queryFn: async () => {
      const data = await getRequests();
      const patients =
        (await queryClient.getQueryData<Patient[]>([QueryKeys.PATIENTS])) ?? (await getPatients());
      const prepData: RequestWithPatient[] = data.map((request) => {
        const patient = patients.find((patient) => patient.patientId === request.patientId);
        if (patient) {
          return { ...request, patient, notOwner: false };
        }
        return { ...request, notOwner: true };
      });
      if (Object.values(filters).filter(isDefined).length > 0) {
        return [{ title: 'sectionTitles.results', data: filterRequests(prepData, filters) }];
      } else {
        return partitionRequests(prepData);
      }
    }
  });

  const filterCount = Object.values(filters).filter(isDefined).length;
  const { setOptions } = useNavigation();
  const filterRef = useRef<ModalDrawerHandle>(null);
  const showFilter = useCallback(() => filterRef.current?.show(), []);
  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HoverButton uppercase={false} buttonColor={colors.onPrimary} onPress={showFilter}>
          {t('common:filters')}
          {!!filterCount && ` (${filterCount})`}
        </HoverButton>
      )
    });
  }, [setOptions, t, colors, filters, filterCount, showFilter]);

  if (!isFetched) {
    return <ActivityIndicator color={colors.primary} size='large' style={{ flex: 1 }} />;
  }

  return (
    <>
      <SectionList
        refreshControl={
          <RefreshControl
            refreshing={isRefetching}
            onRefresh={refetch}
            tintColor={colors.primary}
          />
        }
        style={{ flex: 1 }}
        contentContainerStyle={{
          gap: Margin.Medium,
          padding: Margin.Medium,
          marginHorizontal: horizontalInset
        }}
        sections={data}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={({ section: { title, data } }) => (
          <SectionHeaderContainer>
            <Row justify='space-between'>
              <SectionTitle>{t(`${title}`)}</SectionTitle>
              {!!filterCount && (
                <HoverButton uppercase={false} onPress={() => setFilters({})} compact>
                  {t('common:clearFilters')}
                </HoverButton>
              )}
            </Row>
            {!!filterCount && !data.length && <NoRequestText>{t('noResults')}</NoRequestText>}
          </SectionHeaderContainer>
        )}
        renderItem={({ item }) => (
          <Suspense fallback={<SuspenseRequestItem />}>
            <RequestItem request={item} />
          </Suspense>
        )}
        ListEmptyComponent={() => (
          <EmptyListCard>
            <StyledHeroIcon icon={Inbox} color={colors.primary} />
            <NoRequestText as={Headline}>{t('noRequests.title')}</NoRequestText>
            <NoRequestText>{t('noRequests.description')}</NoRequestText>
            <View>
              <HoverButton mode='contained' onPress={() => setOpen(true)}>
                {t('noRequests.cta')}
              </HoverButton>
            </View>
          </EmptyListCard>
        )}
      />
      <RequestsFilterDrawer ref={filterRef} filters={filters} setFilters={setFilters} />
      <RequestWizardFAB open={open} setOpen={setOpen} />
    </>
  );
};

export default Requests;
