import { StyleSheet } from 'react-native';
import { MD2DarkTheme as DarkTheme } from 'react-native-paper';
import { Body } from 'src/components';
import { IS_WEB, Margin, MOBILE_WIDTH_MAX, Size } from 'src/constants';
import { defaultLightTheme } from 'src/theme';
import { fonts } from 'src/theme/typography';
import styled from 'styled-components/native';

export const style = StyleSheet.create({
  paddingTopMedium: { paddingTop: Size.M },
  infoFields: { flex: 1, justifyContent: 'flex-start' },
  infoFieldsWebOrTablet: {
    justifyContent: 'flex-start',
    marginTop: 20
  },
  fieldLabel: {
    marginBottom: 6,
    position: 'absolute',
    top: IS_WEB ? -15 : -13,
    left: 6,
    paddingHorizontal: 2
  },
  darkRedBorder: { borderColor: DarkTheme.colors.error },
  lightRedBorder: { borderColor: defaultLightTheme.colors.error },
  borderWidthHalf: { borderWidth: 0.5, borderRadius: Size.X3_S },
  fieldValueContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  errorLabel: {
    fontFamily: fonts.roboto.medium
  },
  fieldContainer: { padding: Size.X2_S, flexGrow: 1 },
  helperTextContainer: { paddingVertical: 2, marginLeft: 4 },
  pleaseContactText: {
    paddingRight: 3,
    paddingTop: 1
  },
  verifyText: {
    paddingLeft: 3,
    paddingTop: 1
  }
});

export const StyledHeader = styled(Body)`
  margin-vertical: ${Margin.Medium}px;
  max-width: ${MOBILE_WIDTH_MAX}px;
  text-align: center;
  align-self: center;
`;
