import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import sentry from 'src/utils/sentry';
import * as SecureStore from 'expo-secure-store';
import { AsyncStorageKeys, ExtendedSignInRequest, MutationKeys } from 'src/api';
import { TokenPayload } from './token/type';
import { announcementOptions } from 'src/hooks/react-query/useAnnouncements';
import { availablePracticesOptions } from 'src/hooks/react-query/useAvailablePractices';
import { practiceConfigurationOptions } from 'src/hooks/react-query/usePracticeConfiguration';
import { prescriptionsOptions } from 'src/hooks/react-query/usePrescriptions';
import { remindersOptions } from 'src/hooks/react-query/useReminders';
import { appointmentsOptions } from 'src/scenes/Appointments/AppointmentsRoot/hooks/useAppointments';
import { abilityOptions } from 'src/hooks/react-query/useAbility';
import { practiceOptions } from 'src/hooks/react-query/usePractice';
import { requestSettingsOptions } from 'src/hooks/react-query/useRequestSetting';
import { Client } from 'src/interfaces';
import { isDefined, queryClient, storage } from 'src/utils';
import { useMemo } from 'react';
import { isTokenValid, shouldRefreshToken } from './token';
import { AuthStatus } from './model';
import { useIsMutating } from 'react-query';
import useStorage from 'src/hooks/useStorage';
import useAppState from 'src/hooks/useAppState';
import { IS_WEB } from 'src/constants';

export const unpackToken = (token: string): TokenPayload => jwtDecode<TokenPayload>(token);

export const captureAxiosException = (e: AxiosError): void => {
  e.name = `Api Error - ${e.config?.url ?? 'Unknown'}`;
  sentry.addBreadcrumb({
    type: 'error',
    message: e.message,
    category: 'xhr',
    data: {
      params: JSON.stringify(e.config?.params),
      response: JSON.stringify(e.response?.data),
      request: JSON.stringify(e.request?.data),
      statusCode: e.response?.status
    }
  });
};

export const secureStoreSaveSignInCredentials = async (signInRequest: ExtendedSignInRequest) =>
  IS_WEB
    ? []
    : Promise.all([
        SecureStore.setItemAsync('email', signInRequest.email),
        SecureStore.setItemAsync('password', signInRequest.password),
        SecureStore.setItemAsync('practiceId', signInRequest.practiceId.toString()),
        SecureStore.setItemAsync('id', signInRequest.id.toString())
      ]);
export const secureStoreGetSignInCredentials = async (): Promise<
  ExtendedSignInRequest | undefined
> => {
  if (IS_WEB) return;
  const store = await Promise.all([
    SecureStore.getItemAsync('email'),
    SecureStore.getItemAsync('password'),
    SecureStore.getItemAsync('practiceId'),
    SecureStore.getItemAsync('id')
  ]);
  const [email, password, practiceId, id] = store;
  if (!email || !password || !practiceId || !id) return;
  return {
    email,
    password,
    id: parseInt(id),
    practiceId: parseInt(practiceId ?? '0'),
    isBiometrics: true
  };
};

export const secureStoreDeleteCredentials = async () => {
  if (IS_WEB) return;
  await Promise.all([
    SecureStore.deleteItemAsync('email'),
    SecureStore.deleteItemAsync('password'),
    SecureStore.deleteItemAsync('practiceId'),
    SecureStore.deleteItemAsync('id')
  ]);
};

export const prefetchQueries = async (user: Client) =>
  await Promise.all([
    queryClient.prefetchQuery(announcementOptions),
    queryClient.prefetchQuery(availablePracticesOptions(user.email)),
    queryClient.prefetchQuery(prescriptionsOptions),
    queryClient.prefetchQuery(remindersOptions),
    queryClient.prefetchInfiniteQuery(appointmentsOptions()),
    queryClient.prefetchQuery(practiceConfigurationOptions(user.practiceId)),
    queryClient.prefetchQuery(abilityOptions),
    queryClient.prefetchQuery(practiceOptions(user.practiceId)),
    queryClient.prefetchQuery(requestSettingsOptions(user.practiceId))
  ]);

export const asyncSetToken = async (token: string | undefined) => {
  if (!token) await storage.removeItem(AsyncStorageKeys.TOKEN);
  else await storage.setTypedItem(AsyncStorageKeys.TOKEN, token ?? null);
  await queryClient.invalidateQueries(AsyncStorageKeys.TOKEN);
};

export const useLoginState = () => {
  const [token, , tokenFetched] = useStorage<string>(AsyncStorageKeys.TOKEN);
  const [rememberMe] = useStorage<boolean>(AsyncStorageKeys.REMEMBER_ME, false);
  const isLoggingIn = !!useIsMutating(MutationKeys.AUTHENTICATION);
  const isRefreshing = !!useIsMutating(MutationKeys.SESSION_RENEW);
  const appState = useAppState(); // Used to trigger a check on the login state when the app comes back from the background

  const [biometricAuthAppSetting, , biometricAuthFetched] = useStorage<boolean | undefined>(
    AsyncStorageKeys.BIOMETRIC_AUTH_APP_SETTING
  );

  const state = useMemo(() => {
    if (__DEV__) {
      console.info(`Triggering login state check from app state: ${appState}`);
    }

    const userIsMutating = isLoggingIn || isRefreshing || !tokenFetched || !biometricAuthFetched;
    const isLoggedIn = isTokenValid(token) && !userIsMutating;
    const shouldRefresh =
      (rememberMe || biometricAuthAppSetting) && shouldRefreshToken(token) && !isLoggingIn;
    return {
      hasToken: !!token,
      isLoggedIn,
      shouldRefresh,
      token,
      userIsMutating,
      biometricAuthAppSetting
    };
  }, [
    isLoggingIn,
    isRefreshing,
    tokenFetched,
    biometricAuthFetched,
    token,
    rememberMe,
    biometricAuthAppSetting,
    appState
  ]);

  return state;
};

interface Args
  extends Pick<ReturnType<typeof useLoginState>, 'token' | 'isLoggedIn' | 'userIsMutating'> {
  themeFetched: boolean;
  authStatus: AuthStatus | undefined;
  hasError: boolean;
  navReady: boolean;
  userFetched: boolean;
}

export const isAppReady = ({
  themeFetched,
  authStatus,
  hasError,
  token,
  navReady,
  userIsMutating,
  userFetched
}: Args) => {
  const undefinedClientAllowed =
    isDefined(authStatus) &&
    [
      AuthStatus.FORBIDDEN,
      AuthStatus.NEEDS_BIOMETRIC,
      AuthStatus.NEEDS_PASSWORD_RESET,
      AuthStatus.NEEDS_TERMS,
      AuthStatus.NEEDS_UPGRADE
    ].includes(authStatus);

  const userItemsFetched = themeFetched && !userIsMutating && userFetched;
  const userIsReady = !token || userItemsFetched || undefinedClientAllowed;
  const ready = userIsReady || !!hasError;
  return ready && navReady;
};
