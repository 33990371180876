import { useQuery } from 'react-query';
import { QueryKeys, getPracticeCustomizations } from 'src/api';
import Practice from 'src/interfaces/api/Practice';
import type { Options } from './types';
import { ApiPracticeInfo } from 'src/interfaces';

const usePracticeCustomizations = (
  practiceShortName?: Practice['shortName'],
  options?: Options
) => {
  return useQuery({
    queryKey: [QueryKeys.CUSTOM_THEME, practiceShortName],
    queryFn: async (): Promise<ApiPracticeInfo | undefined> => {
      if (!practiceShortName) {
        return;
      }
      return await getPracticeCustomizations(practiceShortName);
    },
    ...options
  });
};

export default usePracticeCustomizations;
