import { AppColors } from 'src/types/paper';
import { BOOKING_STEPS, STEP_INFO, STEP_STATUS } from '../BookingProvider/helpers';
import { transparent } from 'src/utils';
import { BookingFormData, BookingStateType } from '../BookingProvider/model';

export const switchColors = (stepState: STEP_STATUS, colors: AppColors) => {
  switch (stepState) {
    case STEP_STATUS.ACTIVE:
      return colors.primary;
    case STEP_STATUS.ERROR:
      return colors.error;
    case STEP_STATUS.INCOMPLETE:
      return transparent(colors.disabled, 0.1);
    default:
      return colors.text;
  }
};

export const switchTextColors = (stepState: STEP_STATUS, colors: AppColors) => {
  switch (stepState) {
    case STEP_STATUS.ACTIVE:
      return colors.surface;
    case STEP_STATUS.ERROR:
      return colors.surface;
    default:
      return colors.text;
  }
};

export const getStepState = (
  values: Partial<BookingFormData>,
  state: BookingStateType['bookingState'],
  activeState: BOOKING_STEPS
): STEP_STATUS => {
  if (state.current === activeState) {
    return STEP_STATUS.ACTIVE;
  } else if (!state.visited[activeState]) {
    return STEP_STATUS.INCOMPLETE;
  } else if (STEP_INFO[activeState].validator(values)) {
    return STEP_STATUS.COMPLETE;
  } else {
    return STEP_STATUS.ERROR;
  }
};
