import { Body, Headline, HoverCard, MiniHeroIcon } from 'src/components';
import { Margin } from 'src/constants';
import styled from 'styled-components/native';

export const SectionTitle = styled(Headline)`
  padding-vertical: ${Margin.Medium}px;
`;

export const SectionHeaderContainer = styled.View`
  justify-content: stretch;
  gap: ${Margin.Large}px;
`;

export const NoRequestText = styled(Body)`
  text-align: center;
`;

export const EmptyListCard = styled(HoverCard)`
  padding: ${Margin.Large}px;
  gap: ${Margin.Large}px;
`;

export const StyledHeroIcon = styled(MiniHeroIcon)`
  margin: ${Margin.Medium}px;
`;

export const SurfaceText = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;

export const FabContainer = styled.View`
  position: absolute;
  right: ${({ theme }) => theme.viewMode.horizontalInset + Number(Margin.Large)}px;
  bottom: ${Margin.Large}px;
`;
