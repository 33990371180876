import * as ExpoSentry from 'sentry-expo';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import config from 'src/environment';
import { IS_DEBUG } from 'src/constants';
import { noop } from 'lodash';
import { ignoreErrors, SAMPLE_RATE } from './helper';

export * from './helper';

const getRelease = () => (IS_DEBUG ? undefined : Application.nativeApplicationVersion) ?? undefined;
ExpoSentry.init({
  dsn: 'https://60098ef5659f90ed44a40ea25530a0c2@o513175.ingest.sentry.io/4505874876203008',
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  release: getRelease(),
  tracesSampleRate: SAMPLE_RATE,
  _experiments: {
    profilesSampleRate: SAMPLE_RATE
  },
  environment: config.ENVIRONMENT,
  enableInExpoDevelopment: Constants?.expoConfig?.extra?.DEBUG_SENTRY,
  attachScreenshot: true,
  attachStacktrace: true,
  debug: Constants?.expoConfig?.extra?.DEBUG_SENTRY === 'true',
  ignoreErrors
});

// stub the following to avoid errors in web
const wrap = <T>(fn: T): T => fn;
const withTouchEventBoundary = wrap;
export const routingInstrumentation: {
  registerRoutingInstrumentation: () => void;
} = { registerRoutingInstrumentation: noop };

export default {
  ...ExpoSentry.Browser,
  wrap,
  withTouchEventBoundary
};
