import React from 'react';
import { PracticeConfigurationHook } from 'src/hooks/react-query/usePracticeConfiguration';
import { RequestSettingHook } from 'src/hooks/react-query/useRequestSetting';
import { Screens } from 'src/routes/stacks/screens';
import { openWebBrowser } from 'src/utils';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { SvgProps } from 'react-native-svg';
import { Calendar, Information, Pill } from 'src/components/kit/ABIcons';
import { AlertTriangle } from 'react-native-feather';
import { STROKE_WIDTH_DEFAULT } from 'src/constants';
import EmergencyContact from 'src/interfaces/api/EmergencyContact';
import { UseQueryResult } from 'react-query';

interface QuickLinkContext {
  title: string;
  onPress: () => void;
  child: (props: SvgProps) => React.JSX.Element;
  isDisabled: boolean;
  isLoading: boolean;
}

export const quickLinks = ({
  requestSettings,
  navigation,
  practiceConfiguration,
  emergencyContacts
}: {
  navigation: NavigationProp<ParamListBase>;
  requestSettings: RequestSettingHook;
  practiceConfiguration: PracticeConfigurationHook;
  emergencyContacts: UseQueryResult<EmergencyContact[], unknown>;
}): QuickLinkContext[] =>
  [
    {
      title: 'requestAnAppointment',
      child: (props: SvgProps) => <Calendar {...props} />,
      onPress: () => requestSettings.requestAppointment(),
      isDisabled: !!requestSettings.data?.appointmentRequestsDisabled,
      isLoading: requestSettings.isLoading
    },
    {
      title: 'onlinePharmacy',
      child: (props: SvgProps) => <Pill {...props} />,
      onPress: async () => {
        if (practiceConfiguration?.data?.onlinePharmacyLink)
          await openWebBrowser(practiceConfiguration?.data?.onlinePharmacyLink);
      },
      isDisabled: !practiceConfiguration?.data?.onlinePharmacyLink,
      isLoading: practiceConfiguration?.isLoading
    },
    {
      title: 'contactUs',
      child: (props: SvgProps) => <Information {...props} />,
      onPress: () => navigation.navigate(Screens.CONTACT_US),
      isDisabled: false,
      isLoading: false
    },
    {
      title: 'afterHours',
      child: (props: SvgProps) => <AlertTriangle {...props} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      onPress: () => navigation.navigate(Screens.AFTER_HOURS),
      isDisabled: emergencyContacts.data?.length === 0,
      isLoading: emergencyContacts.isFetching
    }
  ].filter((item) => !item.isDisabled || item.isLoading);
